import { createContext } from 'react';
import { ToastStatus } from './types';

type ContextProps = {
  toasts: any[];
  add: (msg: string, status: ToastStatus) => void;
  remove: (id: string) => void;
};

const ToastContext = createContext<Partial<ContextProps>>({});

export default ToastContext;
