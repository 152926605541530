import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  navList: ReactNode[];
}

const OfferTabsNav = ({ navList }: Props) => {
  return (
    <div className={styles.offerTabNav}>
      <ul>
        {navList.map((item, index) => {
          const i = index;
          return (
            <li key={i} className={styles.navItem}>
              {item}
            </li>
          );
        })}
        <li />
      </ul>
    </div>
  );
};

export default OfferTabsNav;
