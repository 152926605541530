import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import Checkbox from '../../../../../components/Checkbox';
import DisclaimerModal from '../../../../../components/DisclaimerModal';
import InlineInputLabel from '../../../../../components/InlineInputLabel';
import Input from '../../../../../components/Input';
import Textarea from '../../../../../components/Textarea';
import { MarketingSites } from '../../../../../constants/global';
import useInput from '../../../../../hooks/useInput';
import { DnDType, OfferMarketingItem } from '../Models';
import styles from './styles.module.scss';

interface Props {
  addItem: (index: number) => void;
  file?: string;
  fileId?: string;
  handleItemsSort: (newSortOrder: number, prevSortOrder: number) => void;
  id: string;
  innerRef?: (element?: HTMLElement | null | undefined) => any;
  isLexus?: boolean;
  isStandard?: boolean;
  modalItems: { id: string; name: string; rev: string; text: string; tdaCode: string }[];
  itemsList: OfferMarketingItem[];
  label?: string;
  removeItem: (type: DnDType, uid: string) => void;
  setItemsList: (item: OfferMarketingItem) => void;
  site: MarketingSites;
  snippets: { name: string; char: string }[];
  sortOrder: number;
  text?: string;
  tokens: { name: string; char: string }[];
  uid: string;
  type: DnDType;
  copyLinkToToyota?: boolean;
  isDisabledFields?: boolean;
  link?: string;
  isTemplate?: boolean;
}

const labelWidth = 85;
const textAreaLabelWidth = 100;
const DnDItem = ({
  addItem,
  file,
  fileId,
  handleItemsSort,
  id,
  innerRef,
  isLexus,
  modalItems,
  itemsList,
  removeItem,
  isStandard,
  setItemsList,
  snippets,
  site,
  sortOrder,
  text,
  tokens,
  uid,
  label,
  type,
  copyLinkToToyota,
  isDisabledFields,
  link = '',
  isTemplate,
  ...rest
}: Props) => {
  const [itemTitle, itemTitleBindFn, itemTitleError, setItemTitle] = useInput(sortOrder, { isWhole: true, required: true });
  const [itemFile, itemFileBindFn, itemFileError, setItemFile] = useInput(file || '');
  const [itemFileId, setItemFileId] = useState(fileId || '');
  const [itemText, , itemTextError, setItemText] = useInput(text || '', { required: !isTemplate });
  const [bulletLink, setBulletLink] = useState(link || '');
  const [isCopyLinkToToyotaChecked, setIsCopyLinkToToyotaChecked] = useState(copyLinkToToyota);

  const [showModal, setShowModal] = useState(false);
  const [disableTextArea, setDisableTextArea] = useState(isStandard);

  useEffect(() => {
    setItemTitle(sortOrder.toString());
  }, [itemsList]);

  useEffect(() => {
    setItemsList({ uid, fileId: itemFileId, file: itemFile, text: itemText, sortOrder, copyLinkToToyota: isCopyLinkToToyotaChecked, link: bulletLink, isStandard });

    if (itemFile && itemText) {
      setDisableTextArea(true);
    }
  }, [itemFileId, itemFile, itemText, bulletLink, isCopyLinkToToyotaChecked]);

  const handleInsertItem = (tier: { name: string; text: string; id: string }) => {
    if (tier) {
      setItemFileId(tier.id);
      setItemFile(tier.name);
      setItemText(tier.text);
      setDisableTextArea(true);
    }
  };

  const handleUnlink = () => {
    setItemFile('');
    setItemFileId('');
    setDisableTextArea(false);
  };

  return (
    <div className={styles.dndItemParent} ref={innerRef} {...rest}>
      {showModal && (
        <DisclaimerModal
          onClose={() => setShowModal(false)}
          handleInsertDisclaimer={handleInsertItem}
          disclaimers={modalItems.map((item: any, i: number) => ({ ...item, selected: i === 0 }))}
          label={type}
        />
      )}
      <InlineInputLabel label={`${label}`} vertical width={labelWidth}>
        <Input
          id="dnd-item-title"
          darkTheme
          disabled={isDisabledFields}
          className={styles.dndItemTitleInput}
          value={itemTitle}
          {...itemTitleBindFn}
          error={itemTitleError}
          onBlur={e => {
            if (e.target.value) {
              handleItemsSort(Number(e.target.value), sortOrder);
            }
          }}
        />
      </InlineInputLabel>

      <div className={styles.dndWrapper}>
        <div className={styles.dndItemBody}>
          {(type === DnDType.DISCLAIMERS || type === DnDType.RESTRICTIONS) && (
            <InlineInputLabel label="File" vertical width={textAreaLabelWidth}>
              <div className={styles.dndItemFileContainer}>
                <Input id="dnd-item-file" darkTheme className={styles.dndItemInput} disabled value={itemFile} {...itemFileBindFn} error={itemFileError} />
                {!disableTextArea ? (
                  <Button variant="primary" id="browse-btn" onClick={() => setShowModal(true)} disabled={isStandard || isDisabledFields}>
                    Browse
                  </Button>
                ) : (
                  <Button variant="primary" id="unlink-btn" onClick={() => handleUnlink()} disabled={isStandard || isDisabledFields}>
                    Unlink
                  </Button>
                )}
              </div>
            </InlineInputLabel>
          )}

          <InlineInputLabel label="Text *" vertical width={textAreaLabelWidth}>
            <div className={styles.dndItemTextAreaContainer}>
              <Textarea
                id="dnd-item-text-field"
                className={styles.dndItemTextArea}
                value={itemText}
                tokens={tokens}
                secondaryTokens={snippets}
                onChange={e => setItemText(e.target.value)}
                disabled={disableTextArea || isDisabledFields}
                error={itemTextError}
                darkTheme
              />
            </div>
          </InlineInputLabel>
          {type === DnDType.BULLETS && (
            <>
              <InlineInputLabel label="Link" vertical width={textAreaLabelWidth}>
                <div className={styles.dndItemFileContainer}>
                  <Input
                    id="dnd-item-link"
                    darkTheme
                    className={styles.bulletInput}
                    value={bulletLink}
                    disabled={isStandard || isDisabledFields}
                    onChange={e => setBulletLink(e.target.value)}
                  />
                </div>
              </InlineInputLabel>
              {site === MarketingSites.BAT && !isLexus && (
                <Checkbox
                  className={styles.copyToyotaCheckbox}
                  isChecked={isCopyLinkToToyotaChecked}
                  onChange={() => setIsCopyLinkToToyotaChecked(!isCopyLinkToToyotaChecked)}
                  label="Include link when copying from BAT to TCOM/Other Sites"
                  isDisabled={isStandard || isDisabledFields}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DnDItem;
