import { useState } from 'react';
import dateStringToDate from '../../utils/dateStringToDate';

type Options = {
  minDate?: Date | string;
  maxDate?: Date | string;
};

type ErrorResponse = {
  dateOrderError: boolean;
  minDateError: boolean;
  maxDateError: boolean;
};

type ReturnType = [
  Date | undefined,
  Date | undefined,
  React.Dispatch<React.SetStateAction<Date | undefined>>,
  React.Dispatch<React.SetStateAction<Date | undefined>>,
  ErrorResponse | undefined,
];

const validateDateOrder = (startDate: Date | undefined, endDate: Date | undefined) => {
  return startDate && endDate ? startDate.getTime() > endDate.getTime() || endDate.getTime() < startDate.getTime() : false;
};

const validateMinDate = (startDate: Date | undefined, minDate: Date | undefined) => {
  return startDate && minDate ? minDate && startDate.getTime() < minDate.getTime() : false;
};

const validateMaxDate = (endDate: Date | undefined, maxDate: Date | undefined) => {
  return endDate && maxDate ? maxDate && endDate.getTime() > maxDate.getTime() : false;
};

const useDates = (defaultStartDate: string | Date | undefined, defaultEndDate: string | Date | undefined, options?: Options): ReturnType => {
  const [startDate, setStartDate] = useState<Date | undefined>(defaultStartDate ? dateStringToDate(defaultStartDate) : undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate ? dateStringToDate(defaultEndDate) : undefined);
  const [minDate] = useState<Date | undefined>(options && options.minDate ? dateStringToDate(options.minDate) : undefined);
  const [maxDate] = useState<Date | undefined>(options && options.maxDate ? dateStringToDate(options.maxDate) : undefined);

  const dateOrderError = validateDateOrder(startDate, endDate);
  const minDateError = validateMinDate(startDate, minDate);
  const maxDateError = validateMaxDate(endDate, maxDate);

  const error =
    dateOrderError || minDateError || maxDateError
      ? {
          dateOrderError,
          minDateError,
          maxDateError,
        }
      : undefined;

  return [startDate, endDate, setStartDate, setEndDate, error];
};

export default useDates;
