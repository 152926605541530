import React from 'react';
import Input from '../Input';
import styles from './styles.module.scss';

interface TierTableCellProps {
  tier: string;
  term: number;
  index: number;
  setTableCellValue: (rate: string, tier: string, term: number, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => void;
  tiersList: { term: number; tiers: { tier: string; rate: string }[] }[];
  setTiersList: (val: any) => void;
  rate: string;
}

const TierTableCell = ({ tier, term, index, setTableCellValue, rate, tiersList, setTiersList }: TierTableCellProps) => {
  return (
    <td key={`${tier}-${index}`} className={styles.td}>
      <Input className={styles.tableInput} value={rate} onChange={e => setTiersList(setTableCellValue(e.target.value, tier, term, tiersList))} />
      <span className={styles.percentageSymbol}>%</span>
    </td>
  );
};

export default TierTableCell;
