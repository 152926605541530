import React, { ReactNode } from 'react';
import OATWrapper from '../../components/OATWrapper';
import styles from './styles.module.scss';

type SettingsItem = {
  name: string;
  linkComponent: ReactNode;
};

type Props = {
  settings: SettingsItem[];
  children?: ReactNode;
};

const SettingsLayout = ({ settings, children }: Props) => {
  return (
    <OATWrapper withPadding>
      <div className={styles.container}>
        <div className={styles.nav}>
          <ul>
            {settings.map((item, index) => {
              const i = index;
              return <li key={i}>{item.linkComponent}</li>;
            })}
          </ul>
        </div>
        <div className={styles.component}>{children}</div>
      </div>
    </OATWrapper>
  );
};

export default SettingsLayout;
