import React, { useState } from 'react';
import { cashValidation, decimalValidation, rcfValidation, wholeNumberValidation } from './numValidationFunctions';

interface Options {
  required?: boolean;
  isWhole?: boolean; // whole numbers
  isCash?: boolean; // cash amount with cents
  isRcf?: boolean; // lease rcfs
  isDecimal?: boolean; // apr rates, flexible decimal format
  min?: number;
  max?: number;
}

const defaultOptions: Options = {
  required: false,
  isWhole: false,
  isCash: false,
  isRcf: false,
  isDecimal: false,
  min: undefined,
  max: undefined,
};

export type BindFnType = {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

type ReturnType = [string, BindFnType, boolean, React.Dispatch<React.SetStateAction<string>>];

type ValueType = string | number | null | undefined;

const processDefaultValueToString = <T extends ValueType>(defaultValue: T): string => {
  if (typeof defaultValue === 'number') {
    return defaultValue.toString();
  }

  return defaultValue || '';
};

const useInput = <T extends ValueType>(defaultValue: T, { required, isWhole, isCash, isRcf, isDecimal, min, max } = defaultOptions): ReturnType => {
  const [value, setValue] = useState(processDefaultValueToString(defaultValue));
  const numValidation = (isWhole && wholeNumberValidation) || (isCash && cashValidation) || (isRcf && rcfValidation) || (isDecimal && decimalValidation) || undefined;

  const isNumberError = (val: string) => {
    return val && numValidation ? numValidation.isErrorFn(val, min, max) : false;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const currValue = e.currentTarget.value;
    if (isNumberError(currValue)) {
      return;
    }
    setValue(currValue);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (numValidation && numValidation.onBlurFn) {
      const val = numValidation.onBlurFn(e.currentTarget.value);
      if (val) {
        setValue(val);
      }
    }
  };

  const isError = (required && !value) || isNumberError(value) || false;

  return [value, { onChange, onBlur: numValidation ? onBlur : undefined }, isError, setValue];
};

export default useInput;
