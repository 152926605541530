import clsx from 'clsx';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import OATIcon from '../OATIcon';
import './datepicker.module.scss';
import styles from './styles.module.scss';

interface CustomDatePickerProps {
  id?: string;
  selected?: Date;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  darkTheme?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date?: Date) => void;
  isFullWidth?: boolean;
  isNational?: boolean;
  placeholderText?: string;
}

const CustomDatePicker = ({
  id,
  selected,
  error,
  disabled,
  darkTheme,
  className,
  minDate,
  maxDate,
  onChange,
  isFullWidth,
  isNational = false,
  placeholderText = '',
}: CustomDatePickerProps) => {
  const ref = useRef<any>();

  const handleIconClick = () => {
    if (ref && ref.current) {
      ref.current.setOpen(true);
    }
  };

  return (
    <div className={clsx(styles.customDatePicker, isFullWidth && styles.fullWidth)}>
      <DatePicker
        id={id}
        selected={selected}
        ref={ref}
        onChange={date => {
          onChange(date as Date);
        }}
        dateFormat="yyyy-MM-dd"
        disabled={disabled}
        className={clsx(isNational ? styles.nationalInput : styles.input, darkTheme && styles.darkTheme, error && styles.error)}
        wrapperClassName={className}
        minDate={minDate}
        maxDate={maxDate}
        autoComplete="off"
        placeholderText={placeholderText}
      />
      <div className={styles.icon} id={`datepicker-icon-${id}`}>
        <OATIcon icon="calendar" size={14} onClick={handleIconClick} />
      </div>
    </div>
  );
};

export default CustomDatePicker;
