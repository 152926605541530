import { IMultiItem } from '../../models/IMultiItem';

type ReturnType = [excludedList: IMultiItem[], includedList: IMultiItem[]];

const getExcludedIncludedList = (list: IMultiItem[], values: string[], allIncluded = false): ReturnType => {
  const excludedList: IMultiItem[] = [];
  const includedList: IMultiItem[] = [];

  list.forEach(item => {
    if (allIncluded || values.findIndex(val => item.id === val) !== -1) {
      includedList.push(item);
    } else {
      excludedList.push(item);
    }
  });

  return [excludedList, includedList];
};

export default getExcludedIncludedList;
