import React from 'react';
import ShowError from '../../ShowError';
import styles from './styles.module.scss';

interface Props {
  errorMessage: string;
}

const ModalError = ({ errorMessage }: Props) => {
  return <ShowError className={styles.modalError} errorMessage={errorMessage} />;
};

export default ModalError;
