import React from 'react';
import Button from '../Button';
import OATIcon from '../OATIcon';
import styles from './styles.module.scss';

interface Props {
  id?: string;
  addOne?: () => void;
  subtractOne?: () => void;
}

const ArrowButtons = ({ id, addOne, subtractOne }: Props) => {
  return (
    <div className={styles.arrowBtns}>
      <Button
        id={`${id}-add-one-btn`}
        onClick={() => {
          if (addOne) {
            addOne();
          }
        }}
        className={styles.arrowBtn}
      >
        <OATIcon icon="caret-up2" size={8} className={styles.arrowIcon} />
      </Button>
      <Button
        id={`${id}-subtract-one-btn`}
        onClick={() => {
          if (subtractOne) {
            subtractOne();
          }
        }}
        className={styles.arrowBtn}
      >
        <OATIcon icon="caret-down2" size={8} className={styles.arrowIcon} />
      </Button>
    </div>
  );
};

export default ArrowButtons;
