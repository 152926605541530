import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import TokenSettingsComponent from './TokenSettingsComponent';
import { TokenGroup, TokenType } from './types';

type Props = {
  groups: TokenGroup[];
  tokens: string[];
  onCreate: (group: string, token: string, content: string, setSelectedCb: (token: TokenType) => void) => Promise<void>;
  onEdit: (group: string, id: string, token: string, content: string) => Promise<void>;
  onDelete: (group: string, id: string) => Promise<void>;
};

const TokenSettingsLayout = ({ groups, tokens, onCreate, onEdit, onDelete }: Props) => {
  const [selectedGroup, setSelectedGroup] = useState(groups.length ? groups[0] : undefined);

  const handleOnClick = (group: TokenGroup) => {
    setSelectedGroup(group);
  };

  return (
    <>
      <ul className={styles.tabs}>
        {groups.map(item => (
          <li key={item.name}>
            <button
              type="button"
              className={clsx(selectedGroup && selectedGroup.name === item.name && styles.active)}
              id={`ts-group-${item.name}`}
              onClick={() => handleOnClick(item)}
            >
              {item.name}
            </button>
          </li>
        ))}
      </ul>
      {groups.map(item => (
        <React.Fragment key={item.name}>
          {selectedGroup?.name === item.name && item.tokenData && (
            <div>
              <TokenSettingsComponent
                name={item.name}
                modalName={item.modalName || ''}
                data={item.tokenData.data}
                tokens={tokens}
                dataTokens={item.tokenData.dataTokens}
                onCreate={onCreate}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            </div>
          )}
          {selectedGroup?.name === item.name && item.component && <div>{item.component}</div>}
        </React.Fragment>
      ))}
    </>
  );
};

export default TokenSettingsLayout;
