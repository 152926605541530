import { useState } from 'react';

const usePromiseLoading = () => {
  const [promiseLoading, setPromiseLoading] = useState(false);

  const loadPromise = async <T,>(promise: Promise<T>) => {
    try {
      setPromiseLoading(true);
      const res = await promise;
      setPromiseLoading(false);
      return res;
    } catch (e) {
      setPromiseLoading(false);
      throw new Error((e as Error).message);
    }
  };

  return { loadPromise, promiseLoading };
};

export default usePromiseLoading;
