type Props = {
  required?: boolean;
  min?: number;
  max?: number;
};

type RetType = {
  required?: boolean;
  min?: boolean;
  max?: boolean;
};

const validator = (value: any, { required, min, max }: Props) => {
  const retObj: RetType = {};
  const trimVal = typeof value === 'string' ? value.trim() : value;

  if (required && (trimVal === null || trimVal === undefined || trimVal === '')) {
    retObj.required = true;
  }

  if (min !== undefined && Number(trimVal) < min) {
    retObj.min = true;
  }

  if (max !== undefined && Number(trimVal) > max) {
    retObj.max = true;
  }

  return Object.keys(retObj).length ? retObj : undefined;
};

export default validator;
