import React, { useContext, useEffect } from 'react';
import OATToastBar from './OATToastBar';
import styles from './styles.module.scss';
import ToastContext from './ToastContext';
import { DEFAULT_TOAST_TIMEOUT } from './types';

const OATToastContainer = () => {
  const ctx = useContext(ToastContext);
  const { toasts, remove } = ctx;

  useEffect(() => {
    const interval = setInterval(() => {
      if (remove && toasts?.length) {
        remove(toasts[0].id);
      }
    }, DEFAULT_TOAST_TIMEOUT);

    return () => {
      clearInterval(interval);
    };
  }, [toasts]);

  return (
    <div id="oat-toast-container" className={styles.toastContainer}>
      {toasts?.map(toast => (
        <OATToastBar key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

export default OATToastContainer;
