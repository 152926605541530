/* eslint-disable no-console */
import React from 'react';
import AdInput from '../../components/AdInput';
import NoVehicles from '../../components/NoVehicles';
import { ConfigItem, TrimItem, VinItem } from '../../models';
import MostRecentConfig from '../../models/MostRecentConfig';
import ConfigTable from '../../tables/ConfigTable';
import TrimTable from '../../tables/TrimTable';
import VinTable from '../../tables/VinTable';
import styles from './styles.module.scss';

// utils functions for display values in search queries (shows typed in query or selected value)
const getTrim = (item: TrimItem | undefined, trimQuery = '') => {
  return item?.trimTitle || trimQuery;
};

const getConfig = (item: ConfigItem | undefined, configQuery = '') => {
  return item ? item.config : configQuery;
};

const getDealerCode = (item: VinItem | undefined, dealerCodeQuery = '') => {
  return item ? item.dealerCode : dealerCodeQuery;
};

export interface SelectTrimFlowProps {
  // trim
  trimList: TrimItem[] | undefined;
  trimItem: TrimItem | undefined;
  trimQuery: string;

  // config
  configList: ConfigItem[] | undefined;
  configItem: ConfigItem | undefined;
  configQuery: string;

  // vin
  vinList: VinItem[] | undefined;
  vinItem: VinItem | undefined;

  // other/flags
  dealerCodeQuery: string;
  showTrimTable: boolean;
  showConfigTable: boolean;
  showVinTable: boolean;
  showNoVehicles: string | undefined;
  gStatus: boolean;
  isSETUser: boolean;
  isLexusUser: boolean;
  isNationalApp: boolean;
  useSrpLabelLogic: boolean;

  // sort
  sortCol: string;
  sortAsc: boolean;

  portsMap: (port: string) => string;

  handleTrimClick: () => void;
  handleTrimQueryChange: (val: string) => void;
  handleSelectTrim: (item: TrimItem) => Promise<void>;
  handleConfigClick: () => void;
  handleConfigQueryChange: (val: string) => void;
  handleSelectConfig: (item: ConfigItem) => Promise<void>;
  handleSelectVin: (vin: VinItem) => void;
  handleDealerCodeQueryChange: (val: string) => void;

  handleSelectDifferentTrim: () => void;
  handleToNonAdvertised?: () => void;

  handleTrimTableSort: (col: string) => void;
  handleConfigTableSort: (col: string) => void;
  handleVinTableSort: (col: string) => void;

  setGStatus: () => void;
  showVinCount?: boolean;
  showRecentConfig?: boolean;
  mostRecentConfig?: MostRecentConfig;
}

const SelectTrimFlow = ({
  trimList = [],
  trimItem,
  trimQuery,
  configList = [],
  configItem,
  configQuery,
  vinList = [],
  vinItem,
  dealerCodeQuery,
  showTrimTable,
  showConfigTable,
  showVinTable,
  showNoVehicles,
  gStatus,
  isSETUser,
  isLexusUser,
  isNationalApp,
  useSrpLabelLogic,
  sortAsc,
  sortCol,
  portsMap,
  handleTrimClick,
  handleTrimQueryChange,
  handleSelectTrim,
  handleConfigClick,
  handleConfigQueryChange,
  handleSelectConfig,
  handleSelectVin,
  handleDealerCodeQueryChange,
  handleSelectDifferentTrim,
  handleToNonAdvertised,

  handleTrimTableSort,
  handleConfigTableSort,
  handleVinTableSort,

  setGStatus,
  showVinCount = false,
  showRecentConfig = false,
  mostRecentConfig
}: SelectTrimFlowProps) => {
  return (
    <>
      <div className={styles.trimFlowInputs}>
        <AdInput
          className={styles.trimInputContainer}
          id="trim-input"
          label="Trim"
          type={showTrimTable ? 'number' : 'text'}
          placeholder="Search by model code"
          inputClickHandler={handleTrimClick}
          onChangeHandler={e => handleTrimQueryChange(e.currentTarget.value)}
          value={getTrim(trimItem, trimQuery)}
          icon="search"
          units
        />
        <AdInput
          className={styles.configInputContainer}
          id="config-input"
          label="Configuration"
          inputClickHandler={handleConfigClick}
          onChangeHandler={e => handleConfigQueryChange(e.currentTarget.value)}
          value={getConfig(configItem, configQuery)}
          disabled={showTrimTable}
          icon="search"
        />
        <AdInput
          className={styles.dealerCodeContainer}
          id="dealer-code-input"
          label="Dealer Code"
          type="number"
          onChangeHandler={e => handleDealerCodeQueryChange(e.currentTarget.value)}
          value={getDealerCode(vinItem, dealerCodeQuery)}
          disabled={showTrimTable || showConfigTable}
          icon="search"
          units
          allowLeadingZeros
        />
      </div>

      {showNoVehicles && <NoVehicles message={showNoVehicles} handleSelectDifferentTrim={handleSelectDifferentTrim} handleToNonAdvertised={handleToNonAdvertised} />}
      {!showNoVehicles && showTrimTable && (
        <TrimTable
          trimList={trimList}
          trimQuery={trimQuery}
          sortField={sortCol}
          sortAsc={sortAsc}
          handleSelectTrim={handleSelectTrim}
          handleSort={handleTrimTableSort}
          showVinCount={showVinCount}
        />
      )}
      {!showNoVehicles && showConfigTable && (
        <ConfigTable
          configList={configList}
          configQuery={configQuery}
          sortField={sortCol}
          sortAsc={sortAsc}
          handleSelectConfig={handleSelectConfig}
          handleSort={handleConfigTableSort}
          showRecentConfig={showRecentConfig}
          mostRecentConfig={mostRecentConfig}
        />
      )}
      {!showNoVehicles && showVinTable && (
        <VinTable
          vinList={vinList}
          dealerCodeQuery={dealerCodeQuery}
          sortField={sortCol}
          sortAsc={sortAsc}
          handleSelectVin={handleSelectVin}
          handleSort={handleVinTableSort}
          isSETUser={isSETUser}
          isLexusUser={isLexusUser}
          isNationalApp={isNationalApp}
          useSrpLabelLogic={useSrpLabelLogic}
          gStatus={gStatus}
          setGStatus={setGStatus}
          portsMap={portsMap}
        />
      )}
    </>
  );
};

export default SelectTrimFlow;
