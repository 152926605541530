import axios from 'axios';

const uploadWithPresignedUrl = async (presignedUploadUrl: string, file: File) => {
  const obj: any = JSON.parse(presignedUploadUrl);
  const formData = new FormData();
  Object.entries(obj.fields).forEach(([key, value]) => {
    formData.set(key, value as string);
  });
  formData.set('file', file);
  await axios.post(obj.url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export default uploadWithPresignedUrl;