import clsx from 'clsx';
import React from 'react';
import BlockInputLabel from '../../../components/BlockInputLabel';
import BoxSelector from '../../../components/BoxSelector';
import Checkbox from '../../../components/Checkbox';
import InlineInputLabel from '../../../components/InlineInputLabel';
import { TermItem } from '../../models';
import styles from './styles.module.scss';

interface Props {
  termItems: TermItem[];
  termsList: string[];
  selectedTermsList: string[];
  tiersList: string[];
  selectedTiers: string[];
  blended: boolean;
  isLexus: boolean;
  isSpecialEdition: boolean;
  setBlended: (val: boolean) => void;
  onTermsSelect: (vals: string[]) => void;
  onTiersSelect: (vals: string[]) => void;
  onSpecialEdition: (val: boolean) => void;
}

const TierTermFlow = ({
  termItems,
  termsList,
  selectedTermsList,
  tiersList,
  selectedTiers,
  blended,
  isLexus,
  isSpecialEdition,
  setBlended,
  onTermsSelect,
  onTiersSelect,
  onSpecialEdition,
}: Props) => {
  return (
    <>
      {/* Advertised selector */}
      <BlockInputLabel className={clsx(styles.inputLabel, styles.advTermselecctor)} label="Terms">
        <div className={styles.boxWrapper}>
          <BoxSelector id="advTermsSelector" options={termsList} selected={selectedTermsList} setSelected={onTermsSelect} />
        </div>

        {/* Term selector */}
        <div className={styles.termOptionContainer}>
          {termItems.map(term => (
            <BlockInputLabel label={`Term ${term.term}`} className={styles.inputLabel} key={term.term}>
              <input
                type="checkbox"
                checked={term.isAdvertised}
                name={`term-${term.term}`}
                onChange={e => {
                  term.isAdvertised = e.target.checked;
                }}
              />
              <span className={styles.advLabel}>Advertised</span>
            </BlockInputLabel>
          ))}
        </div>
      </BlockInputLabel>

      {/* Tier selector */}
      <BlockInputLabel label="Tiers" className={clsx(styles.tiers, styles.inputLabel)}>
        <BoxSelector id="advTiersSelector" options={tiersList} selected={selectedTiers} setSelected={onTiersSelect} />
      </BlockInputLabel>

      {/* Blended/By Tier options */}
      <div className={styles.optionType}>
        <BlockInputLabel className={styles.inputLabel} label="Option Type">
          <label htmlFor="advBlended">
            <input type="radio" id="advBlended" checked={blended} onChange={() => setBlended(true)} />
            Blended
          </label>
          <label htmlFor="advByTier">
            <input type="radio" id="advByTier" checked={!blended} onChange={() => setBlended(false)} />
            By Tier
          </label>
        </BlockInputLabel>
      </div>

      {/* Special Edition */}
      {isLexus && (
        <div className={styles.specialEditionWrapper}>
          <InlineInputLabel label="Special Edition" className={styles.label}>
            <Checkbox id="special-edition-checkbox" onChange={e => onSpecialEdition(e.target.checked)} isChecked={isSpecialEdition} />
          </InlineInputLabel>
        </div>
      )}
    </>
  );
};

export default TierTermFlow;
