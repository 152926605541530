import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

export type BreadCrumbType = {
  name: string;
  component?: ReactNode;
  url?: string;
};

interface Props {
  data: BreadCrumbType[];
}

const BreadCrumbs = ({ data }: Props) => {
  const renderItem = (item: BreadCrumbType) => {
    if (item.component) {
      return item.component;
    }

    if (item.url) {
      return <a href="/url">{item.name}</a>;
    }

    return <>{item.name}</>;
  };

  return (
    <div className={styles.breadCrumbs}>
      <ul>
        {data.map((item, index) => {
          const i = index;
          return (
            <React.Fragment key={i}>
              <li className={styles.text}>{renderItem(item)}</li>
              {index < data.length - 1 && <li className={styles.div}>/</li>}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default BreadCrumbs;
