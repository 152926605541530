import clsx from 'clsx';
import React, { AnchorHTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  navigate?: any; // fix issue where Link from react-router tries to pass down navigate to <a>. Results in unit test warning.
}

const OATLink = ({ children, navigate, className, ...rest }: Props) => {
  return (
    <a className={clsx(styles.oatLink, className)} {...rest}>
      {children}
    </a>
  );
};

export default OATLink;
