import React, { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

const IconMenu = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ children, className }: HTMLAttributes<HTMLDivElement>, ref) => {
  return (
    <div className={styles.iconMenu} ref={ref}>
      <ul className={className}>{children}</ul>
    </div>
  );
});

export default IconMenu;
