import React, { ReactNode } from 'react';
import BreadCrumbs, { BreadCrumbType } from '../../components/BreadCrumbs';
import OATHeader from '../../components/OATHeader';
import styles from './styles.module.scss';

interface Props {
  isProd: boolean;
  breadCrumbs?: BreadCrumbType[];
  renderLogo: ReactNode;
  renderTitle: ReactNode;
  renderMiscLinks?: ReactNode;
  rightContent?: ReactNode;
  showRightContent?: boolean;
}
const DefaultHeaderLayout = ({ isProd, breadCrumbs = [], renderLogo, renderTitle, renderMiscLinks, rightContent, showRightContent }: Props) => {
  return (
    <OATHeader isProd={isProd}>
      <div className={styles.leftSide}>
        <div className={styles.container}>{renderLogo}</div>
        <div className={styles.container}>
          {renderTitle}
          <BreadCrumbs data={breadCrumbs} />
        </div>
      </div>
      <div className={styles.container}>{renderMiscLinks}</div>
      {showRightContent && rightContent}
    </OATHeader>
  );
};

export default DefaultHeaderLayout;
