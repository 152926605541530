import React, { useEffect, useState } from 'react';
import { IMultiItem } from '../../models/IMultiItem';
import MultiSelectPanes from './MultiSelectPanes';

interface Props {
  returnSelectorData: (excluded: IMultiItem[], included: IMultiItem[], setInc?: (included: IMultiItem[]) => void) => void;
  excludedList?: IMultiItem[];
  includedList?: IMultiItem[];
  error?: boolean;
  onSelect?: (excludedList: IMultiItem[], includedList: IMultiItem[]) => void;
  onMove?: (excludedList: IMultiItem[], includedList: IMultiItem[], selectedList: IMultiItem[], toExcluded: boolean) => void;
  isNational?: boolean;
  allowSearch?: boolean;
  leftLabel?: string;
  leftSearchPlaceholder?: string;
  rightLabel?: string;
  rightSearchPlaceholder?: string;
}

const DefaultMultiSelectPanes = ({
  excludedList,
  includedList,
  returnSelectorData,
  onSelect,
  onMove,
  error,
  isNational = false,
  allowSearch = false,
  leftLabel = '',
  leftSearchPlaceholder = '',
  rightLabel = '',
  rightSearchPlaceholder = '',
}: Props) => {
  const [excludedArray, setExcludedArray] = useState<IMultiItem[]>(excludedList || []);
  const [includedArray, setIncludedArray] = useState<IMultiItem[]>(includedList || []);
  const [excludedFilteredResults, setExcludedFilteredResults] = useState<IMultiItem[]>([]);
  const [includedFilteredResults, setIncludedFilteredResults] = useState<IMultiItem[]>([]);

  useEffect(() => {
    returnSelectorData(excludedArray, includedArray);
  }, [includedArray, excludedArray]);

  const handleOnSelect = (onSelectExcludedList: IMultiItem[], onSelectIncludedList: IMultiItem[]) => {
    setExcludedArray(onSelectExcludedList);
    setIncludedArray(onSelectIncludedList);

    if (onSelect) {
      onSelect(onSelectExcludedList, onSelectIncludedList);
    }
  };

  const handleOnMove = (onMoveExcludedList: IMultiItem[], onMoveIncludedList: IMultiItem[], selectedList: IMultiItem[], toExcluded: boolean) => {
    setExcludedArray(onMoveExcludedList);
    setIncludedArray(onMoveIncludedList);

    if (onSelect) {
      onSelect(onMoveExcludedList, onMoveIncludedList);
    }

    if (onMove) {
      onMove(onMoveExcludedList, onMoveIncludedList, selectedList, toExcluded);
    }
  };

  const handleSearchOnExcluded = (excluded: IMultiItem[], query = '') => {
    setExcludedFilteredResults([]);
    if (query !== '') {
      const excl = excluded.filter(item => {
        return Object.values(item).join('').toLowerCase().includes(query.toLowerCase());
      });
      setExcludedFilteredResults(excl);
    }
  };

  const handleSearchOnIncluded = (included: IMultiItem[], query = '') => {
    setIncludedFilteredResults([]);
    if (query !== '') {
      const incl = included.filter(item => {
        return Object.values(item).join('').toLowerCase().includes(query.toLowerCase());
      });
      setIncludedFilteredResults(incl);
    }
  };

  return (
    <MultiSelectPanes
      excludedList={excludedFilteredResults.length > 0 ? excludedFilteredResults : excludedArray}
      includedList={includedFilteredResults.length > 0 ? includedFilteredResults : includedArray}
      onMove={handleOnMove}
      onSelect={handleOnSelect}
      error={error}
      isNational={isNational}
      allowSearch={allowSearch}
      leftLabel={leftLabel}
      leftSearchPlaceholder={leftSearchPlaceholder}
      rightLabel={rightLabel}
      rightSearchPlaceholder={rightSearchPlaceholder}
      searchOnExcluded={handleSearchOnExcluded}
      searchOnIncluded={handleSearchOnIncluded}
    />
  );
};

export default DefaultMultiSelectPanes;
