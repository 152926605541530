import React from 'react';
import sortFieldsHelper from '../../utils/sortFieldsHelper';
import { TrimItem } from '../models';
import CreateLeaseTable from './CreateLeaseTable';
import TrimTableRow from './TrimTableRow';

const trimHeaderColumns = [
  { header: 'Model Code', field: 'code', centered: true, sortable: true },
  { header: 'Grade', field: 'grade', centered: true, sortable: true },
  { header: 'Trim', field: 'trimTitle', sortable: true },
  { header: 'Count', field: 'count', centered: true, sortable: true },
  { header: 'Residual', field: 'residual', centered: true, sortable: true },
];

interface Props {
  trimList: TrimItem[];
  trimQuery: string;
  sortField: string;
  sortAsc: boolean;
  handleSelectTrim: (item: TrimItem) => Promise<void>;
  handleSort: (field: string) => void;
  showVinCount?: boolean;
}

const TrimTable = ({ trimList, trimQuery, sortField, sortAsc, handleSelectTrim, handleSort, showVinCount = false }: Props) => {
  const filteredTrimList = trimList.filter(item => !trimQuery || item.code.includes(trimQuery)).sort(sortFieldsHelper(sortField, sortAsc));
  const filteredTrimHeaderColumns = trimHeaderColumns.filter(column => {
    return !(column.header === 'Count' && !showVinCount);
  });

  return (
    <CreateLeaseTable headerColumns={filteredTrimHeaderColumns} sortField={sortField} sortAscending={sortAsc} handleSort={handleSort}>
      {filteredTrimList.map(item => {
        return <TrimTableRow key={item.code} item={item} handleSelectTrim={handleSelectTrim} showVinCount={showVinCount} />;
      })}
    </CreateLeaseTable>
  );
};

export default TrimTable;
