import React from 'react';
import BlockInputLabel from '../../../components/BlockInputLabel';
import Button from '../../../components/Button';
import ButtonGroup from '../../../components/ButtonGroup';
import Input from '../../../components/Input';
import NoVehicles from '../../components/NoVehicles';
import VinItem from '../../models/VinItem';
import VinTable from '../../tables/VinTable';
import { getAlphanumericString } from '../../utils/utils';
import styles from './styles.module.scss';

interface Props {
  vinList: VinItem[] | undefined;
  vinQuery: string;
  hasVinQueryError: boolean;
  showVinList: boolean;
  isSETUser: boolean;
  isLexusUser: boolean;
  useSrpLabelLogic: boolean;
  isNationalApp: boolean;
  showNoVehicles?: string;
  sortCol: string;
  sortAsc: boolean;
  handleSelectVin: (item: VinItem) => void;
  portsMap: (port: string) => string;
  handleVinSearchReset: () => void;
  handleVinSearch: () => void;
  onVinQueryChange: (query: string) => void;
  handleSortColumns: (column: string) => void;
}

const EnterVinFlow = ({
  vinList = [],
  vinQuery = '',
  hasVinQueryError,
  showVinList,
  isSETUser,
  isLexusUser,
  isNationalApp,
  useSrpLabelLogic,
  showNoVehicles = '',
  sortCol,
  sortAsc,
  handleSelectVin,
  portsMap,
  handleVinSearch,
  handleVinSearchReset,
  onVinQueryChange,
  handleSortColumns,
}: Props) => {
  return (
    <>
      <BlockInputLabel className={styles.vinSearch} labelComponent={<span className={styles.trimInputLabel}>Enter Vin</span>}>
        <Input id="vin-search-input" value={vinQuery} onChange={e => onVinQueryChange(getAlphanumericString(e.currentTarget.value))} error={hasVinQueryError} />
      </BlockInputLabel>
      <ButtonGroup className={styles.vinSearchButtonGroup}>
        <Button id="reset-vin-cta" variant="primary" disabled={hasVinQueryError} onClick={handleVinSearchReset}>
          Reset
        </Button>
        <Button id="search-vin-cta" variant="primary" disabled={hasVinQueryError} onClick={handleVinSearch}>
          Search
        </Button>
      </ButtonGroup>
      {showNoVehicles && showVinList && <NoVehicles message={showNoVehicles} isEnterVinFlow />}
      {vinList.length > 0 && vinQuery === vinList[0].vin && !showNoVehicles && showVinList && (
        <>
          <div className={styles.vinSearchTableWrapper}>
            <VinTable
              vinList={vinList}
              dealerCodeQuery=""
              sortField={sortCol}
              sortAsc={sortAsc}
              isSETUser={isSETUser}
              isLexusUser={isLexusUser}
              isNationalApp={isNationalApp}
              useSrpLabelLogic={useSrpLabelLogic}
              gStatus={false}
              setGStatus={() => {}}
              portsMap={portsMap}
              handleSelectVin={handleSelectVin}
              handleSort={handleSortColumns}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EnterVinFlow;
