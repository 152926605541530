import React, { ReactNode } from 'react';
import OATWrapper from '../../components/OATWrapper';
import styles from './styles.module.scss';

interface Props {
  renderHeader: ReactNode;
  renderActiveTable: ReactNode;
  renderArchiveTable: ReactNode;
  renderButton?: ReactNode;
  renderDropdown?: ReactNode;
  children?: ReactNode;
}

const OfferingsLayout = ({ renderHeader, renderActiveTable, renderButton, renderArchiveTable, renderDropdown, children }: Props) => {
  return (
    <div className={styles.offeringsLayout}>
      {renderHeader}
      <section className={styles.activeSection}>
        <OATWrapper>
          <div className={styles.dropdownSection}>{renderDropdown}</div>
          {renderActiveTable}
          <div className={styles.buttonSection}>{renderButton}</div>
        </OATWrapper>
      </section>
      <section className={styles.archiveSection}>
        <OATWrapper>{renderArchiveTable}</OATWrapper>
      </section>
      {children}
    </div>
  );
};

export default OfferingsLayout;
