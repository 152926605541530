import clsx from 'clsx';
import React from 'react';
import BlockInputLabel from '../../../components/BlockInputLabel';
import Input from '../../../components/Input';
import NumberInput from '../../../components/NumberInput';
import OATIcon from '../../../components/OATIcon';
import IconNames from '../../../components/OATIcon/IconNames';
import styles from './styles.module.scss';

interface Props {
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  id: string;
  className: string;
  label: string;
  value: string;
  icon: IconNames;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputClickHandler?: () => void;
  buttonClickHandler?: () => void;
  units?: boolean;
  allowLeadingZeros?: boolean;
}

const AdInput = ({ placeholder, type, disabled, id, className, label, value, icon, allowLeadingZeros, inputClickHandler, onChangeHandler, buttonClickHandler, units }: Props) => {
  return (
    <div className={clsx(styles.adInput, className)}>
      <div className={disabled ? styles.disabled : undefined}>
        <BlockInputLabel labelComponent={<span className={styles.label}>{label}</span>}>
          {type === 'number' ? (
            <NumberInput
              id={id}
              onClick={inputClickHandler}
              placeholder={placeholder}
              onChange={onChangeHandler}
              value={value}
              className={styles.input}
              disabled={disabled}
              allowNegative={false}
              units={units}
              allowLeadingZeros={allowLeadingZeros}
            />
          ) : (
            <Input id={id} onClick={inputClickHandler} placeholder={placeholder} onChange={onChangeHandler} value={value} className={styles.input} disabled={disabled} />
          )}
          <OATIcon icon={icon} colorTheme="blue" className={styles.icon} onClick={buttonClickHandler} />
        </BlockInputLabel>
      </div>
    </div>
  );
};

export default AdInput;
