import React, { ReactNode } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

interface Props {
  droppableId: string;
  children: ReactNode;
  handleOnDragEnd: (result: DropResult) => void;
}

// must include Draggable component as well
// view MarketingTab for reference

const DragDrop = ({ droppableId, children, handleOnDragEnd }: Props) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={droppableId}>
        {provided => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {children}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDrop;
