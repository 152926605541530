import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
}

const TableTitle = ({ className, children }: Props) => {
  return <div className={cx(styles.tableTitle, className)}>{children}</div>;
};

export default TableTitle;
