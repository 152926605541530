import get3dImages from './get3dImages';
import getInventory from './getInventory';
import getSeries from './getSeries';
import getSeriesV1 from './getSeriesV1';
import getStopSaleSSC from './getStopSaleSSC';
import getStopSaleVIS from './getStopSaleVIS';
import getVinDetails from './getVinDetails';
import getVinList from './getVinList';
import utils from './utils';

export default {
  getSeriesV1,
  get3dImages,
  getSeries,
  getInventory,
  getVinDetails,
  getVinList,
  getStopSaleSSC,
  getStopSaleVIS,
  ...utils,
};
