import clsx from 'clsx';
import React, { ReactNode } from 'react';
import Button from '../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  tab: number;
  children?: ReactNode;
  onTabClick: (tab: number) => void;
  handleToNonAdvertised?: () => void;
}

const CreateLeaseTabs = ({ tab, children, onTabClick, handleToNonAdvertised }: Props) => {
  return (
    <>
      <div className={styles.tabs}>
        <div>
          <Button id="select-trim-tab-btn" className={clsx(styles.tabBtn, tab === 1 && styles.active)} onClick={() => onTabClick(1)}>
            Select Trim
          </Button>
          <Button id="enter-vin-tab-btn" className={clsx(styles.tabBtn, tab === 2 && styles.active)} onClick={() => onTabClick(2)}>
            Enter VIN
          </Button>
        </div>

        {handleToNonAdvertised && (
          <Button id="make-non-ad-btn" className={styles.offerHeaderLink} onClick={handleToNonAdvertised} variant="text">
            Make Non-Advertised
          </Button>
        )}
      </div>
      <div className={styles.tabContent}>{children}</div>
    </>
  );
};

export default CreateLeaseTabs;
