import React from 'react';
import Button from '../../components/Button';
import MostRecentConfig from '../models/MostRecentConfig';
import { getLeaseConfigList } from '../utils/utils';
import styles from './styles.module.scss';


interface Props {
  recentConfig: MostRecentConfig;
  handleSelectConfig: () => void;
}

const RecentConfigTableRow = ({ handleSelectConfig, recentConfig }: Props) => {
  return (
    <tr>
      <td>
        <Button id={`config-${recentConfig.configuration}`} onClick={handleSelectConfig} className={styles.blueTextBtn} variant="text">
          {getLeaseConfigList(recentConfig.configuration)}
        </Button>
      </td>
      <td>{recentConfig.salesPeriod}</td>
      <td>{recentConfig.count}</td>
      <td>{recentConfig.inventory}</td>
    </tr>
  );
}

export default RecentConfigTableRow;