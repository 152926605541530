const isRcfValid = (value?: string) => {
  if (Math.abs(Number(value)) > 0.99999) {
    return false;
  }

  if (value) {
    if (value.toString().charAt(0) === '-') {
      return value.toString().length <= 8;
    }

    return value.toString().length <= 7;
  }

  return true;
};

export default isRcfValid;
