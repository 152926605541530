import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface TierTableIconCellProps {
  tdClassName: string;
  iconMethod: (val: any) => void;
  iconMethodParam: any;
  id: string;
  iconName: any;
  iconClassName: string;
}

const TierTableIconCell = ({ tdClassName, iconMethod, iconMethodParam, id, iconName, iconClassName }: TierTableIconCellProps) => {
  return (
    <td id={id} className={tdClassName}>
      <button type="button" onClick={() => iconMethod(iconMethodParam)} id="add-to-table">
        <FontAwesomeIcon icon={iconName} className={iconClassName} />
      </button>
    </td>
  );
};

export default TierTableIconCell;
