import React from 'react';
import Button from '../../components/Button';
import { TrimItem } from '../models';
import { calculateResidual } from '../utils/utils';
import styles from './styles.module.scss';

interface Props {
  item: TrimItem;
  handleSelectTrim: (item: TrimItem) => Promise<void>;
  showVinCount?: boolean;
}

const TrimTableRow = ({ item, handleSelectTrim, showVinCount = false }: Props) => {
  return (
    <tr>
      <td className={styles.centerTableValue}>
        <Button id={`trim-${item.code}`} onClick={() => handleSelectTrim(item)} className={styles.blueTextBtn} variant="text">
          {item.code}
        </Button>
      </td>
      <td className={styles.centerTableValue}>{item.grade}</td>
      <td>{item.title}</td>
      {showVinCount && <td className={styles.centerTableValue}>{item.count}</td>}
      <td className={styles.centerTableValue}>{calculateResidual(Number(item.residual))}</td>
    </tr>
  );
};

export default TrimTableRow;
