import React from 'react';
import blankCarImg from '../../../assets/blank_car.png';

interface Props {
  source?: string;
  className?: string;
}

const CarJellyImage = ({ className, source }: Props) => {
  return (
    <img
      className={className}
      src={source}
      alt="car_jelly"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = blankCarImg;
      }}
    />
  );
};

export default CarJellyImage;
