import axios from 'axios';
import DD365VinDetailsResponse from './models/DD365VinDetailsResponse';

const getDD365VinDetails = (token: string, domain: string, vin: string) => {
  const url = `/dd365/vin/${vin}`;
  return axios.get<DD365VinDetailsResponse>(`${domain}${url}`, {
    headers: { Authorization: token },
  });
};

export default getDD365VinDetails;
