import React from 'react';
import DollarDisplay from '../../../../components/DollarDisplay';
import { formatDollars } from '../../../../utils/formatNumber';
import OfferTabHeaderDetailContainer from '../OfferTabHeaderDetailContainer';
import OfferTabHeaderDetailLabel from '../OfferTabHeaderDetailLabel';
import OfferTabHeaderDetailLabelValue from '../OfferTabHeaderDetailLabelValue';
import OfferTabHeaderDetailRow from '../OfferTabHeaderDetailRow';

interface Props {
  cashAmount: string;
  isMisc: boolean;
  miscType: string;
}

const CashHeaderDetails = ({ cashAmount, isMisc, miscType }: Props) => {
  const formattedAmount = formatDollars(cashAmount);
  return (
    <OfferTabHeaderDetailRow>
      <OfferTabHeaderDetailContainer isMargin={false}>
        <OfferTabHeaderDetailLabelValue>{!formattedAmount ? '--' : <DollarDisplay value={formattedAmount} />}</OfferTabHeaderDetailLabelValue>
        <OfferTabHeaderDetailLabel>{isMisc ? miscType : ' Per Customer'}</OfferTabHeaderDetailLabel>
      </OfferTabHeaderDetailContainer>
    </OfferTabHeaderDetailRow>
  );
};

export default CashHeaderDetails;
