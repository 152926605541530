import React, { ReactNode } from 'react';
import OfferTabBodyFooter from './OfferTabBodyFooter';
import styles from './styles.module.scss';

interface Props {
  renderContent: ReactNode;
  renderFooter?: ReactNode;
}

const OfferTabBodyLayout = ({ renderContent, renderFooter }: Props) => {
  return (
    <div className={styles.offerTabBody}>
      {renderContent}
      <OfferTabBodyFooter>{renderFooter}</OfferTabBodyFooter>
    </div>
  );
};

export default OfferTabBodyLayout;
