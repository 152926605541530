export const APP_NAME = 'Offers Admin Tool';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

// Generic. Needs to pull from oat-admin-common
export enum Brands {
  TOYOTA = 'Toyota',
  LEXUS = 'Lexus',
}

export interface Tda {
  id: string;
  code: string;
  name: string;
  brand: string;
  regionCode: string;
  agency: string;
  batTda?: string;
}

// CUV Specific
export enum OfferTypes {
  CUSTOMER_CASH = 'Cash',
  APR = 'APR',
  LEASE = 'Lease',
  MISCELLANEOUS = 'Misc',
  OTHER = 'Other',
}

// Generic. Needs to pull from oat-admin-common
export enum OfferTypesLong {
  CUSTOMER_CASH = 'Customer Cash',
  APR = 'APR',
  LEASE = 'Lease',
  MISCELLANEOUS = 'Miscellaneous',
  OTHER = 'Other',
}

export enum MarketingSites {
  BAT = 'BAT',
  TCOM = 'TCOM',
  TCOM_OTHER = 'TCOM/Other Sites',
}

export enum OfferStatus {
  IN_PROGRESS = 'In Progress',
  PAUSED = 'Paused',
  PUBLISHED = 'Published',
  PUBLISHED_PENDING_CHANGES = 'Published Pending Changes',
  READY_TO_PUBLISH = 'Ready To Publish',
  READY_TO_PREVIEW = 'Ready To Preview',
  PREVIEWED = 'Previewed',
  UNPUBLISHED = 'Unpublished',
  UPDATED = 'Updated',
  DRAFT = 'Draft',
}

type MonthNamesConstantModel = {
  [key: number]: string;
};

export const MonthFullNameNumberConstant: MonthNamesConstantModel = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const MonthShortNamesConstant: MonthNamesConstantModel = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const SeriesCodeToNameMap: Record<string, string> = {
  '4runner': '4Runner',
  avalon: 'Avalon',
  avalonhybrid: 'Avalon Hybrid',
  camry: 'Camry Gas',
  camryhybrid: 'Camry Hybrid',
  chr: 'C-HR',
  corolla: 'Corolla',
  corollacross: 'Corolla Cross',
  corollahybrid: 'Corolla Hybrid',
  corollaim: 'Corolla iM',
  corollahatchback: 'Corolla Hatchback',
  highlander: 'Highlander Gas',
  highlanderhybrid: 'Highlander Hybrid',
  landcruiser: 'Land Cruiser',
  matrix: 'Matrix',
  mirai: 'Mirai',
  priusc: 'Prius C',
  prius: 'Prius Liftback',
  priusplugin: 'Prius Plug-in Hybrid',
  priusv: 'Prius V',
  priusprime: 'Prius Prime',
  rav4: 'RAV4 Gas',
  rav4ev: 'RAV4 EV',
  rav4hybrid: 'RAV4 Hybrid',
  sequoia: 'Sequoia',
  sienna: 'Sienna',
  tacoma: 'Tacoma',
  tundra: 'Tundra',
  rav4prime: 'RAV4 Prime',

  // model mapping manual added
  tundracrewmax: 'Tundra Crew Max',
  tundrabccab: 'Tundra bc Cab',

  venza: 'Venza',
  yaris: 'Yaris',
  yarisia: 'Yaris iA',
  yarisliftback: 'Yaris Liftback',
  yarishatchback: 'Yaris Hatchback',
  supra: 'Supra',
  '86': '86',

  CTh: 'CT',
  ES: 'ES',
  ESh: 'ES Hybrid',
  GS: 'GS',
  GSh: 'GS Hybrid',
  GX: 'GX',
  IS: 'IS',
  ISF: 'IS F',
  ISC: 'IS C',
  LC: 'LC',
  LCh: 'LC Hybrid',
  LS: 'LS',
  LSh: 'LS Hybrid',
  LX: 'LX',
  RXh: 'RX Hybrid',
  NX: 'NX',
  NXh: 'NX Hybrid',
  RC: 'RC',
  RX: 'RX',
  RCF: 'RC F',
  UX: 'UX',
  UXh: 'UX Hybrid',

  // model mapping manual added
  GS200t: 'GS 200t',
  GS350: 'GS 350',
  GSF: 'GS F',
  IS200t: 'IS 200t',
  IS350: 'IS 350',
  ES300h: 'ES 300h',
  ES350: 'ES 350',

  frsrs2: 'FR-S RS 2',
  ia: 'iA',
  frs: 'FR-s',
  tcrs: 'tc RS',
  tC: 'tC',
  xb: 'xB',
  xb686: 'xB 686 Parklan Ed',
  im: 'iM',
  tcrs10: 'tC Release Series 10.0',
};
