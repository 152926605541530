import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  name: string;
  icon?: ReactNode;
  component?: ReactNode;
  url?: string;
}
const OATHeaderLink = ({ name, icon, component, url }: Props) => {
  const renderLink = () => {
    if (component) {
      return component;
    }

    return <a href={url}>{name}</a>;
  };

  return (
    <div className={styles.oatHeaderLink}>
      <div className={styles.icon}>{icon}</div>
      <div>{renderLink()}</div>
    </div>
  );
};

export default OATHeaderLink;
