import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  label?: string;
  labelComponent?: ReactNode;
  isRequired?: boolean;
  className?: string;
  labelClass?: string;
  bottomContent?: ReactNode;
}

const BlockInputLabel = ({ children, label, labelComponent, isRequired, className, labelClass, bottomContent }: Props) => {
  return (
    <div className={clsx(styles.container, className)}>
      <span className={clsx(styles.label, labelClass)}>
        {labelComponent || label}
        {isRequired && <span className={styles.requiredField}>*</span>}
      </span>
      <div className={styles.content}>{children}</div>
      {bottomContent && <div className={styles.bottomContent}>{bottomContent}</div>}
    </div>
  );
};

export default BlockInputLabel;
