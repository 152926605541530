import React from 'react';
import Button from '../../../components/Button';
import OATIcon from '../../../components/OATIcon';
import styles from './styles.module.scss';

interface Props {
  message: string;
  handleSelectDifferentTrim?: () => void;
  handleToNonAdvertised?: () => void;
  isEnterVinFlow?: boolean;
}

const NoVehicles = ({ message, handleSelectDifferentTrim, handleToNonAdvertised, isEnterVinFlow = false }: Props) => {
  return (
    <div className={styles.noVehiclesContainer}>
      {!isEnterVinFlow && (
        <Button id="no-veh-select-trim-btn" variant="text" onClick={handleSelectDifferentTrim}>
          Select different Trim
        </Button>
      )}
      {handleToNonAdvertised && (
        <Button id="no-veh-make-non-ad-btn" variant="text" onClick={handleToNonAdvertised}>
          Make Non-Advertised
        </Button>
      )}
      <div>
        <OATIcon icon="warning" colorTheme="red" />
        <span className={styles.noVehiclesText}>{message}</span>
      </div>
    </div>
  );
};

export default NoVehicles;
