import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface ShowErrorProps {
  errorMessage: string;
  className?: string;
}

const ShowError = ({ errorMessage, className }: ShowErrorProps) => {
  return (
    <div className={clsx(styles.container, className)}>
      <FontAwesomeIcon icon={faInfoCircle} className={styles.errorIcon} />
      <span className={styles.errorMessage}>{errorMessage}</span>
    </div>
  );
};

export default ShowError;
