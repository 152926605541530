import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type ButtonType = 'primary' | 'text' | 'error' | 'input';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonType;
  icon?: ReactNode;
}

const Button = ({ variant, className, icon, children, ...rest }: Props) => {
  return (
    <button
      type="button"
      className={clsx(
        [
          {
            [styles.button]: true,
            [styles.primary]: variant === 'primary',
            [styles.text]: variant === 'text',
            [styles.error]: variant === 'error',
            [styles.input]: variant === 'input',
          },
        ],
        className,
      )}
      {...rest}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </button>
  );
};

export default Button;
