import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ButtonGroupProps {
  children: ReactNode;
  className?: string;
}

const ButtonGroup = ({ children, className }: ButtonGroupProps) => {
  return <div className={`${styles.buttonGroup} ${className}`}>{children}</div>;
};

export default ButtonGroup;
