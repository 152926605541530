import clsx from 'clsx';
import React from 'react';
import IcoMoon from './IcoMoon';
import IconNames from './IconNames';
import iconSet from './selection.json';
import styles from './styles.module.scss';

type ColorThemeType = 'green' | 'orange' | 'red' | 'blue' | 'white' | 'natBlue';

type Props = {
  icon: IconNames;
  size?: string | number;
  className?: string;
  style?: React.CSSProperties;
  colorTheme?: ColorThemeType;
  pointer?: boolean;
  [name: string]: any;
};

const OATIcon = ({ size = 14, colorTheme, className, pointer, ...props }: Props) => {
  const getColorTheme = () => {
    return colorTheme && styles[colorTheme] ? styles[colorTheme] : undefined;
  };

  return <IcoMoon className={clsx(className, getColorTheme(), pointer && styles.pointer)} iconSet={iconSet} size={size} {...props} />;
};

export default OATIcon;
