import { parse } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '../../constants/global';
import dateFormatISOString from '../dateFormatISOString';

const dateStringToDate = (date: Date | string) => {
  // Ensure that if doing 'new Date()', we use 'yyyy-MM-dd' without extra time.
  // This is for comparing using the following method, 'a.getTime() ==== b.getTime()';
  const initDate = typeof date !== 'string' ? dateFormatISOString(date) : date;
  return parse(initDate, DEFAULT_DATE_FORMAT, new Date());
};

export default dateStringToDate;
