import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  id: string;
  renderIcon: ReactNode;
  children?: ReactNode;
  onClick?: () => void;
}

const IconMenuItem = ({ id, renderIcon, children, onClick }: Props) => {
  return (
    <li className={styles.iconMenuItem}>
      <button id={id} type="button" onClick={onClick}>
        {renderIcon && <div className={styles.icon}>{renderIcon}</div>}
        {children}
      </button>
    </li>
  );
};

export default IconMenuItem;
