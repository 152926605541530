import React from 'react';
import styles from './styles.module.scss';

const OATLoadingBar = () => {
  return (
    <div className={styles.loadingBar}>
      <span className={styles.bar} />
    </div>
  );
};

export default OATLoadingBar;
