import clsx from 'clsx';
import React from 'react';
import { OfferStatus } from '../../constants/global';
import OATIcon from '../OATIcon';
import IconNames from '../OATIcon/IconNames';
import styles from './styles.module.scss';

type StatusType = {
  [key: string]: {
    class: string;
    icon: IconNames;
  };
};

const statuses: StatusType = {
  [OfferStatus.UNPUBLISHED]: {
    class: styles.orange,
    icon: 'close',
  },
  [OfferStatus.PUBLISHED]: {
    class: styles.green,
    icon: 'check-mark',
  },
  [OfferStatus.IN_PROGRESS]: {
    class: styles.orange,
    icon: 'retweet',
  },
  [OfferStatus.READY_TO_PUBLISH]: {
    class: styles.orange,
    icon: 'check-mark',
  },
  [OfferStatus.PAUSED]: {
    class: styles.orange,
    icon: 'pause-circle',
  },
  [OfferStatus.PUBLISHED_PENDING_CHANGES]: {
    class: styles.green,
    icon: 'retweet',
  },
  [OfferStatus.READY_TO_PREVIEW]: {
    class: styles.orange,
    icon: 'eye',
  },
  [OfferStatus.PREVIEWED]: {
    class: styles.green,
    icon: 'eye',
  },
  [OfferStatus.UPDATED]: {
    class: styles.orange,
    icon: 'warning',
  },
};

interface Props {
  status: string;
  size?: number;
  className?: string;
}

const OATStatusIcon = ({ status, className, size = 14 }: Props) => {
  const statusIcon = statuses[status];
  return statusIcon ? <OATIcon className={clsx(statusIcon.class, className)} icon={statusIcon.icon} size={size} /> : null;
};

export default OATStatusIcon;
