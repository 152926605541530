import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '../../constants/global';

type DateToISOStringType = Date | string | null | undefined;

const dateFormatISOString = (date: DateToISOStringType, dateFormat?: string) => {
  if (!date) {
    return '';
  }

  return typeof date === 'string' ? date : format(date, dateFormat ?? DEFAULT_DATE_FORMAT);
};

export default dateFormatISOString;
