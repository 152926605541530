import React, { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import Button from '../../components/Button';
import Input from '../../components/Input';
import OATIcon from '../../components/OATIcon';
import styles from './styles.module.scss';

type ListItemType = {
  id: string;
  name: string;
};

const ListItem = ({ item, onChange, onDelete }: { item: ListItemType; onChange: (id: string, name: string) => void; onDelete: (id: string) => void }) => {
  const [value, setValue] = useState(item.name);

  const handleOnChange = (val: string) => {
    setValue(val);
    onChange(item.id, val);
  };

  const handleOnDelete = () => {
    onDelete(item.id);
  };

  return (
    <li>
      <Input id={`ser-man-input-${item.id}`} value={value} onChange={e => handleOnChange(e.currentTarget.value)} error={!value} />
      <button type="button" id={`ser-man-del-btn-${item.id}`}>
        <OATIcon icon="minus" size={12} onClick={handleOnDelete} />
      </button>
    </li>
  );
};

type Props = {
  seriesList: string[];
  onSave: (seriesList: string[]) => void;
};

const processList = (list: string[]): ListItemType[] => {
  return list.map(item => ({ id: uuid4(), name: item }));
};

const SeriesManagerLayout = ({ seriesList, onSave }: Props) => {
  const [list, setList] = useState(processList(seriesList));

  const validate = () => {
    return list.every(item => !!item.name);
  };

  const handleOnChange = (id: string, name: string) => {
    setList(list.map(item => (item.id === id ? { id, name } : item)));
  };

  const handleAdd = () => {
    setList([...list, { id: uuid4(), name: '' }]);
  };

  const handleDelete = (id: string) => {
    setList(list.filter(item => item.id !== id));
  };

  const handleSave = () => {
    // validate
    if (validate()) {
      onSave(list.map(item => item.name));
    }
  };

  return (
    <div>
      <h2 className={styles.title}>Manage Series</h2>
      <div>
        <div className={styles.header}>Series Name</div>
        <ul className={styles.list}>
          {list.map(item => (
            <ListItem key={item.id} item={item} onChange={handleOnChange} onDelete={handleDelete} />
          ))}
        </ul>

        <button id="ser-man-add-btn" className={styles.addBtn} type="button" onClick={handleAdd}>
          <OATIcon icon="add" size={10} />
          <span>Add Series</span>
        </button>
      </div>
      <div>
        <Button variant="primary" id="ser-man-save-btn" onClick={handleSave} disabled={!validate()}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default SeriesManagerLayout;
