enum ErrorMessages {
  INCLUDED_LDA_REQUIRED = 'Included LDA is required.',
  INCLUDED_PORT_REQUIRED = 'Included port is required.',
  INCLUDED_SERIES_REQUIRED = 'Included series is required.',
  INCLUDED_STATE_REQUIRED = 'Included state is required.',
  OFFERING_DUPLICATE_NAME = 'Duplicate name, please use another.',
  OFFERING_NO_NAME = 'Offering name required, please enter one.',
  OFFERING_INCENTIVE_PERIOD_OVERLAP = 'Incentive period overlap. Please select a different date range.',
  OFFERING_END_DATE_INVALID = 'End Date must occur after the Start Date.',
  OFFERING_START_DATE_INVALID = "Start Date must occur after Today's Date.",
  OFFERING_INCLUDED_SERIES_REQUIRED = 'Included series is required when saving enhanced rates.',
  OFFERING_INCLUDED_STATES_REQUIRED = 'Included states is required when saving enhanced rates.',
  OFFERING_ENHANCED_INCENTIVE_PERIOD_OVERLAP = 'Enhanced Standard Rate Dates are out of range of Incentive Dates.',
  OFFER_TABS_REQUIRED_FIELDS = '* Required fields are not filled in.',
  OFFERING_SETTINGS_OVERLAP_APR = 'Dates, series, states and rates cannot overlap.',
  OFFERING_SETTINGS_OVERLAP_LEASE = 'States and rates cannot overlap.',
  DATE_ORDER_INVALID = 'End Date cannot be before Start Date.',
}

export default ErrorMessages;
