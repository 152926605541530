import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
}

const OATHeaderTitle = ({ children }: Props) => {
  return <h1 className={styles.oatHeaderTitle}>{children}</h1>;
};

export default OATHeaderTitle;
