import axios from 'axios';
import { EFCVinListResponse } from './models/EFCVinListResponse';
import getEfcBrand from './utils/getEfcBrand';

const getVinList = (token: string, domain: string, brand: string, region: string, model: string, year: string, optionParam: string) => {
  const brandParam = getEfcBrand(brand);
  return axios.get<EFCVinListResponse>(
    `${domain}/efc/vinList?brand=${brandParam}&region=${region}&model=${model}&year=${year}&option=${optionParam}&pagestart=0&pagesize=50&vehcategory=ALL`,
    {
      headers: { Authorization: token },
    },
  );
};

export default getVinList;
