import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from '@hedgedoc/html-to-react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { IMultiItem } from '../../models/IMultiItem';
import BlockInputLabel from '../BlockInputLabel';
import Button from '../Button';
import Input from '../Input';
import OATIcon from '../OATIcon';
import styles from './styles.module.scss';

interface Props {
  excludedList: IMultiItem[];
  includedList: IMultiItem[];
  onMove: (excludedList: IMultiItem[], includedList: IMultiItem[], selectedList: IMultiItem[], toExcluded: boolean) => void;
  onSelect: (excludedList: IMultiItem[], includedList: IMultiItem[], item?: IMultiItem) => void;
  error?: boolean;
  disabled?: boolean;
  isNational?: boolean;
  leftLabel?: string;
  leftSearchPlaceholder?: string;
  rightLabel?: string;
  rightSearchPlaceholder?: string;
  allowSearch?: boolean;
  alphaNumeric?: boolean;
  searchOnExcluded?: (excluded: IMultiItem[], query: string) => void;
  searchOnIncluded?: (included: IMultiItem[], query: string) => void;
}

const MultiSelectPanes = ({
  excludedList,
  includedList,
  onSelect,
  onMove,
  error,
  disabled,
  alphaNumeric = false,
  isNational = false,
  allowSearch = false,
  leftLabel = '',
  leftSearchPlaceholder = '',
  rightLabel = '',
  rightSearchPlaceholder = '',
  searchOnExcluded,
  searchOnIncluded,
}: Props) => {
  const [excludedSearchInput, setExcludedSearchInput] = useState('');
  const [includedSearchInput, setIncludedSearchInput] = useState('');
  const handleOnSelect = (item: IMultiItem, excluded: boolean) => {
    const excludedListCopy = [...excludedList];
    const includedListCopy = [...includedList];

    if (excluded) {
      const found = excludedListCopy.find(eItem => eItem.id === item.id);
      if (found) {
        found.selected = !found.selected;
      }
    } else {
      const found = includedListCopy.find(eItem => eItem.id === item.id);
      if (found) {
        found.selected = !found.selected;
      }
    }

    onSelect(excludedListCopy, includedListCopy, item);
  };

  const moveAll = (toExcluded: boolean) => {
    const listCopy = toExcluded ? [...includedList] : [...excludedList];

    if (!toExcluded) {
      const newIncludedArray = [...includedList];
      listCopy.forEach(item => {
        newIncludedArray.push(item);
      });
      newIncludedArray.sort((a, b) => (a.index || 0) - (b.index || 0));
      onMove([], newIncludedArray, newIncludedArray, false);
      return;
    }

    if (toExcluded) {
      const newExcludedArray = [...excludedList];
      listCopy.forEach(item => {
        newExcludedArray.push(item);
      });
      newExcludedArray.sort((a, b) => (a.index || 0) - (b.index || 0));
      onMove(newExcludedArray, [], newExcludedArray, true);
    }
  };

  const moveSelected = (toExcluded: boolean) => {
    const listCopy = toExcluded ? [...includedList] : [...excludedList];
    const targetList = toExcluded ? [...excludedList] : [...includedList];
    const unSelectedList = [] as any[];

    listCopy.forEach(item => {
      if (item.selected) {
        targetList.push({ ...item, selected: false });
        targetList.sort((a, b) => (a.index || 0) - (b.index || 0));
      } else {
        unSelectedList.push({ ...item, selected: false });
        unSelectedList.sort((a, b) => (a.index || 0) - (b.index || 0));
      }
    });

    const selectedList = listCopy.filter(listItem => listItem.selected);

    if (toExcluded) {
      onMove([...targetList], [...unSelectedList], selectedList, true);
      return;
    }

    if (!toExcluded) {
      onMove([...unSelectedList], [...targetList], selectedList, false);
    }
  };

  return (
    <div className={clsx(styles.parent, error && styles.error)}>
      <div>
        {allowSearch && searchOnExcluded && (
          <BlockInputLabel labelComponent={<span className={styles.paneLabel}>{leftLabel}</span>}>
            <Input
              id="search-from-left-list"
              alphaNumeric={alphaNumeric}
              placeholder={leftSearchPlaceholder}
              value={excludedSearchInput}
              onChange={e => {
                setExcludedSearchInput(e.target.value);
                searchOnExcluded(excludedList, e.target.value);
              }}
              className={styles.searchInput}
            />
            <OATIcon icon="search" className={styles.searchIcon} />
          </BlockInputLabel>
        )}
        <div className={clsx(styles.excludedParent, isNational && styles.nationalPane)}>
          {!isNational && (
            <div className={styles.listHeader}>
              <span>{leftLabel || 'Excluded'}</span>
            </div>
          )}
          <ul className={styles.mspList} data-testid="excluded-list">
            {excludedList.map(listItem => {
              return (
                <button
                  type="button"
                  key={`ex-${listItem.id}`}
                  id={`list-item-model-ex-${listItem.id}`}
                  onClick={() => handleOnSelect(listItem, true)}
                  className={clsx(styles.listItem, listItem.selected && styles.active)}
                  disabled={listItem.disabled}
                >
                  {ReactHtmlParser(listItem.data)}
                </button>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={styles.buttonParent}>
        <div className={clsx(styles.middleItems, isNational && styles.nationalMiddleItems)}>
          <Button variant="primary" aria-label="move-selected-to-included" className={styles.copyButton} onClick={() => moveSelected(false)} disabled={disabled}>
            <FontAwesomeIcon icon={faAngleRight} className={styles.icon} />
          </Button>
          {!isNational && (
            <Button variant="primary" aria-label="move-all-to-included" className={styles.copyButton} onClick={() => moveAll(false)} disabled={disabled}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className={styles.icon} />
            </Button>
          )}
          <Button variant="primary" aria-label="move-selected-to-excluded" className={styles.copyButton} onClick={() => moveSelected(true)} disabled={disabled}>
            <FontAwesomeIcon icon={faAngleLeft} className={styles.icon} />
          </Button>
          {!isNational && (
            <Button variant="primary" aria-label="move-all-to-excluded" className={styles.copyButton} onClick={() => moveAll(true)} disabled={disabled}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className={styles.icon} />
            </Button>
          )}
        </div>
      </div>
      <div>
        {allowSearch && searchOnIncluded && (
          <BlockInputLabel labelComponent={<span className={styles.paneLabel}>{rightLabel}</span>}>
            <Input
              id="search-from-right-list"
              alphaNumeric={alphaNumeric}
              placeholder={rightSearchPlaceholder}
              value={includedSearchInput}
              onChange={e => {
                setIncludedSearchInput(e.target.value);
                searchOnIncluded(includedList, e.target.value);
              }}
              className={styles.searchInput}
            />
            <OATIcon icon="search" className={styles.searchIcon} />
          </BlockInputLabel>
        )}
        <div className={clsx(styles.includedParent, isNational && styles.nationalPane)}>
          {!isNational && (
            <div className={styles.listHeader}>
              <span>{rightLabel || 'Included'}</span>
            </div>
          )}
          <ul className={styles.mspList} data-testid="included-list">
            {includedList.map(listItem => {
              return (
                <button
                  type="button"
                  key={`in-${listItem.id}`}
                  id={`list-item-model-in-${listItem.id}`}
                  onClick={() => handleOnSelect(listItem, false)}
                  className={clsx(styles.listItem, listItem.selected && styles.active, listItem.error && styles.error)}
                  disabled={listItem.disabled}
                >
                  {ReactHtmlParser(listItem.data)}
                </button>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectPanes;
