import React from 'react';
import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';

interface Props {
  disabled: boolean;
  isNextDisabled?: boolean;
  onSubmit: (back: boolean, next: boolean) => void;
}

const FinancialTabFooter = ({ disabled, onSubmit, isNextDisabled }: Props) => {
  return (
    <ButtonGroup>
      <Button id="fin-save-btn" variant="primary" onClick={() => onSubmit(true, false)} disabled={disabled}>
        Back
      </Button>
      <Button id="fin-save-btn" variant="primary" onClick={() => onSubmit(false, false)} disabled={disabled}>
        Save
      </Button>
      <Button id="fin-save-btn" variant="primary" onClick={() => onSubmit(false, true)} disabled={disabled || isNextDisabled}>
        Next
      </Button>
    </ButtonGroup>
  );
};

export default FinancialTabFooter;
