import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const OfferTabHeaderDetailRow = ({ children }: Props) => {
  return (
    <div className={styles.row}>
      {children}
    </div>
  );
};

export default OfferTabHeaderDetailRow;
