import React from 'react';
import InlineInputLabel from '../../../components/InlineInputLabel';
import styles from './styles.module.scss';

interface Props {
  isFiltered: boolean;
  setFiltered: () => void;
}

const Gstatus = ({ isFiltered, setFiltered }: Props) => {
  const CheckboxInput = () => {
    return <input id="g-status-filter-cbox" type="checkbox" checked={isFiltered} onChange={setFiltered} className={styles.gCheckbox} tabIndex={-1} />;
  };

  return (
    <div className={styles.gContainer}>
      <InlineInputLabel labelComponent={<CheckboxInput />}>
        <span className={styles.gText}>G STATUS ONLY</span>
      </InlineInputLabel>
    </div>
  );
};

export default Gstatus;
