import React, { ReactNode, useState } from 'react';
import DefaultTable from '../../../components/DefaultTable';
import { OfferStatus } from '../../../constants/global';
import { IOffer } from '../../../models/IOffer';
import { IOfferStatusError } from '../../../models/IOfferStatusError';
import sortFieldsHelper from '../../../utils/sortFieldsHelper';
import { UniqueSeriesType } from '../../../webservices/efc/utils/getSeriesV1UniqueSeries';
import OfferingLayoutTableRow from './OfferingLayoutTableRow';
import styles from './styles.module.scss';

interface Props {
  canPreview: boolean;
  offers: IOffer[];
  seriesMap: UniqueSeriesType[];
  uniqueSeriesMap?: UniqueSeriesType[];
  offerMarketFlagMap: Map<string, IOfferStatusError>;
  renderOfferLink: (offer: IOffer) => ReactNode;
  onChangeOfferStatus?: (offer: IOffer, status: OfferStatus) => void;
  onRemoveOffer?: (offer: IOffer) => void;
  onCopyOffer?: (offer: IOffer) => void;
}

const OfferingLayoutTable = ({ canPreview, offers, seriesMap, uniqueSeriesMap, offerMarketFlagMap, renderOfferLink, onChangeOfferStatus, onRemoveOffer, onCopyOffer }: Props) => {
  const [sortFieldActive, setSortFieldActive] = useState('startDate');
  const [sortAscActive, setSortAscActive] = useState(false);

  const onSortActive = (field: string, asc: boolean) => {
    setSortFieldActive(field);
    setSortAscActive(asc);
  };
  const activeOffers = offers.slice().sort(sortFieldsHelper(sortFieldActive, sortAscActive));

  const renderRows = () => {
    return (
      <>
        {activeOffers.map(item => (
          <OfferingLayoutTableRow
            canPreview={canPreview}
            key={item.id}
            offer={item}
            offerMarketFlagMap={offerMarketFlagMap}
            onChangeOfferStatus={onChangeOfferStatus}
            onRemoveOffer={onRemoveOffer}
            renderOfferLink={renderOfferLink}
            seriesMap={seriesMap}
            uniqueSeriesMap={uniqueSeriesMap}
            onCopyOffer={onCopyOffer}
          />
        ))}
      </>
    );
  };

  return (
    <div className={styles.offeringLayoutTable}>
      <DefaultTable
        columns={[
          { header: 'Offer Type', sortable: true, field: 'offerType' },
          { header: 'Vehicle', sortable: true, field: 'series' },
          { header: 'Offer Detail' },
          { header: 'Notes' },
          { header: 'Action' },
          { header: 'Offer Status' },
        ]}
        defaultSortAscending={false}
        onSort={onSortActive}
        renderRows={renderRows()}
      />
    </div>
  );
};

export default OfferingLayoutTable;
