import React from 'react';
import clsx from 'clsx';
import ToggleButton from '../ToggleButton/ToggleButton';

import styles from './styles.module.scss';

type Props = {
  containerClass?: string;
  disabled?: boolean;
  id?: string;
  selected: boolean;
  toggleSelected: () => void;
};

const SlideToggle = ({ containerClass, selected, ...rest }: Props) => {
  return (
    <ToggleButton
      buttonClass={clsx(styles.toggleBtn, selected && styles.toggleBtnSelected)}
      containerClass={clsx(styles.slideToggle, selected && styles.selected, containerClass)}
      selected={selected}
      {...rest}
    />
  );
};

export default SlideToggle;
