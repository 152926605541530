import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const OfferTabBodyFooter = ({ children, className }: Props) => {
  return <div className={clsx(styles.footer, className)}>{children}</div>;
};

export default OfferTabBodyFooter;
