import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  width?: number;
  contentWidth?: number;
  vertical?: boolean;
  label?: string;
  labelComponent?: ReactNode;
  className?: string;
  contentClass?: string;
  isRequired?: boolean;
  inputWrapperClass?: string;
  contentGrow?: boolean; // default is true. flex setting is 1 0 100%. false will remove this.
}

const InlineInputLabel = ({
  label,
  labelComponent,
  width,
  contentWidth,
  vertical,
  children,
  className,
  contentClass,
  isRequired,
  inputWrapperClass,
  contentGrow = true,
}: Props) => {
  const getStyles = () => {
    return width ? { width: `${width}px` } : undefined;
  };

  const getContentStyles = () => {
    return contentWidth ? { width: `${contentWidth}px` } : undefined;
  };

  return (
    <div className={clsx(styles.container, vertical ? styles.vertical : styles.horizontal, inputWrapperClass)}>
      <span style={getStyles()} className={clsx(styles.label, className, !width && styles.defaultLabelAttr)}>
        {labelComponent || label}
        {isRequired && <span className={styles.requiredField}>*</span>}
      </span>
      <div className={clsx(styles.content, contentClass, contentGrow && styles.contentGrow)} style={getContentStyles()}>
        {children}
      </div>
    </div>
  );
};

export default InlineInputLabel;
