import { OfferMarketing } from './Models';

const formatMarketingPayload = (data: OfferMarketing, marketingCopy: OfferMarketing[]) => {
  const marketingDetails = marketingCopy.filter(item => item.language !== data.language);
  marketingDetails.push(data);

  return marketingDetails.map(item => {
    return {
      description: item.description,
      disclaimers: item.disclaimers.map(discItem => ({
        sortOrder: discItem.sortOrder,
        text: discItem.text || '',
        tcuvDisclaimerFileName: discItem.tcuvDisclaimerFileName || '',
        tcuvDisclaimerFileId: discItem.tcuvDisclaimerFileId || '',
      })),
      id: item.id || '',
      rev: item.rev || '',
      imageDisclaimer: item.imageDisclaimer,
      language: item.language,
      miscType: item.miscType,
      offerCardAltText: item.offerCardAltText,
      offerCardTitle: item.offerCardTitle,
      offerDetailAltText: item.offerDetailAltText,
      offerDetailTitle: item.offerDetailTitle,
    };
  });
};

export default formatMarketingPayload;
