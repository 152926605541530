const TOYOTA_DATA = {
  ports: {
    portsAvailable: ['BC', 'CM', 'GT', 'JX', 'LA', 'LC', 'LF', 'NY', 'PR', 'PT', 'SA', 'SF', 'SJ', 'SM', 'TP', 'VC', 'WS'],
  },
};

const LEXUS_DATA = {
  ports: {
    portsAvailable: ['CM', 'GT', 'JX', 'LA', 'NY', 'PT', 'SJ'],
    laPortLWAstates: ['AZ', 'CA', 'NM', 'NV'],
    ptPortLWAstates: ['AK', 'CO', 'ID', 'OR', 'UT', 'WA', 'WY', 'MT'],
    laPortLSAstates: ['AR', 'LA', 'MS', 'OK', 'TX'],
    jxPortLSAstates: ['AL', 'FL', 'GA', 'NC', 'SC', 'TN'],
    centralPortStates: ['IA', 'IL', 'IN', 'KS', 'KY', 'MI', 'MN', 'MO', 'NE', 'OH', 'WI', 'ND', 'SD'],
    easternPortStates: ['CT', 'DE', 'MA', 'MD', 'ME', 'NH', 'NJ', 'NY', 'PA', 'RI', 'VA', 'WV', 'VT'],
  },
};

const brands = {
  Toyota: TOYOTA_DATA,
  Lexus: LEXUS_DATA,
};

export default brands;
