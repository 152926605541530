/**
 * Searches by specific dimension
 * @param extColors
 * @param dimensions Lexus dimensions by default
 * @returns
 */
const findCarJellyByDimensions = (extColors: any[]) => {
  const img3dAttr = '3dimage';
  const minW = 650; // min range of image width;
  const maxW = 750; // max range of image wi]dth;
  let carjelly = '';

  // find by specified dimensions
  extColors.forEach(color => {
    if (color[img3dAttr]) {
      Object.entries(color[img3dAttr]).forEach(([dim, item]) => {
        // split dimension to obtain width (ex 700_658_PNG, get 700)
        const dsplit = dim.split('_');
        const width = dsplit[0];

        // then compare if width is within range and jelly is not set
        if (!carjelly && width && Number(width) >= minW && Number(width) <= maxW) {
          carjelly = (Object.values(item as any)[0] as any)[0] as string;
        }
      });
    }
  });

  return carjelly || undefined;
};

/**
 * Searches all colors for car jelly
 * @param extColors
 * @returns
 */
const findCarJellyAttribute = (extColors: any[]) => {
  return extColors.find(item => !!item.carjellyimage)?.carjellyimage || undefined;
};

/**
 * Extracts car jelly from exterior colors array
 * @param extColors
 * @returns
 */
const getCarJellyFromExtColors = (extColors: any[]) => {
  if (extColors.length > 0) {
    return findCarJellyAttribute(extColors) || findCarJellyByDimensions(extColors);
  }

  return undefined;
};

export default getCarJellyFromExtColors;
