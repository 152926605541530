import React, { useEffect, useState } from 'react';
import DisclaimerModal from '../../../../../components/DisclaimerModal';
import useInput from '../../../../../hooks/useInput';
import DnDFileText from '../DnDFileText';
import DnDLabel from '../DnDLabel';
import { DnDType, OfferMarketingDisclaimers } from '../Models';
import styles from './styles.module.scss';

interface Props {
  uid: string;
  id: string;
  addDisclaimer: (index: number) => void;
  removeDisclaimer: (uid: string) => void;
  handleDisclaimersSort: (newSortOrder: number, prevSortOrder: number) => void;
  sortOrder: number;
  setDisclaimersList: (uid: string, file: string, text: string, id: string) => void;
  disclaimersList: OfferMarketingDisclaimers[];
  disclaimers: { brand: string; id: string; name: string; rev: string; text: string; selected?: boolean }[];
  snippets: { name: string; char: string }[];
  tokens: { name: string; char: string }[];
  showAdd: boolean;
  isLexus?: boolean;
  file?: string;
  text?: string;
  innerRef?: (element: HTMLElement | null) => any;
}

const labelWidth = 200;
const textAreaLabelWidth = 100;

const Disclaimer = ({
  uid,
  id,
  addDisclaimer,
  removeDisclaimer,
  setDisclaimersList,
  disclaimersList,
  disclaimers,
  sortOrder,
  handleDisclaimersSort,
  tokens,
  showAdd,
  snippets,
  innerRef,
  file,
  text,
  isLexus,
  ...rest
}: Props) => {
  const [disclaimerTitle, disclaimerTitleBindFn, disclaimerTitleError, setDisclaimerTitle] = useInput(sortOrder, { isWhole: true, required: true });
  const [disclaimerFile, disclaimerFileBindFn, disclaimerFileError, setDisclaimerFile] = useInput(file || '');
  const [disclaimerText, , disclaimerTextError, setDisclaimerText] = useInput(text || '', { required: !isLexus });
  const [disclaimerId, setDisclaimerId] = useState(id || '');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setDisclaimerTitle(sortOrder.toString());
  }, [disclaimersList]);

  useEffect(() => {
    setDisclaimersList(uid, disclaimerFile, disclaimerText, disclaimerId);
  }, [disclaimerFile, disclaimerText]);

  const handleInsertDisclaimer = (tier: { name: string; text: string; id: string }) => {
    if (tier) {
      setDisclaimerFile(tier.name);
      setDisclaimerText(tier.text);
      setDisclaimerId(tier.id);
    }
  };

  const handleUnlink = () => {
    setDisclaimerFile('');
    setDisclaimerId('');
  };

  const disableTextArea = !!disclaimerFile && !!disclaimerText;

  return (
    <div className={styles.mainContainer} ref={innerRef} {...rest}>
      {showModal && (
        <DisclaimerModal
          onClose={() => setShowModal(false)}
          handleInsertDisclaimer={handleInsertDisclaimer}
          disclaimers={disclaimers.map((disclaimer, i) => ({ ...disclaimer, selected: i === 0 }))}
          label={DnDType.DISCLAIMERS}
        />
      )}
      <DnDLabel
        uid={uid}
        dndName="Disclaimer"
        labelWidth={labelWidth}
        showAdd={showAdd}
        sortOrder={sortOrder}
        sortInput={disclaimerTitle}
        sortInputError={disclaimerTitleError}
        sortInputFn={disclaimerTitleBindFn}
        onAdd={addDisclaimer}
        onSort={handleDisclaimersSort}
      >
        <DnDFileText
          uid={uid}
          bodyContainerClass={styles.bodyContainer}
          textAreaLabelWidth={textAreaLabelWidth}
          showRemove={disclaimersList.length > 1}
          disableTextArea={disableTextArea}
          file={disclaimerFile}
          fileError={disclaimerFileError}
          fileFn={disclaimerFileBindFn}
          text={disclaimerText}
          textError={disclaimerTextError}
          tokens={tokens}
          secondaryTokens={snippets}
          disableBrowse={!disclaimers.length}
          setText={setDisclaimerText}
          onUnlink={handleUnlink}
          onBrowse={() => setShowModal(true)}
          onRemove={() => removeDisclaimer(uid)}
        />
      </DnDLabel>
    </div>
  );
};

export default Disclaimer;
