import axios from 'axios';
import DD365InventoryResponse from './models/DD365InventoryResponse';

/**
 *
 * @param domain
 * @param token
 * @param region
 * @param year
 * @param model
 * @param vehcategory ex A,F,G
 * @param pageNumber
 * @returns
 */
const getDD365Inventory = (domain: string, token: string, region: string, year: string, model: string, vehcategory?: string, pageNumber = 1) => {
  const url = `/dd365/getInventory?region=${region}&year=${year}&model=${model}&pageNumber=${pageNumber}&vehcategory=${vehcategory}`;
  return axios.get<DD365InventoryResponse>(`${domain}${url}`, {
    headers: { Authorization: token },
  });
};

export default getDD365Inventory;
