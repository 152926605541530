export interface NumberConfig {
  allowNegative?: boolean;
  decimalScale?: number;
  thousandSeparator?: boolean;
  max?: number;
}

export const wholeNumberConfig: NumberConfig = {
  allowNegative: false,
  decimalScale: 0,
  thousandSeparator: true,
};

export const negativeNumberConfig: NumberConfig = {
  allowNegative: true,
  decimalScale: 0,
  thousandSeparator: true,
};

export const unitsConfig: NumberConfig = {
  allowNegative: false,
  decimalScale: 0,
};

export const rcfConfig: NumberConfig = {
  decimalScale: 5,
};

export const dollarConfig: NumberConfig = {
  decimalScale: 2,
  thousandSeparator: true,
};

export const rateConfig: NumberConfig = {
  decimalScale: 2,
  allowNegative: true,
};

export const adjFactorConfig: NumberConfig = {
  decimalScale: 3,
  allowNegative: false,
};

export const getConfig = (wholeNumber = false, negativeNumber = false, units = false, rcf = false, dollar = false, rate = false, adjustmentFactor = false) => {
  if (wholeNumber) {
    return wholeNumberConfig;
  }

  if (negativeNumber) {
    return negativeNumberConfig;
  }

  if (units) {
    return unitsConfig;
  }

  if (rcf) {
    return rcfConfig;
  }

  if (dollar) {
    return dollarConfig;
  }

  if (rate) {
    return rateConfig;
  }

  if (adjustmentFactor) {
    return adjFactorConfig;
  }

  return {};
};
