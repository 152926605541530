import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  fixed?: boolean;
  children?: ReactNode;
  withPadding?: boolean;
  className?: string;
}
const OATWrapper = ({ fixed, children, withPadding, className }: Props) => {
  return <div className={clsx(styles.oatWrapper, withPadding && styles.padding, fixed && styles.fixed, className)}>{children}</div>;
};

export default OATWrapper;
