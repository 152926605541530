import clsx from 'clsx';
import React, { useState, FocusEvent, ChangeEvent, useEffect } from 'react';
import { assignNumberValue } from '../../utils/assignValue';
import { formatRcf } from '../../utils/formatNumber';
import isRcfValid from './utils';

import styles from './styles.module.scss';

type Props = {
  onChange: (value: string) => void;
  id: string;
  value: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
};

const RcfInput = ({ onChange, id, value, inputClassName, inputWrapperClassName }: Props) => {
  const [val, setVal] = useState(formatRcf(value));
  const [isValid, setIsValid] = useState(isRcfValid(value));

  // useEffect to force rerender of component when different value is passed
  useEffect(() => {
    // condition to prevent unneccesseary re-rendering
    if (assignNumberValue(val) !== assignNumberValue(value)) {
      setVal(formatRcf(value));
      setIsValid(isRcfValid(value));
    }
  }, [val, value]);

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const formatedVal = formatRcf(e.currentTarget.value);
    setIsValid(isRcfValid(formatedVal));
    setVal(formatedVal);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.currentTarget.value);
    setIsValid(isRcfValid(e.currentTarget.value));

    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className={inputWrapperClassName}>
      <input
        id={id}
        onBlur={handleOnBlur}
        className={clsx(styles.rcfInput, inputClassName, isValid ? styles.valid : styles.invalid)}
        placeholder="--"
        type="number"
        value={val}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default RcfInput;
