import React from 'react';
import lexLogo from '../../../../assets/lexus_header_logo.svg';
import toyLogo from '../../../../assets/toy_header_logo.svg';
import styles from './styles.module.scss';

type Props = {
  isLexus: boolean;
};

const SideNavLogo = ({ isLexus }: Props) => {
  const logo = isLexus ? lexLogo : toyLogo;

  return (
    <div className={styles.sideNavLogo}>
      <img className={styles.image} src={logo} alt="Offers Admin Tool" />
    </div>
  );
};

export default SideNavLogo;
