import axios from 'axios';
import SeriesV1Response from './models/SeriesV1Response';
import getDefaultRegion from './utils/getDefaultRegion';
import getEfcBrand from './utils/getEfcBrand';

const getSeriesV1 = (domain: string, key: string, brand = '', lang = 'EN', region = '', year = '') => {
  const brandParam = getEfcBrand(brand);
  const regionParam = getDefaultRegion(brandParam, region);

  const params = year ? `?year=${year}` : '';
  return axios.get<SeriesV1Response>(`${domain}/vehiclecontent/v1/${brandParam}/${regionParam}/${lang}/series${params}`, {
    headers: {
      'x-api-key': key,
    },
  });
};

export default getSeriesV1;
