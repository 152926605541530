import ReactHTMLParser from '@hedgedoc/html-to-react';
import React, { ReactNode } from 'react';
import blankCar from '../../../../../assets/blank_car.png';
import OATStatusIcon from '../../../../components/OATStatusIcon';
import { OfferStatus } from '../../../../constants/global';
import getLabelFromSeriesMap from '../../../../utils/getLabelFromSeriesMap';
import { EFC_IMG_DOMAIN } from '../../../../webservices/efc/constants/efcConstants';
import { UniqueSeriesType } from '../../../../webservices/efc/utils/getSeriesV1UniqueSeries';
import styles from './styles.module.scss';

type Props = {
  offerType: string;
  endDate: string;
  status: string;
  seriesMap: UniqueSeriesType[];
  offerImage?: string;
  offerSeries: string;
  offerSeriesYear?: string;
  renderHeaderContent: ReactNode;
  renderNav: ReactNode;
};

const OfferTabsHeader = ({ offerType, endDate, status, seriesMap, offerImage, offerSeries, offerSeriesYear, renderHeaderContent, renderNav }: Props) => {
  return (
    <div className={styles.offerTabsHeader}>
      <div className={styles.innerContainer}>
        <div className={styles.topContent}>
          <span>{offerType}</span>
          <div>
            {`exp ${endDate.substring(5).replace('-', '/')}`}
            <div className={styles.statusIcon}>
              <OATStatusIcon status={status || OfferStatus.IN_PROGRESS} size={32} />
            </div>
          </div>
        </div>
        <div className={styles.headerContent}>
          <div className={styles.carImage}>
            <img src={offerImage ? `${EFC_IMG_DOMAIN}${offerImage}` : blankCar} alt="car_img" />
          </div>
          <div className={styles.headerSeries}>
            {offerSeriesYear}
            <b className={styles.headerSeriesName}>{ReactHTMLParser(getLabelFromSeriesMap(offerSeries, seriesMap))}</b>
          </div>
          {renderHeaderContent}
        </div>
      </div>
      {renderNav}
    </div>
  );
};

export default OfferTabsHeader;
