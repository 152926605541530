const getPlusOneYears7 = () => {
  const newestYear = new Date().getFullYear() + 1;
  const lowestYear = newestYear - 8;

  const years: string[] = [];
  for (let year = lowestYear; year <= newestYear; year += 1) {
    years.push(year.toString());
  }
  return years;
};

export default getPlusOneYears7;
