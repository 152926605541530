import clsx from 'clsx';
import React from 'react';
import OATIcon from '../OATIcon';
import styles from './styles.module.scss';

interface FieldTitleProps {
  field?: string;
  sortField: string;
  sortAscending: boolean;
  descendingFirst?: boolean;
  className?: string;
  id: string;
  header: string;
  handleOnClick: (field: string, descendingFirst?: boolean) => void;
}

const FieldTitle = ({ field, sortField, sortAscending, descendingFirst, id, header, handleOnClick, className }: FieldTitleProps) => {
  return (
    <div
      id={id}
      onClick={() => {
        handleOnClick(field || '', descendingFirst);
      }}
      className={clsx(styles.fieldTitle, className)}
      aria-hidden="true"
      role="columnheader"
    >
      <span className={styles.headerFieldText}>{header}</span>
      {field === sortField && <OATIcon icon={sortAscending ? 'caret-down' : 'caret-up'} size={10} className={styles.icon} />}
    </div>
  );
};

export default FieldTitle;
