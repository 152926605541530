export const DEFAULT_TOAST_TIMEOUT = 4000;

export type ToastItem = {
  id: string;
  msg: string;
  status: ToastStatus;
  options?: any;
};

export enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
