import axios from 'axios';
import Efc3dImagesResponse from './models/Efc3dImagesResponse';
import getEfcBrand from './utils/getEfcBrand';

const get3dImages = (domain: string, key: string, brand: string, year: string, series: string, region = 'NATIONAL') => {
  const brandParam = getEfcBrand(brand);

  return axios.get<Efc3dImagesResponse>(
    `${domain}/config/services/configuration/3d/getImages?brand=${brandParam}&config=base&region=${region}&year=${year}&series=${series}&appkey=${key}`,
  );
};

export default get3dImages;
