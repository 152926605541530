import clsx from 'clsx';
import React, { ReactNode, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import styles from './styles.module.scss';

type PlacementType = 'top' | 'left' | 'right' | 'bottom';

interface Props {
  id?: string;
  content?: ReactNode;
  buttonContent?: ReactNode;
  contentClass?: string;
  popoverClass?: string;
  placement?: PlacementType;
}

const OATPopover = ({ id, content, buttonContent, contentClass, popoverClass, placement = 'bottom' }: Props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  return (
    <div ref={ref} className={styles.container}>
      <Overlay show={show} target={ref.current} onHide={() => setShow(false)} placement={placement} rootClose>
        <Popover id={id} className={clsx(styles.popover, popoverClass)}>
          <Popover.Body className={clsx(styles.body, contentClass)}>{content}</Popover.Body>
        </Popover>
      </Overlay>
      <button id={id} type="button" className={styles.btn} onClick={() => setShow(!show)}>
        {buttonContent}
      </button>
    </div>
  );
};

export default OATPopover;
