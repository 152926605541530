import clsx from 'clsx';
import React, { ReactNode } from 'react';
import Table from '../../components/Table';
import HeaderRow from './HeaderRow';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  headerColumns: { header: string; field: string; sortable?: boolean; centered?: boolean }[];
  sortField: string;
  sortAscending: boolean;
  handleSort: (headerField: string) => void;
}

const CreateLeaseTable = ({ children, className, headerColumns, sortField, sortAscending, handleSort }: Props) => {
  return (
    <Table className={clsx(styles.tableBorder, className)}>
      <HeaderRow headerList={headerColumns} sortField={sortField} sortAscending={sortAscending} handleOnClick={handleSort} />
      <tbody>{children}</tbody>
    </Table>
  );
};

export default CreateLeaseTable;
