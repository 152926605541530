import React, { useState } from 'react';
import Button from '../../../components/Button';
import Dropdown, { DropdownItem } from '../../../components/Dropdown';
import { OfferTypes, OfferTypesLong } from '../../../constants/global';
import getLabelFromSeriesMap from '../../../utils/getLabelFromSeriesMap';
import { UniqueSeriesType } from '../../../webservices/efc/utils/getSeriesV1UniqueSeries';
import styles from './styles.module.scss';

type Props = {
  offerTypeFilters: string[];
  seriesFilters: string[];
  statusFilters?: string[];
  seriesMap: UniqueSeriesType[];
  uniqueSeriesMap?: UniqueSeriesType[];
  createOfferCallback?: () => void;
  onOfferTypeFilterChange: (item: DropdownItem) => void;
  onSeriesFilterChange: (item: DropdownItem) => void;
  onStatusFilterChange?: (item: DropdownItem) => void;
  onCreateOffer: (type: string) => void;
  onCopyFromTDA?: () => void;
  createButtonText: string;
  tdaCode?: string;
};

const OfferingLayoutSearch = ({
  offerTypeFilters,
  seriesFilters,
  statusFilters,
  seriesMap,
  uniqueSeriesMap,
  createOfferCallback,
  onOfferTypeFilterChange,
  onSeriesFilterChange,
  onStatusFilterChange,
  onCreateOffer,
  onCopyFromTDA,
  createButtonText,
  tdaCode,
}: Props) => {
  const [offerTypeFilter, setOfferTypeFilter] = useState<DropdownItem | undefined>();
  const [seriesFilter, setSeriesFilter] = useState<DropdownItem | undefined>();
  const [statusFilter, setStatusFilter] = useState<DropdownItem | undefined>();
  const [showOfferMenu, setShowOfferMenu] = useState(false);

  const handleOfferType = (newVal: DropdownItem) => {
    setSeriesFilter(undefined);
    setOfferTypeFilter(newVal);
    onOfferTypeFilterChange(newVal);
  };

  const handleSeriesChange = (newVal: DropdownItem) => {
    setSeriesFilter(newVal);
    onSeriesFilterChange(newVal);
  };

  const handleStatusChange = (newVal: DropdownItem) => {
    setStatusFilter(newVal);
    if (onStatusFilterChange) {
      onStatusFilterChange(newVal);
    }
  };

  const handleCreateOffer = (type: OfferTypes) => {
    onCreateOffer(type.toLowerCase());
  };

  const handleCreateOfferBtnClick = () => {
    if (createOfferCallback) {
      createOfferCallback();
    } else {
      setShowOfferMenu(true);
    }
  };

  const handleCopyFromTDA = () => {
    if (onCopyFromTDA) {
      onCopyFromTDA();
    }
  };

  const statusOptions = statusFilters ? [{ value: '', label: 'All Statuses' }, ...statusFilters.map(item => ({ value: item, label: item }))] : [];
  const seriesOptions = [{ value: '', label: 'All Vehicles' }, ...seriesFilters.map(item => ({ value: item, label: getLabelFromSeriesMap(item, seriesMap, uniqueSeriesMap) }))];
  const offerTypeOptions = [{ value: '', label: 'All Offer Types' }, ...offerTypeFilters.map(item => ({ value: item, label: item }))];

  return (
    <div className={styles.offeringLayoutSearch}>
      <div className={styles.leftSide}>
        <h3>Filter View</h3>
        <Dropdown id="dd-offer-type" placeholder="All Offer Types" minWidth={172} value={offerTypeFilter} darkTheme options={offerTypeOptions} onChange={handleOfferType} />
        <Dropdown id="dd-series" placeholder="All Vehicles" minWidth={172} value={seriesFilter} darkTheme options={seriesOptions} onChange={handleSeriesChange} />
        {statusFilters && (
          <Dropdown id="dd-statuses" placeholder="All Statuses" minWidth={172} value={statusFilter} darkTheme options={statusOptions} onChange={handleStatusChange} />
        )}
        {(tdaCode === 'UST' || tdaCode === 'CON') && (
          <Button id="copy-from-gny" variant="primary" onClick={() => handleCopyFromTDA()}>
            Copy from GNY
          </Button>
        )}
        {tdaCode === 'INW' && (
          <Button id="copy-from-ptl" variant="primary" onClick={() => handleCopyFromTDA()}>
            Copy from PTL
          </Button>
        )}
      </div>
      <div className={styles.rightSide}>
        {showOfferMenu && (
          <div className={styles.btnGroup}>
            <Button id="offering-create-cash" variant="primary" onClick={() => handleCreateOffer(OfferTypes.CUSTOMER_CASH)}>
              {OfferTypesLong.CUSTOMER_CASH}
            </Button>
            <Button id="offering-create-apr" variant="primary" onClick={() => handleCreateOffer(OfferTypes.APR)}>
              {OfferTypesLong.APR}
            </Button>
            <Button id="offering-create-lease" variant="primary" onClick={() => handleCreateOffer(OfferTypes.LEASE)}>
              {OfferTypesLong.LEASE}
            </Button>
            <Button id="offering-create-misc" variant="primary" onClick={() => handleCreateOffer(OfferTypes.MISCELLANEOUS)}>
              {OfferTypesLong.MISCELLANEOUS}
            </Button>
            <Button id="offering-create-other" variant="primary" onClick={() => handleCreateOffer(OfferTypes.OTHER)}>
              {OfferTypesLong.OTHER}
            </Button>
          </div>
        )}
        {!showOfferMenu && (
          <Button id="offering-create-btn" variant="primary" onClick={handleCreateOfferBtnClick}>
            {createButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default OfferingLayoutSearch;
