import React, { ReactNode, useState } from 'react';
import { DropdownItem } from '../../components/Dropdown';
import OATWrapper from '../../components/OATWrapper';
import { OfferStatus } from '../../constants/global';
import { IOffer } from '../../models/IOffer';
import { IOfferStatusError } from '../../models/IOfferStatusError';
import { IOffering } from '../../models/IOffering';
import { UniqueSeriesType } from '../../webservices/efc/utils/getSeriesV1UniqueSeries';
import OfferingLayoutHeader from './OfferingLayoutHeader';
import OfferingLayoutSearch from './OfferingLayoutSearch';
import OfferingLayoutTable from './OfferingLayoutTable';

interface Props<T> {
  canPreview: boolean;
  offering: T;
  offers: IOffer[];
  offerTypeFilters: string[];
  seriesFilters: string[];
  statusFilters?: string[];
  seriesMap: UniqueSeriesType[];
  uniqueSeriesMap?: UniqueSeriesType[];
  offerMarketFlagMap: Map<string, IOfferStatusError>;
  renderOfferLink: (offer: IOffer) => ReactNode;
  onChangeOfferingStatus: (status: OfferStatus) => void;
  onCreateOffer: (type: string) => void;
  onRemoveOffer: (offer: IOffer) => void;
  onChangeOfferStatus: (offer: IOffer, status: OfferStatus) => void;
  statusOptions: OfferStatus[];
  createButtonText: string;
  onCopyOffer?: (offer: IOffer) => void;
}

const OfferingLayout = <T extends IOffering>({
  canPreview,
  offering,
  offers,
  offerTypeFilters,
  seriesFilters,
  statusFilters,
  seriesMap,
  uniqueSeriesMap,
  offerMarketFlagMap,
  renderOfferLink,
  onChangeOfferingStatus,
  onChangeOfferStatus,
  onRemoveOffer,
  onCreateOffer,
  statusOptions,
  createButtonText,
  onCopyOffer,
}: Props<T>) => {
  let updatedSeriesFilters = seriesFilters;
  const [statusFilter, setStatusFilter] = useState('');
  const [seriesFilter, setSeriesFilter] = useState('');
  const [offerTypeFilter, setOfferTypeFilter] = useState('');

  const handleOnStatusFilter = (item: DropdownItem) => {
    setStatusFilter(item.value);
  };

  const handleOnSeriesFilter = (item: DropdownItem) => {
    setSeriesFilter(item.value);
  };

  const handleOfferTypeFilter = (item: DropdownItem) => {
    setSeriesFilter('');
    setOfferTypeFilter(item.value);
  };

  const filteredOffers = offers.filter(
    offer => (!statusFilter || offer.status === statusFilter) && (!seriesFilter || offer.series === seriesFilter) && (!offerTypeFilter || offer.offerType === offerTypeFilter),
  );

  if (offerTypeFilter !== '') {
    updatedSeriesFilters = [...new Set(offers.filter(offer => offer.offerType === offerTypeFilter).map(item => item.series))];
  }

  return (
    <OATWrapper withPadding>
      <OfferingLayoutHeader
        offeringName={offering.name}
        startDate={offering.startDate}
        endDate={offering.endDate}
        status={offering.status}
        onChangeOfferingStatus={onChangeOfferingStatus}
        statusOptions={statusOptions}
      />
      <OfferingLayoutSearch
        offerTypeFilters={offerTypeFilters}
        seriesFilters={updatedSeriesFilters}
        statusFilters={statusFilters}
        seriesMap={seriesMap}
        uniqueSeriesMap={uniqueSeriesMap}
        onOfferTypeFilterChange={handleOfferTypeFilter}
        onSeriesFilterChange={handleOnSeriesFilter}
        onStatusFilterChange={handleOnStatusFilter}
        onCreateOffer={onCreateOffer}
        createButtonText={createButtonText}
      />
      <OfferingLayoutTable
        canPreview={canPreview}
        offers={filteredOffers}
        offerMarketFlagMap={offerMarketFlagMap}
        onRemoveOffer={onRemoveOffer}
        onChangeOfferStatus={onChangeOfferStatus}
        renderOfferLink={renderOfferLink}
        seriesMap={seriesMap}
        uniqueSeriesMap={uniqueSeriesMap}
        onCopyOffer={onCopyOffer}
      />
    </OATWrapper>
  );
};

export default OfferingLayout;
