import axios from 'axios';
import { EFCInventoryResponse, InventoryParam } from './models/Inventory';

const getInventory = (token: string, domain: string, inventoryParam: InventoryParam) => {
  return axios.post<EFCInventoryResponse>(`${domain}/efc/inventory`, inventoryParam, {
    headers: { Authorization: token },
  });
};

export default getInventory;
