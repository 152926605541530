import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ModalBodyProps {
  children?: ReactNode;
  className?: string;
  isWarning?: boolean;
}

const ModalBody = ({ children, className, isWarning }: ModalBodyProps) => {
  return <div className={clsx(styles.modalBody, className, isWarning && styles.warning)}>{children}</div>;
};

export default ModalBody;
