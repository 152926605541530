import React from 'react';
import IconMenu from '../../../components/IconMenu';
import IconMenuItem from '../../../components/IconMenu/IconMenuItem';
import OATStatusIcon from '../../../components/OATStatusIcon';
import Tooltip from '../../../components/Tooltip';
import { OfferStatus } from '../../../constants/global';
import useComponentVisible from '../../../hooks/useComponentVisible';
import styles from './styles.module.scss';

interface Props {
  offeringName: string;
  startDate: string;
  endDate: string;
  status: string;
  onChangeOfferingStatus: (status: OfferStatus) => void;
  statusOptions: OfferStatus[];
}

const OfferingLayoutHeader = ({ offeringName, startDate, endDate, status = OfferStatus.IN_PROGRESS, onChangeOfferingStatus, statusOptions }: Props) => {
  const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible(false);

  const handleChangeOffering = (newStatus: OfferStatus) => {
    onChangeOfferingStatus(newStatus);
    setIsComponentVisible(false);
  };

  return (
    <div className={styles.offeringLayoutHeader}>
      <h2>{`Sales Period: ${offeringName} (${startDate} - ${endDate})`}</h2>
      <Tooltip id={`offer-tooltip-status-${status}`} message={status}>
        <div className={styles.statusBox}>
          Incentive Period Status
          <div>
            <button id="offering-status-btn" type="button" className={styles.statusIcon} onClick={() => setIsComponentVisible(true)}>
              <OATStatusIcon status={status} size={24} />
            </button>
            {isComponentVisible && (
              <IconMenu ref={ref}>
                {statusOptions.map((item, index) => (
                  <IconMenuItem key={item} id={`offering-status-menu-${index}`} renderIcon={<OATStatusIcon status={item} size={14} />} onClick={() => handleChangeOffering(item)}>
                    {item}
                  </IconMenuItem>
                ))}
              </IconMenu>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default OfferingLayoutHeader;
