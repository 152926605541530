import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { TierTableDataType } from '../../models/TierTableDataType';
import styles from './styles.module.scss';
import TierTableCell from './TierTableCell';
import TierTableHeaderCell from './TierTableHeaderCell';
import TierTableIconCell from './TierTableIconCell';
import { buildTable, clearTerm, clearTier, copyAllByTerm, copyAllByTier, copyByTerm, copyByTier, setTableCellValue } from './tierTableUtil';

interface TierTableProps {
  defaultTierLength: number;
  maxTierLength: number;
  defaultMonthsLength: number;
  maxMonthsLength: number;
  tierTableData?: any;
  returnTableData?: any;
  type?: string;
}

const TierTable = ({ defaultTierLength, maxTierLength, defaultMonthsLength, maxMonthsLength, tierTableData, returnTableData, type }: TierTableProps) => {
  const [tiersLength, setTiersLength] = useState(defaultTierLength);
  const [monthsLength, setMonthsLength] = useState(defaultMonthsLength);
  const [tiersList, setTiersList] = useState<TierTableDataType[]>([]);
  const [tiersHeaderList, setTiersHeaderList] = useState([] as {}[]);
  const [tiersFooterList, setTiersFooterList] = useState([] as {}[]);

  useEffect(() => {
    if (tierTableData) {
      const tableData = buildTable(defaultMonthsLength, defaultTierLength, monthsLength, tiersLength, tierTableData);

      setTiersList([...tableData]);
      setTiersHeaderList([...tableData.map((item: any) => item.tiers.map((tier: any) => tier.tier))[0]]);
      setTiersFooterList([...tableData.map((item: any) => item.tiers.map((tier: any) => tier.tier))[0]]);
    }
  }, [monthsLength, tiersLength]);

  const handleUpdateTable = (tiersArray: TierTableDataType[]) => {
    setTiersList(tiersArray);
    returnTableData(tiersArray, type);
  };

  const incrementMonthLength = () => {
    if (monthsLength === maxMonthsLength) {
      return;
    }
    setMonthsLength(monthsLength + 1);
  };

  const incrementTierLength = () => {
    if (tiersLength === maxTierLength) {
      return;
    }
    setTiersLength(tiersLength + 1);
  };

  const incrementLength = (dataType: string) => {
    if (dataType === 'months') {
      incrementMonthLength();
    }

    if (dataType === 'tiers') {
      incrementTierLength();
    }
  };

  const deleteByTier = (tier: string) => {
    handleUpdateTable(clearTier(tiersList, tier));
  };

  const deleteByTerm = (term: number) => {
    handleUpdateTable(clearTerm(tiersList, term));
  };

  const getTierVal = (tier: string | number | {}) => {
    return Number.isNaN(Number(tier)) ? tier.toString() : Number(tier);
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.tableRow}>
          <td className={styles.fixedFront} />
          {tiersHeaderList.map(tier => {
            const tierVal = getTierVal(tier);

            return (
              <TierTableHeaderCell
                key={`tier-${tierVal}-header`}
                tdClassName={styles.tableHeader}
                textClassName={styles.tableHeader}
                text={`Tier ${tierVal} ${type === 'apr' ? 'Rate' : 'RCF'}`}
                copyMethodOne={copyByTier}
                copyMethodTwo={copyAllByTier}
                copyMethodParam={tier}
                tiersList={tiersList}
                setTiersList={handleUpdateTable}
              />
            );
          })}
          {tiersHeaderList.length && tiersLength < maxTierLength ? (
            <TierTableIconCell
              tdClassName={styles.fixed}
              iconMethod={incrementLength}
              iconMethodParam="tiers"
              id="add-tier"
              iconName={faPlusCircle}
              iconClassName={styles.plusIcon}
            />
          ) : (
            <td className={styles.fixedEnd} />
          )}
        </tr>
      </thead>
      <tbody>
        {tiersList.map(item => {
          return (
            <tr key={item.term} className={styles.tableRow}>
              <TierTableHeaderCell
                tdClassName={styles.monthTitle}
                textClassName={styles.fixed}
                text={`${item.term} mo`}
                copyMethodOne={copyAllByTerm}
                copyMethodTwo={copyByTerm}
                copyMethodParam={item.term}
                tiersList={tiersList}
                setTiersList={handleUpdateTable}
              />
              {item.tiers.map((tier, index) => {
                return (
                  <TierTableCell
                    key={tier.tier}
                    tier={tier.tier}
                    index={index}
                    term={item.term}
                    setTableCellValue={setTableCellValue}
                    rate={tier.rate}
                    tiersList={tiersList}
                    setTiersList={handleUpdateTable}
                  />
                );
              })}
              <TierTableIconCell
                tdClassName={styles.fixed}
                iconMethod={deleteByTerm}
                iconMethodParam={item.term}
                id="delete-by-term"
                iconName={faMinusCircle}
                iconClassName={styles.minusIcon}
              />
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr className={styles.tableRow}>
          {type === 'apr' && monthsLength < maxMonthsLength ? (
            <TierTableIconCell
              tdClassName={styles.footerPlusContainer}
              iconMethod={incrementLength}
              iconMethodParam="months"
              id="add-term"
              iconName={faPlusCircle}
              iconClassName={styles.plusIcon}
            />
          ) : (
            <td className={styles.fixedFront} />
          )}
          {tiersFooterList.map(tier => {
            return (
              <TierTableIconCell
                key={`Tier-${getTierVal(tier)}-Footer`}
                tdClassName={styles.tableFooter}
                iconMethod={deleteByTier}
                iconMethodParam={tier}
                id="delete-by-tier"
                iconName={faMinusCircle}
                iconClassName={styles.minusIcon}
              />
            );
          })}
          <td className={styles.fixedEnd} />
        </tr>
      </tfoot>
    </table>
  );
};

export default TierTable;
