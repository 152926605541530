import { TierTableDataType } from '../../models/TierTableDataType';

export const copyAllByTerm = (term: number, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => {
  const tiersListCopy = [...tiersList];
  const valuesArray = [] as { tier: string; rate: string }[];
  const startingPoint = tiersListCopy.findIndex(item => item.term === term);

  tiersListCopy.forEach(row => {
    row.tiers.forEach(col => {
      if (col.rate && row.term === term) {
        valuesArray.push({ tier: col.tier, rate: col.rate });
      } else if (col.rate && row.term > term) {
        col.rate = '';
      }
    });
  });

  valuesArray.forEach(item => {
    for (let i = startingPoint; i < tiersListCopy.length; i += 1) {
      tiersListCopy[i].tiers.forEach(tiersj => {
        if (item.tier === tiersj.tier && !tiersj.rate) {
          tiersj.rate = item.rate;
        }
      });
    }
  });

  return tiersListCopy;
};

export const copyByTier = (tier: any, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => {
  const tiersListCopy = [...tiersList];
  let value = '';

  tiersListCopy.forEach(row => {
    row.tiers.forEach(col => {
      if (row.term === 24 && col.rate && col.tier === tier) {
        value = col.rate;
      }

      if (row.term !== 24 && col.rate && col.tier === tier) {
        col.rate = '';
      }
    });
  });

  if (value) {
    tiersListCopy.forEach(row => {
      row.tiers.forEach(col => {
        if (!col.rate && col.tier === tier) {
          col.rate = value;
        }
      });
    });
  }

  return tiersListCopy;
};

export const copyAllByTier = (tier: any, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => {
  const tiersListCopy = [...tiersList];
  let value = '';

  const startingPoint = tiersListCopy[0].tiers.findIndex(item => item.tier === tier);
  tiersListCopy.forEach(tiersi => {
    value = '';

    for (let j = startingPoint; j < tiersi.tiers.length; j += 1) {
      if (tiersi.tiers[j].tier === tier && tiersi.tiers[j].rate) {
        value = tiersi.tiers[j].rate;
      }

      if (tiersi.tiers[j].tier !== tier && tiersi.tiers[j].rate) {
        tiersi.tiers[j].rate = '';
      }

      tiersi.tiers[j].rate = value;
    }
  });

  return tiersListCopy;
};

export const copyByTerm = (term: number, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => {
  const tiersListCopy = [...tiersList];
  let value = '';

  tiersListCopy.forEach(row => {
    if (row.term === term) {
      row.tiers.forEach(col => {
        if (col.tier === '1+' && col.rate) {
          value = col.rate;
        }

        if (col.tier !== '1+' && col.rate) {
          col.rate = '';
        }

        if (col.tier !== '1+' && !col.rate && row.term === term) {
          col.rate = value;
        }
      });
    }
  });

  return tiersListCopy;
};

export const setTableCellValue = (rate: string, tier: string, term: number, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => {
  const tiersListCopy = [...tiersList];

  tiersListCopy.forEach(item => {
    if (item.term === term) {
      item.tiers.forEach(col => {
        if (col.tier === tier) {
          col.rate = rate;
        }
      });
    }
  });

  return tiersListCopy;
};

export const buildTable = (defaultMonthsLength: number, defaultTierLength: number, monthsLength: number, tiersLength: number, tierDataInput: any[]) => {
  let finalTierData = tierDataInput;

  if (monthsLength === defaultMonthsLength && tiersLength === defaultTierLength) {
    finalTierData = tierDataInput.slice(0, defaultMonthsLength).map((item: any) => {
      return {
        term: item.term,
        tiers: item.tiers.slice(0, defaultTierLength),
      };
    });
  }

  if (monthsLength > defaultMonthsLength || tiersLength > defaultTierLength) {
    finalTierData = tierDataInput.slice(0, monthsLength).map((item: any) => {
      return {
        term: item.term,
        tiers: item.tiers.slice(0, tiersLength),
      };
    });
  }

  return finalTierData;
};

export const clearTier = (tierTableData: TierTableDataType[], tier: string) => {
  const tiersListCopy = [...tierTableData];

  tiersListCopy.forEach(row => {
    row.tiers.forEach(col => {
      if (col.tier === tier && col.rate) {
        col.rate = '';
      }
    });
  });

  return tiersListCopy;
};

export const clearTerm = (tierTableData: TierTableDataType[], term: number) => {
  const tiersListCopy = [...tierTableData];

  tiersListCopy.forEach(row => {
    if (row.term === term) {
      row.tiers.forEach(col => {
        if (col.rate) {
          col.rate = '';
        }
      });
    }
  });

  return tiersListCopy;
};
