import React from 'react';
import FieldTitle from '../../components/FieldTitle';
import styles from './styles.module.scss';

interface Props {
  headerList: { header: string; field: string; centered?: boolean; sortable?: boolean }[];
  sortField: string;
  sortAscending: boolean;
  handleOnClick: (val: string) => void;
}

const HeaderRow = ({ headerList, sortField, sortAscending, handleOnClick }: Props) => {
  return (
    <thead>
      <tr>
        {headerList.map(col => {
          const key = `${col.field}-sort-btn`;

          return (
            <th key={key} className={sortField === col.field ? styles.sortedField : undefined}>
              {col.sortable ? (
                <FieldTitle
                  id={key}
                  field={col.field}
                  header={col.header}
                  sortField={sortField}
                  sortAscending={sortAscending}
                  descendingFirst={false}
                  handleOnClick={handleOnClick}
                  className={col.centered ? styles.tableHeaderCenter : undefined}
                />
              ) : (
                <>{col.header}</>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default HeaderRow;
