import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import lexBlueLogo from '../../../assets/blue_lexus_header_logo.svg';
import toyBlueLogo from '../../../assets/blue_toy_header_logo.svg';
import lexLogo from '../../../assets/lexus_header_logo.svg';
import toyLogo from '../../../assets/toy_header_logo.svg';
import useComponentVisible from '../../hooks/useComponentVisible';
import Button from '../Button';
import styles from './styles.module.scss';

interface NavProps {
  name: string;
  component?: ReactNode;
  url?: string;
  icon?: ReactNode;
}

interface Props {
  isLexus: boolean;
  nav?: NavProps[];
}

const OATHeaderLogo = ({ isLexus, nav }: Props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const logo = isLexus ? lexLogo : toyLogo;
  const blueLogo = isLexus ? lexBlueLogo : toyBlueLogo;

  return (
    <div className={styles.oatHeaderLogo}>
      <div className={styles.content}>
        <img className={styles.image} src={isComponentVisible ? blueLogo : logo} alt="Offer Admin Tool" />
        {nav && nav.length && (
          <div className={styles.btnWrapper} ref={ref}>
            <button
              id="oat-header-logo-nav-btn"
              type="button"
              className={clsx(styles.navBtn, isComponentVisible && styles.navActive)}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
            {isComponentVisible && (
              <div className={styles.nav}>
                <ul>
                  {nav.map((item, index) => {
                    const i = index;
                    return (
                      <Button className={styles.listItem} key={i} onClick={() => setIsComponentVisible(false)}>
                        <div className={styles.icon}>{item.icon}</div>
                        {item.component}
                      </Button>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OATHeaderLogo;
