import React from 'react';
import OATIcon from '../OATIcon';
import styles from './styles.module.scss';

interface TierTableHeaderCellProps {
  tdClassName: string;
  textClassName: string;
  text: string;
  copyMethodOne: (val: number, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => void;
  copyMethodTwo: (val: number, tiersList: { term: number; tiers: { tier: string; rate: string }[] }[]) => void;
  tiersList: { term: number; tiers: { tier: string; rate: string }[] }[];
  setTiersList: (val: any) => void;
  copyMethodParam: any;
}

const TierTableHeaderCell = ({ tdClassName, textClassName, text, copyMethodOne, copyMethodTwo, copyMethodParam, tiersList, setTiersList }: TierTableHeaderCellProps) => {
  return (
    <td className={tdClassName}>
      <span className={textClassName}>{text}</span>
      <div className={styles.iconContainer}>
        <OATIcon icon="circle-down" size={14} className={styles.icon} onClick={() => setTiersList(copyMethodOne(copyMethodParam, tiersList))} id="copy-down" />
        <OATIcon icon="circle-right" size={14} className={styles.icon} onClick={() => setTiersList(copyMethodTwo(copyMethodParam, tiersList))} id="copy-across" />
      </div>
    </td>
  );
};

export default TierTableHeaderCell;
