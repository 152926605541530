import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  labelClass?: string;
}

const RadioInput = ({ labelClass, ...props }: Props) => {
  const { id, checked, type = 'radio' } = props;

  return (
    <label htmlFor={id} className={clsx(styles.label, labelClass, checked && styles.checked)}>
      <input className={styles.input} {...props} type={type} />
    </label>
  );
};

export default RadioInput;
