import React from 'react';
import Button from '../../components/Button';
import DollarDisplay from '../../components/DollarDisplay';
import InfoTooltip from '../../components/InfoTooltip';
import { VinItem } from '../models';
import { formatDollar } from '../utils/utils';
import styles from './styles.module.scss';

interface Props {
  item: VinItem;
  isSETUser: boolean;
  isNationalApp: boolean;
  portsMap: (port: string) => string;
  handleSelectVin: (item: VinItem) => void;
}

const VinTableRow = ({ item, isSETUser, isNationalApp, portsMap, handleSelectVin }: Props) => {
  return (
    <tr className={item.stopSale === 'Y' ? styles.stopSale : ''}>
      <td>
        <Button id={`vin-${item.vin}`} onClick={() => handleSelectVin(item)} className={styles.blueTextBtn} variant="text">
          {item.vin}
        </Button>
      </td>
      <td>
        <InfoTooltip id={`vin-accessories-${item.accessories}`} message={`Accessories: ${item.accessories}`} popoverClass={styles.accPopover} />
      </td>
      <td>{item.dealerCode}</td>
      {!isSETUser && <td>{item.originalDealerCode}</td>}
      <td>
        <p className={styles.invoiceDate}>{item.invoiceDate}</p>
      </td>
      <td>
        <DollarDisplay value={item.dealerInvoice} />
      </td>
      <td>
        <DollarDisplay value={item.msrp} />
      </td>
      {!isSETUser && (
        <td>
          <DollarDisplay value={item.msrpDiscount} />
        </td>
      )}
      {!isSETUser && !isNationalApp && <td>{portsMap(item.port)}</td>}
      <td>{item.status}</td>
      {!isSETUser && (
        <td>
          <DollarDisplay value={formatDollar(item.tdaFee)} />
        </td>
      )}
      <td>
        <DollarDisplay value={formatDollar(item.dph)} />
      </td>
      {!isNationalApp && <td>{item.stopSale}</td>}
      {isSETUser && <td>{item.isTempVin ? 'Y' : 'N'}</td>}
    </tr>
  );
};

export default VinTableRow;
