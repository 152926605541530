import SeriesV1Response from '../models/SeriesV1Response';

export interface UniqueSeriesType {
  code: string;
  title: string;
}

const getSeriesV1UniqueSeries = (response: SeriesV1Response) => {
  const seriesMap = new Map<string, string>();
  response.series_data.forEach(yearSeries => {
    yearSeries.series_list.forEach(({ code, title }) => {
      seriesMap.set(code, title);
    });
  });

  const result: UniqueSeriesType[] = [];

  seriesMap.forEach((value, key) => {
    result.push({
      code: key,
      title: value,
    });
  });

  return result;
};

export default getSeriesV1UniqueSeries;
