import clsx from 'clsx';
import React, { ReactNode } from 'react';
import OATIcon from '../OATIcon';
import OATPopover from '../OATPopover';
import styles from './styles.module.scss';

type Props = {
  iconSize?: number;
  id: string;
  message?: ReactNode;
  iconClassName?: string;
  popoverClass?: string;
  iconRightPadding?: boolean;
};

const InfoTooltip = ({ id, message, iconSize = 16, iconClassName, popoverClass, iconRightPadding = false }: Props) => {
  return (
    <OATPopover
      id={id}
      content={message}
      contentClass={styles.body}
      popoverClass={popoverClass}
      buttonContent={<OATIcon className={clsx(iconClassName, iconRightPadding && styles.iconRightPadding)} pointer icon="info" size={iconSize} colorTheme="blue" />}
    />
  );
};

export default InfoTooltip;
