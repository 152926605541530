import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface ToggleButtonProps {
  selected: boolean;
  toggleSelected: () => void;
  disabled?: boolean;
  id?: string;
  containerClass?: string;
  buttonClass?: string;
}

const ToggleButton = ({ selected, toggleSelected, containerClass, buttonClass, disabled, id }: ToggleButtonProps) => {
  const toggleHandler = () => {
    if (!disabled) {
      toggleSelected();
    }
  };

  return (
    <div
      id={id}
      role="button"
      className={clsx(styles.toggleContainer, containerClass, selected && !disabled ? '' : styles.toggleContainerDisabled)}
      onClick={toggleHandler}
      onKeyDown={toggleHandler}
      tabIndex={0}
    >
      <div className={clsx(styles.dialogButton, buttonClass, selected && !disabled ? '' : styles.disabled)} />
    </div>
  );
};

export default ToggleButton;
