import clsx from 'clsx';
import React, { InputHTMLAttributes, Ref, useState } from 'react';
import styles from './styles.module.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errorMsg?: string;
  darkTheme?: boolean;
  className?: string;
  dollarSign?: boolean;
  percentageSign?: boolean;
  displayValue?: string;
  inputWrapperClass?: string;
  isNational?: boolean;
  alphaNumeric?: boolean;
  alphaNumericWithComma?: boolean;
}

const Input = React.forwardRef(
  (
    {
      error,
      errorMsg,
      darkTheme,
      className,
      inputWrapperClass,
      dollarSign,
      percentageSign,
      displayValue,
      autoComplete = 'off',
      value,
      onFocus,
      onBlur,
      onChange,
      isNational = false,
      alphaNumeric = false,
      alphaNumericWithComma = false,
      ...rest
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [focused, setFocused] = useState(false);

    const getTheme = () => {
      let theme = '';
      // national theme
      if (isNational) {
        theme = clsx(styles.nationalInput, darkTheme && styles.nationalDark, error && styles.nationalError, dollarSign && styles.leftPadding);
      } else {
        // regional themes
        theme = clsx(darkTheme ? styles.darkTheme : styles.input, error && styles.error, dollarSign && styles.leftPadding);
      }

      return theme;
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const handleReplace = (val: string) => {
        return val.replace(/[^a-z0-9]/gi, '');
      };
      const handleReplaceAllowComma = (val: string) => {
        return val.replace(/[^a-z0-9,]/gi, '');
      };

      if (onChange) {
        if (alphaNumeric) {
          e.target.value = handleReplace(e.target.value);
          e.currentTarget.value = handleReplace(e.currentTarget.value);
        }
        if (alphaNumericWithComma) {
          e.target.value = handleReplaceAllowComma(e.target.value);
          e.currentTarget.value = handleReplaceAllowComma(e.currentTarget.value);
        }
        onChange(e);
      }
    };

    const valueToDisplay = displayValue && !focused ? displayValue : value;

    return (
      <div className={clsx(styles.inputWrapper, inputWrapperClass)}>
        {dollarSign && <span className={styles.dollarSign}>$</span>}
        <input
          ref={ref}
          className={clsx(getTheme(), className)}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          autoComplete={autoComplete}
          value={valueToDisplay}
          {...rest}
        />
        {percentageSign && <span className={styles.percentageSign}>%</span>}
        {error && errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
      </div>
    );
  },
);

export default Input;
