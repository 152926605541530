import clsx from 'clsx';
import React, { ChangeEventHandler, ReactNode } from 'react';
import styles from './styles.module.scss';

interface CheckboxProps {
  className?: string;
  id?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  disableLabelClick?: boolean;
  label?: string | ReactNode;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checkmarkClass?: string;
  nationalCheckmarkClass?: string;
  isNational?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  id,
  isChecked = false,
  isDisabled = false,
  label,
  name,
  onChange,
  checkmarkClass,
  nationalCheckmarkClass,
  isNational = false,
  disableLabelClick = false,
}) => {
  const getTheme = () => {
    let theme = '';
    // national theme
    if (isNational) {
      theme = clsx([styles.nationalCheckmark, { [styles.disabled]: isDisabled }, nationalCheckmarkClass]);
    } else {
      // regional themes
      theme = clsx([styles.checkmark, { [styles.disabled]: isDisabled }, checkmarkClass]);
    }

    return theme;
  };
  return (
    <label htmlFor={id} key={id} className={clsx([styles.chbox, { [styles.disabled]: isDisabled }, className])} tabIndex={-1}>
      {label}
      <input checked={isChecked} disabled={isDisabled} id={disableLabelClick ? '' : id} name={name} onChange={onChange} type="checkbox" tabIndex={-1} />
      <span className={getTheme()} />
    </label>
  );
};

export default Checkbox;
