import clsx from 'clsx';
import React from 'react';
import OATIcon from '../../OATIcon';
import styles from './styles.module.scss';

interface Props {
  id: string;
  toggled: boolean;
  onClick: () => void;
  className?: string;
}

const TableTitleToggle = ({ id, toggled, onClick, className }: Props) => {
  return (
    <button id={id} type="button" className={clsx(styles.tableTitleToggle, className)} onClick={onClick}>
      <OATIcon icon={toggled ? 'angle-up' : 'angle-down'} size={14} />
    </button>
  );
};

export default TableTitleToggle;
