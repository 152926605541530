import React, { ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ToastContext from './ToastContext';
import { ToastItem, ToastStatus } from './types';

type Props = {
  children?: ReactNode;
};

const OATToast = ({ children }: Props) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const add = (msg: string, status: ToastStatus) => {
    const id = uuidv4();
    setToasts([...toasts, { id, msg, status }]);
  };

  const remove = (id: string) => {
    setToasts(toasts.filter(item => item.id !== id));
  };

  return <ToastContext.Provider value={{ toasts, add, remove }}>{children}</ToastContext.Provider>;
};

export default OATToast;
