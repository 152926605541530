import React from 'react';
import ErrorMessages from '../../../../constants/errorMessages';
import styles from './styles.module.scss';

type Props = {
  message?: string;
};

const OfferTabError = ({ message = ErrorMessages.OFFER_TABS_REQUIRED_FIELDS }: Props) => {
  return <div className={styles.offerTabError}>{message}</div>;
};

export default OfferTabError;
