import { formatDollars, formatDollarsCents, formatNumber, formatRcf, formatStdRate } from '../../utils/formatNumber';

type ValueType = string | number | undefined | null;

/**
 * Ideally, this should conform to any decimal scale, but we are restricting to the app's only number types
 * @param value
 * @param rcf
 * @param dollar
 * @param whole
 * @param adjustmentFactor
 * @returns
 */

const formatValue = (value: ValueType, rcf = false, dollar = false, adjustmentFactor = false, whole = false, stdRate = false) => {
  if (!value !== undefined && value !== null) {
    if (rcf) {
      return formatRcf(value);
    }
    if (dollar) {
      return formatDollarsCents(value, true);
    }
    if (adjustmentFactor) {
      return formatNumber(value, false, true, 3);
    }
    if (stdRate) {
      return formatStdRate(value);
    }
    if (whole) {
      return formatDollars(value);
    }
  }

  return value?.toString() || '';
};

export default formatValue;
