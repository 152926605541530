/**
 * Helps in converting optional number properties
 * ex. if response can accept optional number, isNaN('') will return 0, but we want undefined instead
 * @param val
 * @param retVal default is undefined
 */
const stringToNumber = (val: string | undefined | null, retVal = null) => {
  return val === undefined || val === null || val.trim() === '' ? retVal : Number(val);
};

export default stringToNumber;
