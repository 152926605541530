import { formatDecimal, formatDollarsCents, formatNumber, formatRcf } from '../../utils/formatNumber';

const baseCaseError = (val: string, min?: number, max?: number) => {
  const numVal = Number(val);
  // base cases
  return Number.isNaN(numVal) || (min !== undefined && numVal < min) || (max !== undefined && numVal > max);
};

export const wholeNumberValidation = {
  isErrorFn: (val: string, min?: number, max?: number) => {
    // base cases
    if (baseCaseError(val, min, max) || val.includes('.')) {
      return true;
    }

    return false;
  },
  onBlurFn: (val: string) => {
    return formatNumber(val);
  },
};

export const cashValidation = {
  isErrorFn: (val: string, min?: number, max?: number) => {
    if (val === '.') {
      return false;
    }

    // base cases
    if (baseCaseError(val, min, max)) {
      return true;
    }

    const vals = val.split('.');
    if (vals.length === 2 && vals[1].length > 2) {
      return true;
    }

    return false;
  },
  onBlurFn: (val: string) => {
    // pad zeroes
    return formatDollarsCents(val, false);
  },
};

export const rcfValidation = {
  isErrorFn: (val: string) => {
    if (val === '.') {
      return false;
    }

    // base cases
    if (baseCaseError(val, 0, 0.99999)) {
      return true;
    }

    const vals = val.split('.');
    if (vals.length === 2 && vals[1].length > 5) {
      return true;
    }

    return false;
  },
  onBlurFn: (val: string) => {
    // pad zeroes
    return formatRcf(val);
  },
};

export const decimalValidation = {
  isErrorFn: (val: string, min?: number, max?: number) => {
    if (val === '.') {
      return false;
    }

    // base cases
    if (baseCaseError(val, min, max)) {
      return true;
    }

    return false;
  },
  onBlurFn: (val: string) => {
    // pad zeroes
    return formatDecimal(val, false);
  },
};
