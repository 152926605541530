import cx from 'clsx';
import React from 'react';
import Textarea from '../Textarea';
import styles from './styles.module.scss';

interface SnippetInputProps {
  id: string;
  snippets?: {
    brand?: string;
    id: string;
    name: string;
    rev: string;
    text: string;
  }[];
  setInputValue: (val: string) => void;
  value: string;
  tokens?: string[];
  error?: boolean;
  darkTheme?: boolean;
  disabled?: boolean;
  contentClass?: string;
}

const SnippetInput = ({ id, snippets, tokens, setInputValue, value, error, darkTheme, disabled, contentClass }: SnippetInputProps) => {
  return (
    <Textarea
      id={id}
      className={cx(styles.snippetsTextarea, error && styles.error, contentClass)}
      disabled={disabled}
      dropdownClassName={styles.snippetsStyle}
      tokens={tokens?.map(name => ({ name, char: name }))}
      secondaryTokens={snippets?.map(item => ({ name: item.name, char: item.text }))}
      autoComplete="off"
      wrap="off"
      rows={1}
      value={value}
      onChange={e => {
        e.target.value = e.target.value.replace(/\n/g, '');
        setInputValue(e.target.value);
      }}
      error={error}
      darkTheme={darkTheme}
    />
  );
};

export default SnippetInput;
