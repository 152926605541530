import { faPrint, faQuestionCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import Button from '../Button';
import OATWrapper from '../OATWrapper';
import styles from './styles.module.scss';

interface Props {
  darkTheme?: boolean;
  secondaryTheme?: boolean;
  downloadLink?: string;
  downloadLinkRef?: React.RefObject<HTMLAnchorElement>;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  onClickLogout: () => void;
  isShowPublish?: boolean;
  isDisablePublish?: boolean;
  onOfferExport?: () => void;
  onOfferDownload?: () => void;
  onPublish: () => void;
  onPreviewPublish: () => void;
  isDisablePreview?: boolean;
  isShowDownload?: boolean;
  isShowExport?: boolean;
  isShowPreview?: boolean;
  helpUrl?: string;
}

const OATFooter = ({
  darkTheme,
  secondaryTheme,
  downloadLink,
  downloadLinkRef,
  leftContent,
  rightContent,
  onClickLogout,
  isShowDownload = false,
  isShowExport = false,
  isShowPublish = false,
  isDisablePublish = false,
  isShowPreview,
  isDisablePreview,
  onOfferDownload,
  onOfferExport,
  onPublish,
  onPreviewPublish,
  helpUrl,
}: Props) => {
  const getTheme = () => {
    if (darkTheme) {
      return styles.darkTheme;
    }

    return secondaryTheme ? styles.secondaryTheme : styles.defaultTheme;
  };

  return (
    <>
      <div className={clsx(styles.oatFooter, getTheme())}>
        <OATWrapper>
          <div className={styles.content}>
            <div>{leftContent}</div>
            <div>
              {isShowExport && (
                <span>
                  {isShowDownload && (
                    <a id="offer-download-btn" ref={downloadLinkRef} className={styles.downloadButton} href={downloadLink} onClick={onOfferDownload}>
                      <FontAwesomeIcon className={styles.printIcon} icon={faPrint} />
                    </a>
                  )}
                  <Button id="offer-export-btn" className={styles.exportButton} onClick={onOfferExport}>
                    Offer Export
                  </Button>
                </span>
              )}
              {isShowPublish && (
                <span>
                  {!isShowPreview && (
                    <Button id="offer-preview-publish-btn" variant="text" onClick={onPreviewPublish} disabled={isDisablePreview}>
                      Preview
                    </Button>
                  )}
                  <Button id="offer-publish-btn" className={styles.publishButton} variant="primary" onClick={onPublish} disabled={isDisablePublish}>
                    Publish
                  </Button>
                  <span className={styles.separator}>|</span>
                </span>
              )}

              {rightContent}

              {helpUrl && (
                <a className={styles.helpLink} href={helpUrl} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                  <span> Help</span>
                </a>
              )}
              <button id="footer-logout-btn" className={styles.logoutButton} type="button" onClick={onClickLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span> Logout</span>
              </button>
            </div>
          </div>
        </OATWrapper>
      </div>
      <div className={styles.spacer} />
    </>
  );
};

export default OATFooter;
