import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

const Table = ({ children, className }: Props) => {
  return <table className={clsx(styles.table, className)}>{children}</table>;
};

export default Table;
