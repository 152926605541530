import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type Props = {
  children?: ReactNode;
  isProd?: boolean;
};

const SideNav = ({ children, isProd }: Props) => {
  return (
    <>
      <div className={styles.spacer} />
      <div className={clsx(styles.sideNav, isProd && styles.prodBg)}>{children}</div>
    </>
  );
};

export default SideNav;
