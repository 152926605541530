import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface InputContainerProps {
  label?: string;
  children?: ReactNode;
  className?: string;
}

const InputContainer = ({ label, children, className }: InputContainerProps) => {
  return (
    <div className={cx(styles.inputContainer, className)}>
      {label && <span className={styles.label}>{label}</span>}
      {children}
    </div>
  );
};

export default InputContainer;
