import ReactHTMLParser from '@hedgedoc/html-to-react';
import React, { ReactNode, useState } from 'react';
import DefaultModal from '../../../components/DefaultModal';
import IconMenu from '../../../components/IconMenu';
import IconMenuItem from '../../../components/IconMenu/IconMenuItem';
import OATIcon from '../../../components/OATIcon';
import OATStatusIcon from '../../../components/OATStatusIcon';
import Tooltip from '../../../components/Tooltip';
import { OfferStatus, OfferTypes } from '../../../constants/global';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { IOffer } from '../../../models/IOffer';
import { IOfferStatusError } from '../../../models/IOfferStatusError';
import { formatDecimal, formatDollars, formatRcf } from '../../../utils/formatNumber';
import getLabelFromSeriesMap from '../../../utils/getLabelFromSeriesMap';
import { UniqueSeriesType } from '../../../webservices/efc/utils/getSeriesV1UniqueSeries';
import styles from './styles.module.scss';

type Props = {
  canPreview: boolean;
  offer: IOffer;
  seriesMap: UniqueSeriesType[];
  uniqueSeriesMap?: UniqueSeriesType[];
  offerMarketFlagMap: Map<string, IOfferStatusError>;
  renderOfferLink: (offer: IOffer) => ReactNode;
  onRemoveOffer?: (offer: IOffer) => void;
  onChangeOfferStatus?: (offer: IOffer, status: OfferStatus) => void;
  onCopyOffer?: (offer: IOffer) => void;
};

const OfferingLayoutTableRow = ({
  canPreview,
  offer,
  seriesMap,
  uniqueSeriesMap,
  offerMarketFlagMap,
  renderOfferLink,
  onChangeOfferStatus = () => {},
  onRemoveOffer = () => {},
  onCopyOffer,
}: Props) => {
  const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const statuses = [
    OfferStatus.IN_PROGRESS,
    OfferStatus.READY_TO_PREVIEW,
    OfferStatus.PREVIEWED,
    OfferStatus.READY_TO_PUBLISH,
    OfferStatus.PAUSED,
    OfferStatus.PUBLISHED_PENDING_CHANGES,
  ];
  const handleOnCopy = () => {
    if (onCopyOffer) {
      onCopyOffer(offer);
    }
  };

  const handleChangeStatus = (status: OfferStatus) => {
    onChangeOfferStatus(offer, status);
    setIsComponentVisible(false);
  };

  const handleRemoveOffer = () => {
    onRemoveOffer(offer);
    setShowDeleteModal(false);
  };

  const renderDetails = (item: IOffer) => {
    switch (item.offerType) {
      case OfferTypes.CUSTOMER_CASH: {
        return item.cashOfferAmount !== null ? `$${formatDollars(item.cashOfferAmount?.toString() || '')} per customer` : null;
      }
      case OfferTypes.MISCELLANEOUS: {
        return item.cashOfferAmount !== null && item.miscType ? `$${formatDollars(item.cashOfferAmount?.toString() || '')} ${item.miscType}` : null;
      }
      case OfferTypes.APR: {
        const vals = [];
        if (item.rate !== null) {
          vals.push(formatDecimal(item.rate?.toString() || ''));
        }

        if (item.term) {
          vals.push(item.term);
        }

        if (item.tier) {
          vals.push(item.tier);
        }

        if (item.subventionAmount !== null) {
          vals.push(`($${formatDollars(item.subventionAmount?.toString())})` || '');
        }

        return vals.join(' | ');
      }
      case OfferTypes.LEASE: {
        const vals = [];
        if (item.rcf !== null) {
          vals.push(formatRcf(item.rcf?.toString() || ''));
        }

        if (item.term) {
          vals.push(item.term);
        }

        if (item.tier) {
          vals.push(item.tier);
        }

        return vals.join(' | ');
      }

      default: {
        return null;
      }
    }
  };

  const renderErrorDetails = () => {
    const offerStatusError = offerMarketFlagMap.get(offer.id || '');
    if (offerStatusError && !offerStatusError.success) {
      return (
        <Tooltip message={offerStatusError.error || ''}>
          <OATIcon className={styles.red} icon="warning" size={24} />
        </Tooltip>
      );
    }
    return null;
  };

  const canShowMenuItem = (status: string, item: string) => {
    let showMenuItem = true;
    if (
      status === item ||
      (status === OfferStatus.PAUSED && item === OfferStatus.PUBLISHED_PENDING_CHANGES) ||
      (!canPreview && (item === OfferStatus.READY_TO_PREVIEW || item === OfferStatus.PREVIEWED))
    ) {
      showMenuItem = false;
    }
    return showMenuItem;
  };

  const renderStatusIconDetails = (status: string) => {
    return (
      <IconMenu ref={ref}>
        {statuses.map((item, index) => {
          const i = index;
          if (canShowMenuItem(status, item)) {
            return (
              <IconMenuItem key={`o-t-m-${i}`} id={`offer-item-menu-${index}`} renderIcon={<OATStatusIcon status={item} size={14} />} onClick={() => handleChangeStatus(item)}>
                {item}
              </IconMenuItem>
            );
          }
          return null;
        })}
      </IconMenu>
    );
  };

  return (
    <tr>
      <td>{renderOfferLink(offer)}</td>
      <td>{ReactHTMLParser(getLabelFromSeriesMap(offer.series, seriesMap, uniqueSeriesMap))}</td>
      <td>{renderDetails(offer)}</td>
      <td>
        {offer.regionCodes?.map((code, i) => (
          <span key={code}>
            {code}
            {offer.regionCodes && i < offer.regionCodes.length - 1 && ', '}
          </span>
        ))}
      </td>
      <td className={styles.actionCol}>
        {onCopyOffer && (
          <Tooltip id={`copyIcon-${offer.id}`} message="Copy">
            <OATIcon className={styles.copyIcon} icon="copy" size={14} onClick={handleOnCopy} />
          </Tooltip>
        )}

        <Tooltip id={`delIcon-${offer.id}`} message="Delete">
          <OATIcon id={`delIcon-${offer.id}`} className={styles.delIcon} icon="trash" size={14} onClick={() => setShowDeleteModal(true)} />
        </Tooltip>
        {showDeleteModal && (
          <DefaultModal
            id={offer.id}
            title="Delete Offer"
            message="Are you sure you wish to remove this offer?"
            open
            submitText="OK"
            onSubmit={handleRemoveOffer}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
      </td>
      <td>
        <div className={styles.statusIcon}>
          <button title={offer.status} type="button" id={`offer-item-${offer.id}`} onClick={() => setIsComponentVisible(true)}>
            <OATStatusIcon status={offer.status} size={24} />
          </button>
          {renderErrorDetails()}
          {isComponentVisible && renderStatusIconDetails(offer.status)}
        </div>
      </td>
    </tr>
  );
};

export default OfferingLayoutTableRow;
