import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useContext } from 'react';
import OATWrapper from '../OATWrapper';
import styles from './styles.module.scss';
import ToastContext from './ToastContext';
import { ToastItem, ToastStatus } from './types';

interface OATToastBarProps {
  toast: ToastItem;
}

const OATToastBar = ({ toast }: OATToastBarProps) => {
  const { remove = () => {} } = useContext(ToastContext);

  const handleOnRemove = () => {
    remove(toast.id);
  };

  const getStatusClass = () => {
    switch (toast.status) {
      case ToastStatus.SUCCESS: {
        return styles.success;
      }
      case ToastStatus.ERROR: {
        return styles.error;
      }
      default: {
        return styles.info;
      }
    }
  };

  return (
    <div className={clsx(styles.toastBar, getStatusClass())}>
      <OATWrapper>
        <div className={styles.container}>
          <div>{toast.msg}</div>
          <div>
            <button id={`toast-item-${toast.id}`} className={styles.toastClose} type="button" onClick={() => handleOnRemove()}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
        </div>
      </OATWrapper>
    </div>
  );
};

export default OATToastBar;
