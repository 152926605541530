// import { getLeaseConfigList } from '../../../../../../../utils/configListUtils';
// import { formatInventory, formatOptionsTotal } from '../../../utils/utils';
import React from 'react';
import Button from '../../components/Button';
import DollarDisplay from '../../components/DollarDisplay';
import ConfigItem from '../models/ConfigItem';
import { formatOptionsTotal, getLeaseConfigList } from '../utils/utils';
import styles from './styles.module.scss';

interface Props {
  item: ConfigItem;
  handleSelectConfig: (item: ConfigItem) => Promise<void>;
}

const ConfigTableRow = ({ item, handleSelectConfig }: Props) => {
  return (
    <tr>
      <td>
        <Button id={`config-${item.config}`} onClick={() => handleSelectConfig(item)} className={styles.blueTextBtn} variant="text">
          {getLeaseConfigList(item.config)}
        </Button>
      </td>
      <td>
        <DollarDisplay value={formatOptionsTotal(item.optionsTotal)} />
      </td>
      <td>{item.count}</td>
      <td>{`${item.inventory}%`}</td>
    </tr>
  );
};

export default ConfigTableRow;
