import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  isProd?: boolean;
  children?: ReactNode;
}

const OATHeader = ({ isProd, children }: Props) => {
  return (
    <>
      <section className={clsx(styles.oatHeader, isProd && styles.prodBg)}>
        <div className={styles.content}>{children}</div>
      </section>
      <div className={styles.spacer} />
    </>
  );
};

export default OATHeader;
