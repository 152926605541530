import dateStringToDate from '../dateStringToDate';

const processField = (obj: any, field: string) => {
  if (field === 'startDate' || field === 'endDate') {
    return dateStringToDate(obj[field]);
  }

  if (typeof obj[field] === 'string') {
    return obj[field].toLowerCase();
  }

  return obj[field];
};

const sortFieldsHelper = (field: string, sortAscending: boolean) => {
  return (a: any, b: any) => {
    const itemA = processField(a, field);
    const itemB = processField(b, field);

    if (itemA < itemB) {
      return sortAscending ? -1 : 1;
    }

    if (itemA > itemB) {
      return sortAscending ? 1 : -1;
    }
    return 0;
  };
};

export default sortFieldsHelper;
