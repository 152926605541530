import React, { useEffect } from 'react';
import styles from './styles.module.scss';

interface Props {
  blocking: boolean;
  title: string;
}

const BlockUi = ({ blocking, title }: Props) => {
  useEffect(() => {
    if (blocking) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [blocking]);

  if (blocking) {
    return (
      <div className={styles.blockUiContainer}>
        <div className={styles.blockUiOverlay} />
        <div className={styles.blockUiTitleContainer}>
          <div className={styles.blockUiTitle}>
            <h4>{title}</h4>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default BlockUi;
