import clsx from 'clsx';
import React, { useState } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import ModalBody from '../Modal/ModalBody';
import ModalFooter from '../Modal/ModalFooter';
import ModalHeader from '../Modal/ModalHeader';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  handleInsertDisclaimer: (tier: { name: string; text: string; id: string }) => void;
  disclaimers: { brand: string; id: string; name: string; rev: string; text: string; selected?: boolean }[];
  label: string;
}

const DisclaimerModal = ({ onClose, handleInsertDisclaimer, disclaimers, label }: Props) => {
  const [tier, setTier] = useState(disclaimers[0] || { text: '' });

  const findTierValue = (index: number, title: string) => {
    disclaimers.forEach((disclaimer, i) => {
      if (disclaimer.name === title && i === index) {
        disclaimer.selected = true;
        setTier(disclaimer);
      } else {
        disclaimer.selected = false;
      }
    });
  };

  return (
    <Modal isOpen size="lg" onClose={onClose}>
      <ModalHeader onClose={onClose}>{label}</ModalHeader>
      <ModalBody>
        <div className={styles.modalBody}>
          <div className={styles.tiersListContainer}>
            <div className={styles.modalTitle}>
              <span className={styles.disclaimerText}>{label}</span>
            </div>
            <div>
              <ul className={styles.tiersList}>
                {disclaimers.map((item, i) => {
                  return (
                    <button type="button" key={item.name} className={clsx(styles.listItem, item.selected && styles.active)} onClick={() => findTierValue(i, item.name)}>
                      <span className={styles.listItemText}>{item.name}</span>
                    </button>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={styles.tiersTextContainer}>
            <textarea className={styles.tiersText} value={tier.text} disabled />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          className={styles.insertButton}
          disabled={!tier.id}
          onClick={() => {
            handleInsertDisclaimer(tier);
            onClose();
          }}
        >
          Insert
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DisclaimerModal;
