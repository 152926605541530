/* eslint-disable no-console */
import React from 'react';
import TableTitle from '../../components/Table/TableTitle';
import sortFieldsHelper from '../../utils/sortFieldsHelper';
import { ConfigItem } from '../models';
import MostRecentConfig from '../models/MostRecentConfig';
import { formatInventory, getLeaseConfigList } from '../utils/utils';
import ConfigTableRow from './ConfigTableRow';
import CreateLeaseTable from './CreateLeaseTable';
import RecentConfigTableRow from './RecentConfigTableRow';

const configHeaderColumns = [
  { header: 'Configuration', field: 'config', sortable: true },
  { header: 'Options Total', field: 'optionsTotal', sortable: true },
  { header: 'Count', field: 'count', sortable: true },
  { header: 'Inventory', field: 'inventory', sortable: true },
];

const recentConfigHeaderColumns = [
  { header: 'Configuration', field: 'config' },
  { header: 'Sales Period', field: 'salesPeriod' },
  { header: 'Count', field: 'count' },
  { header: 'Inventory', field: 'inventory' },
];

interface Props {
  configList: ConfigItem[];
  configQuery: string;
  sortField: string;
  sortAsc: boolean;
  handleSelectConfig: (item: ConfigItem) => Promise<void>;
  handleSort: (field: string) => void;
  showRecentConfig: boolean;
  mostRecentConfig: MostRecentConfig | undefined;
}

const ConfigTable = ({ configList, configQuery, sortField, sortAsc, handleSelectConfig, handleSort, showRecentConfig, mostRecentConfig }: Props) => {
  const filteredConfigList = configList
    .filter(item => {
      if (!configQuery) {
        return true;
      }
      const configSplit = item.config.split(',');
      const querySplit = configQuery.split(',');
      return querySplit.every(q => configSplit.some(c => c.toLowerCase().trim().includes(q.toLowerCase().trim())));
    })
    .map(item => ({ ...item, inventory: formatInventory(item.inventory) }))
    .sort(sortFieldsHelper(sortField, sortAsc));

  const handleSelectMostRecentConfig = () => {
    if (mostRecentConfig){
      handleSelectConfig({
        config: getLeaseConfigList(mostRecentConfig.configuration),
        configFull: mostRecentConfig.configuration,
        count: mostRecentConfig?.count || 0,
        inventory: Number(mostRecentConfig?.inventory) || 0,
        optionsTotal: mostRecentConfig.optionsTotal || 0,
        vinItems: [],
      });
    }
  };

  return (
    <>
      {mostRecentConfig && showRecentConfig && (
        <>
          <TableTitle>Most Recent</TableTitle>
          <CreateLeaseTable headerColumns={recentConfigHeaderColumns} sortField="" sortAscending={false} handleSort={() => {}}>
            <RecentConfigTableRow handleSelectConfig={handleSelectMostRecentConfig} recentConfig={mostRecentConfig} />
          </CreateLeaseTable>
        </>
      )}
      <TableTitle>Available</TableTitle>
      <CreateLeaseTable headerColumns={configHeaderColumns} sortField={sortField} sortAscending={sortAsc} handleSort={handleSort}>
        {filteredConfigList.map(item => {
          return <ConfigTableRow key={item.config} item={item} handleSelectConfig={handleSelectConfig} />;
        })}
      </CreateLeaseTable>
    </>
  );
};

export default ConfigTable;
