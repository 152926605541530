import cx from 'clsx';
import React from 'react';
import Button from '../../../../../components/Button';
import InlineInputLabel from '../../../../../components/InlineInputLabel';
import Input from '../../../../../components/Input';
import OATIcon from '../../../../../components/OATIcon';
import Textarea from '../../../../../components/Textarea';
import { BindFnType } from '../../../../../hooks/useInput';
import styles from './styles.module.scss';

type Props = {
  uid: string;
  textAreaLabelWidth: number;
  showRemove: boolean;
  disableTextArea: boolean;
  file: string;
  fileError: boolean;
  fileFn: BindFnType;
  text: string;
  textError: boolean;
  tokens: { name: string; char: string }[];
  secondaryTokens: { name: string; char: string }[];
  disableBrowse: boolean;
  bodyContainerClass?: string;
  setText: (val: string) => void;
  onUnlink: () => void;
  onBrowse: () => void;
  onRemove: (uid: string) => void;
};

const DnDFileText = ({
  uid,
  textAreaLabelWidth,
  showRemove,
  disableTextArea,
  file,
  fileError,
  fileFn,
  text,
  textError,
  tokens,
  secondaryTokens,
  disableBrowse,
  bodyContainerClass,
  setText,
  onUnlink,
  onBrowse,
  onRemove,
}: Props) => {
  return (
    <div className={cx(styles.bodyContainer, bodyContainerClass)}>
      <InlineInputLabel label="File" vertical width={textAreaLabelWidth}>
        <div className={styles.fileContainer}>
          <Input id={`file-input-${uid}`} darkTheme disabled value={file} {...fileFn} error={fileError} />
          {!disableTextArea ? (
            <Button variant="primary" id={`browse-btn-${uid}`} onClick={onBrowse} disabled={disableBrowse}>
              Browse
            </Button>
          ) : (
            <Button variant="primary" id={`unlink-btn-${uid}`} onClick={onUnlink}>
              Unlink
            </Button>
          )}
        </div>
      </InlineInputLabel>

      <InlineInputLabel label="Text *" vertical width={textAreaLabelWidth} inputWrapperClass={styles.inlineInputLabel}>
        <div className={styles.textAreaContainer}>
          <Textarea
            id={`text-field-${uid}`}
            className={styles.dndFileTextArea}
            value={text}
            tokens={tokens}
            secondaryTokens={secondaryTokens}
            onChange={e => setText(e.target.value)}
            disabled={disableTextArea}
            error={textError}
            darkTheme
          />
          {showRemove && (
            <Button onClick={() => onRemove(uid)} id={`delete-btn-${uid}`} className={styles.trashIconButton}>
              <OATIcon icon="trash" size={22} />
            </Button>
          )}
        </div>
      </InlineInputLabel>
    </div>
  );
};

export default DnDFileText;
