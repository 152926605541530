import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ModalHeaderProps {
  title?: string;
  children?: ReactNode;
  onClose?: () => void;
  className?: string;
  closeBtnClass?: string;
}

const ModalHeader = ({ title, children, onClose, className, closeBtnClass }: ModalHeaderProps) => {
  return (
    <div className={cx(styles.modalHeader, className)}>
      <div>
        {title && <h2 className={styles.title}>{title}</h2>}
        {children}
      </div>
      <div>
        {onClose && (
          <button type="button" onClick={onClose} className={cx(styles.closeButton, closeBtnClass)}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ModalHeader;
