const tiers = [
  {
    tier: '1+',
    rate: '',
  },
  {
    tier: '1',
    rate: '',
  },
  {
    tier: '2',
    rate: '',
  },
  {
    tier: '3',
    rate: '',
  },
  {
    tier: '4',
    rate: '',
  },
  {
    tier: '5',
    rate: '',
  },
  {
    tier: '6',
    rate: '',
  },
  {
    tier: '7',
    rate: '',
  },
  {
    tier: '7+',
    rate: '',
  },
];

const tierData = [
  {
    term: 24,
    tiers,
  },
  {
    term: 36,
    tiers,
  },
  {
    term: 48,
    tiers,
  },
  {
    term: 60,
    tiers,
  },
  {
    term: 72,
    tiers,
  },
  {
    term: 84,
    tiers,
  },
];

export default tierData;
