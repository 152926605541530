import React from 'react';
import DollarDisplay from '../../../../components/DollarDisplay';
import OfferTabHeaderDetailContainer from '../OfferTabHeaderDetailContainer';
import OfferTabHeaderDetailLabel from '../OfferTabHeaderDetailLabel';
import OfferTabHeaderDetailLabelValue from '../OfferTabHeaderDetailLabelValue';
import OfferTabHeaderDetailRow from '../OfferTabHeaderDetailRow';

interface Props {
  apr: string;
  terms: string;
  subVen: string;
}

const APRHeaderDetails = ({ apr, terms, subVen }: Props) => {
  return (
    <OfferTabHeaderDetailRow>
      <OfferTabHeaderDetailContainer isMargin={false}>
        <OfferTabHeaderDetailLabelValue>{`${apr} % APR`}</OfferTabHeaderDetailLabelValue>
      </OfferTabHeaderDetailContainer>
      <OfferTabHeaderDetailContainer isMargin>
        <OfferTabHeaderDetailLabelValue>{terms}</OfferTabHeaderDetailLabelValue>
        <OfferTabHeaderDetailLabel>Months</OfferTabHeaderDetailLabel>
      </OfferTabHeaderDetailContainer>
      {subVen && subVen !== '' ?
        <>
          <OfferTabHeaderDetailContainer isMargin>
            <OfferTabHeaderDetailLabelValue><b>+</b></OfferTabHeaderDetailLabelValue>
          </OfferTabHeaderDetailContainer>
          <OfferTabHeaderDetailContainer isMargin>
            <OfferTabHeaderDetailLabelValue><DollarDisplay value={subVen} /></OfferTabHeaderDetailLabelValue>
            <OfferTabHeaderDetailLabel>TFS Subvention Cash</OfferTabHeaderDetailLabel>
          </OfferTabHeaderDetailContainer>
        </> : null}
    </OfferTabHeaderDetailRow>
  );
};

export default APRHeaderDetails;
