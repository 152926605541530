import axios from 'axios';
import { EFCVinResponse } from './models/EFCVinResponse';

const getVinDetails = (token: string, domain: string, vin: string) => {
  const url = `/efc/vin/${vin}`;
  return axios.get<EFCVinResponse>(`${domain}${url}`, {
    headers: { Authorization: token },
  });
};

export default getVinDetails;
