import cx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
}

const TableContainer = ({ className, children }: Props) => {
  return <div className={cx(styles.tableContainer, className)}>{children}</div>;
};

export default TableContainer;
