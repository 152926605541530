import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ModalFooterProps {
  children?: ReactNode;
  className?: string;
}

const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return <div className={clsx(styles.modalFooter, className)}>{children}</div>;
};

export default ModalFooter;
