import ReactHtmlParser from '@hedgedoc/html-to-react';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import useComponentVisible from '../../hooks/useComponentVisible';
import OATIcon from '../OATIcon';
import styles from './styles.module.scss';

export type DropdownItem = {
  value: string;
  label: string;
  jsxLabel?: JSX.Element;
  showAddBtn?: boolean;
};

type DropdownType = DropdownItem | string;

interface Props {
  id: string;
  value: DropdownType | undefined;
  options: DropdownType[];
  disabled?: boolean;
  minWidth?: number;
  darkTheme?: boolean;
  placeholder?: string;
  error?: boolean;
  isJsxLabel?: boolean;
  isPillBox?: boolean;
  isNational?: boolean;
  pillBoxValue?: string[];
  usePillBoxLabelForSelectedItems?: boolean;
  onChange: (value: DropdownItem) => void;
  onCancelClick?: (item: string) => void;
}

const getDDItem = (item: DropdownType) => {
  return typeof item === 'string' ? { value: item, label: item } : item;
};

const Dropdown = ({
  id,
  value,
  options,
  disabled,
  darkTheme,
  placeholder,
  error,
  minWidth,
  isJsxLabel = false,
  isPillBox,
  isNational,
  pillBoxValue,
  usePillBoxLabelForSelectedItems,
  onChange,
  onCancelClick,
}: Props) => {
  const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible(false);
  const [optionList, setOptionList] = useState<DropdownItem[]>([]);

  useEffect(() => {
    // process options
    if (options) {
      setOptionList(options.map(item => getDDItem(item)));
    }
  }, [options]);

  const handleOnSelect = (item: DropdownItem) => {
    setIsComponentVisible(false);
    onChange(item);
  };

  const handleBtnSelect = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleCancelClick = (item: string) => {
    if (onCancelClick) {
      onCancelClick(item);
    }
  };

  const getStyle = () => {
    return minWidth
      ? {
          minWidth: `${minWidth}px`,
        }
      : undefined;
  };

  const getInitialValue = (initalValue: DropdownType) => {
    if (isJsxLabel) {
      return getDDItem(initalValue).jsxLabel;
    }

    return ReactHtmlParser(getDDItem(initalValue).label);
  };

  const getTheme = () => {
    let theme = '';
    // national theme
    if (isNational) {
      theme = clsx(styles.ddNationalBtn, styles.nationalTheme);
    } else {
      // regional themes
      theme = clsx(styles.ddBtn, darkTheme ? styles.darkTheme : styles.defaultTheme);
    }

    return clsx(theme, isPillBox && styles.pillBoxBtn);
  };

  const renderPillboxValues = () => {
    // value is undefined
    if (!pillBoxValue) {
      return null;
    }

    // no values
    if (pillBoxValue.length === 0) {
      return <span>None</span>;
    }

    // display values
    return (
      <>
        {pillBoxValue.map(val => {
          const label = usePillBoxLabelForSelectedItems ? optionList.find(opt => opt.value === val)?.label : val;
          return (
            <div className={styles.pillBoxValue} key={val}>
              <span>{label}</span>
              <OATIcon className={styles.cancelIcon} onClick={() => handleCancelClick(val)} icon="cancel-circle" />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={styles.dropdown} ref={ref}>
      <button
        id={`${id}-btn`}
        className={clsx(getTheme(), error && styles.error, isComponentVisible && styles.active)}
        style={getStyle()}
        type="button"
        disabled={disabled}
        onClick={handleBtnSelect}
      >
        {!isPillBox && <span>{value ? getInitialValue(value) : placeholder}</span>}
        {isPillBox && renderPillboxValues()}
        <OATIcon className={clsx(styles.caret, isPillBox && styles.pillBoxCaret)} icon="caret-down" size={10} />
      </button>
      {isComponentVisible && (
        <div className={clsx(isNational ? styles.nationalContent : styles.content)}>
          <ul>
            {optionList.map(item => (
              <li key={`${id}-list-item-${item.value}`} style={getStyle()}>
                <button type="button" id={`${id}-list-btn-${item.value}`} onClick={() => handleOnSelect(item)}>
                  {item.showAddBtn && <OATIcon icon="plus" className={styles.addIcon} />}
                  {isJsxLabel ? item.jsxLabel : ReactHtmlParser(item.label.toString())}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
