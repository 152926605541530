import React, { ReactNode } from 'react';
import Button from '../../../../../components/Button';
import InlineInputLabel from '../../../../../components/InlineInputLabel';
import Input from '../../../../../components/Input';
import OATIcon from '../../../../../components/OATIcon';
import { BindFnType } from '../../../../../hooks/useInput';
import styles from './styles.module.scss';

type Props = {
  uid: string;
  dndName: string;
  labelWidth: number;
  showAdd: boolean;
  sortOrder: number;
  sortInput: string;
  sortInputError: boolean;
  sortInputFn: BindFnType;
  children?: ReactNode;
  onAdd: (sortOrder: number) => void;
  onSort: (newSort: number, oldSort: number) => void;
};

const DnDLabel = ({ uid, dndName, labelWidth, showAdd, sortOrder, sortInput, sortInputError, sortInputFn, children, onAdd, onSort }: Props) => {
  return (
    <InlineInputLabel
      vertical
      width={labelWidth}
      contentGrow={false}
      inputWrapperClass={styles.inlineInputLabel}
      className={styles.label}
      labelComponent={
        <div className={styles.labelContainer}>
          <div className={styles.labelRow}>
            <div>{dndName}</div>
            <Input
              id={`title-${uid}`}
              darkTheme
              className={styles.sortInput}
              value={sortInput}
              error={sortInputError}
              {...sortInputFn}
              onBlur={e => {
                if (e.target.value) {
                  onSort(Number(e.target.value), sortOrder);
                }
              }}
            />
          </div>
          {showAdd && (
            <div className={styles.addItemContainer}>
              <span className={styles.addItemText}>
                <span>Add </span>
                {dndName}
              </span>
              <Button onClick={() => onAdd(sortOrder)} className={styles.iconButton} id={`add-btn-${uid}`}>
                <OATIcon icon="add" size={22} />
              </Button>
            </div>
          )}
        </div>
      }
    >
      {children}
    </InlineInputLabel>
  );
};

export default DnDLabel;
