import clsx from 'clsx';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';
import { assignNumberValue } from '../../utils/assignValue';
import ArrowButtons from './ArrowButtons';
import { getConfig } from './configs';
import styles from './styles.module.scss';
import formatValue from './utils';

type DollarSignProps = {
  dollarSign?: boolean;
  error?: boolean;
  isNational?: boolean;
};

const DollarSign = ({ dollarSign, error, isNational }: DollarSignProps) => {
  return dollarSign ? (
    <span id="dollarSign" className={clsx(!isNational ? styles.dollarSign : styles.nationalDollarSign, error && styles.error)}>
      $
    </span>
  ) : null;
};

type PercentSignProps = {
  percentageSign?: boolean;
  isNational?: boolean;
};

const PercentSign = ({ percentageSign, isNational = false }: PercentSignProps) => {
  return percentageSign ? (
    <span id="percentageSign" className={!isNational ? styles.percentageSign : styles.nationalPercentageSign}>
      %
    </span>
  ) : null;
};

const getTheme = (dark: boolean, dollarSign: boolean, percentageSign: boolean, textAlign?: string) => {
  return clsx(dark ? styles.dark : styles.default, dollarSign && styles.leftPadding, percentageSign && styles.rightPadding, textAlign === 'center' && styles.centerText);
};

type TextAlignType = 'left' | 'right' | 'center' | undefined;

export interface NumberInputProps extends NumericFormatProps {
  id?: string;
  className?: string;
  dark?: boolean;
  dollarSign?: boolean;
  error?: boolean;
  textAlign?: TextAlignType;
  percentageSign?: boolean;
  selectOnFocus?: boolean;
  max?: number;
  min?: number;
  isNational?: boolean;
  showArrowBtns?: boolean;
  addOne?: () => void;
  subtractOne?: () => void;
  stdRate?: boolean;

  // configs
  wholeNumber?: boolean;
  negativeNumber?: boolean;
  units?: boolean;
  rcf?: boolean;
  dollar?: boolean;
  rate?: boolean;
  adjustmentFactor?: boolean;
}

const NumberInput = ({
  id,
  className,
  error,
  selectOnFocus,
  value,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  textAlign,
  dark = false,
  dollarSign = false,
  percentageSign = false,
  max,
  min,
  isNational = false,
  showArrowBtns = false,
  addOne,
  subtractOne,
  stdRate = false,

  // configs
  wholeNumber = false,
  negativeNumber = false,
  units = false,
  rcf = false,
  dollar = false,
  rate = false,
  adjustmentFactor = false,

  ...rest
}: NumberInputProps) => {
  // config
  const baseTheme = getTheme(dark, dollarSign, percentageSign, textAlign);
  const config = getConfig(wholeNumber, negativeNumber, units, rcf, dollar, rate, adjustmentFactor);

  const [val, setVal] = useState(formatValue(value, rcf, dollar, adjustmentFactor, !units, stdRate));
  const [isFocused, setIsFocused] = useState(false);

  // useEffect to force rerender of component when different value is passed
  useEffect(() => {
    // condition to prevent unneccesseary re-rendering
    const formattedValue = formatValue(value, rcf, dollar, adjustmentFactor, !units, stdRate);
    if (!isFocused && (assignNumberValue(val) !== assignNumberValue(value) || val !== formattedValue)) {
      setVal(formattedValue);
    }
  }, [value, val, rcf, dollar, units, isFocused]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (selectOnFocus && e.target) {
      e.target.select();
    }

    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const formattedVal = formatValue(e.target.value, rcf, dollar, adjustmentFactor, !units, stdRate);
    setVal(formattedVal);

    setIsFocused(false);

    if (dollar) {
      e.target.value = formattedVal;
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.currentTarget.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleIsAllowed = (values: NumberFormatValues) => {
    if (max && Number(values.value) > max) {
      return false;
    }

    if (min && Number(values.value) < min) {
      return false;
    }

    return true;
  };

  return (
    <span className={styles.numberInputWrapper}>
      <DollarSign dollarSign={dollarSign} error={error} isNational={isNational} />
      <NumericFormat
        id={id}
        className={clsx(!isNational ? styles.numberInput : styles.nationalNumberInput, className, baseTheme, error && styles.error)}
        value={val}
        onFocus={handleFocus}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        allowLeadingZeros
        isAllowed={handleIsAllowed}
        {...config}
        {...rest}
      />
      <PercentSign percentageSign={percentageSign} isNational={isNational} />
      {showArrowBtns && <ArrowButtons id={id} addOne={addOne} subtractOne={subtractOne} />}
    </span>
  );
};

export default NumberInput;
