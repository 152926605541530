import { Brands } from '../../../constants/global';
import { EfcBrand } from '../constants/efcConstants';

const getEfcBrand = (brand = '') => {
  let brandParam = EfcBrand.TOYOTA;
  if (brand === EfcBrand.LEXUS || brand.toLowerCase() === Brands.LEXUS.toLowerCase()) {
    brandParam = EfcBrand.LEXUS;
  }

  return brandParam;
};

export default getEfcBrand;
