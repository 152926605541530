import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import clsx from 'clsx';
import React, { TextareaHTMLAttributes, useState } from 'react';
import styles from './styles.module.scss';

const Item = ({ entity }: any) => {
  return <div key={entity.name} className={styles.listItem}>{`$\{${entity.name}}`}</div>;
};

const ItemHash = ({ entity }: any) => {
  return <div key={entity.name} className={clsx(styles.listItem, styles.snippetListItem)}>{`#{${entity.name}} ${entity.char}`}</div>;
};
const Loading = () => <div>Loading</div>;

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  errorMsg?: string;
  tokens?: { name: string; char: string }[];
  secondaryTokens?: { name: string; char: string }[];
  dropdownClassName?: string;
  containerClassName?: string;
  darkTheme?: boolean;
  isNational?: boolean;
}

const Textarea = ({
  id,
  isNational,
  error,
  errorMsg,
  darkTheme,
  className,
  dropdownClassName,
  containerClassName,
  disabled,
  tokens = [],
  secondaryTokens = [],
  ...props
}: Props) => {
  const [hasKeyPressed, setHasKeyPressed] = useState(false);
  // Prevents autocomplete from rendering if string ends in the trigger

  const handleOnKeyPressed = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!hasKeyPressed && e.key) {
      setHasKeyPressed(true);
    }
  };

  return (
    <>
      <ReactTextareaAutocomplete
        id={id}
        className={clsx(!isNational ? styles.textarea : styles.nationalTextarea, error && styles.error, darkTheme && styles.darkTheme, disabled && styles.disabled, className)}
        containerClassName={containerClassName}
        dropdownClassName={dropdownClassName}
        disabled={disabled}
        trigger={
          hasKeyPressed
            ? {
                $: {
                  dataProvider: token => tokens.filter(item => item.char.indexOf(token) !== -1),
                  component: Item,
                  output: (item: any) => ({
                    text: `$\{${item.name}} `,
                    caretPosition: 'end',
                  }),
                },
                '#': {
                  dataProvider: token => secondaryTokens.filter(item => item.name.indexOf(token) !== -1),
                  component: ItemHash,
                  output: (item: any) => ({
                    text: `#{${item.name}} `,
                    caretPosition: 'end',
                  }),
                },
              }
            : {}
        }
        onKeyPress={handleOnKeyPressed}
        minChar={0}
        listClassName={styles.list}
        itemClassName={styles.listItemWrapper}
        loadingComponent={Loading}
        {...props}
      />
      {error && errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
    </>
  );
};

export default Textarea;
