import { formatDollars } from '../../utils/formatNumber';

export const calculateResidual = (val: number) => {
  return `${Math.round(val * 100)}%`;
};

export const formatOptionsTotal = (val: number) => {
  return formatDollars(val.toString(), true);
};

export const formatInventory = (val: number) => {
  return Math.round(Number(val) * 1000) / 10;
};

export const formatDollar = (val: string) => {
  if (!val) {
    return '0';
  }

  return Math.round(Number(val)).toString();
};

export const returnNoVehiclesError = (errorName: string, trimTitle: string) => {
  return {
    name: errorName,
    message: `There are currently no vehicles in inventory for ${trimTitle}. Please select a different trim.`,
  };
};

export const getLeaseConfigDisplayList = (config: string) => {
  if (!config) {
    return [];
  }

  return config.split('|').map(item => {
    const itemSplit = item.split('#');
    return itemSplit.length === 1 ? itemSplit[0] : `${itemSplit[1]} (${itemSplit[0]})`;
  });
};

export const getLeaseConfigList = (config: string) => {
  const codes = config.split('|').map(item => {
    return item.split('#')[0];
  });

  codes.sort();
  return codes.join(', ');
};

export const getAlphanumericString = (val: string) => {
  return val.replace(/[^a-z0-9]/gi, '');
};
