import React from 'react';
import styles from './styles.module.scss';

interface Props {
  value: string;
}

const DollarDisplay = ({ value }: Props) => {
  return (
    <div className={styles.dollarDisplay}>
      <sup>$</sup>
      {value}
    </div>
  );
};

export default DollarDisplay;
