import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type Props = {
  sideNav?: ReactNode;
  children?: ReactNode;
};

const SideNavLayout = ({ sideNav, children }: Props) => {
  return (
    <div className={styles.sideNavLayout}>
      {sideNav}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SideNavLayout;
