import axios from 'axios';
import { SeriesData } from './models/SeriesModels';
import getEfcBrand from './utils/getEfcBrand';

const getSeries = (token: string, domain: string, brand: string, region: string) => {
  const brandParam = getEfcBrand(brand);

  return axios.get<SeriesData>(`${domain}/efc/series/${brandParam}/${region}`, {
    headers : { Authorization: token },
  });
};

export default getSeries;
