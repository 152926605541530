const leadZeroesRegex = /^0+/;

const removeTrailingZeros = (numStr: string): string => {
  const result = parseFloat(numStr).toString();
  return numStr[numStr.length - 1] === '.' ? `${result}.0` : result;
};

export const formatNumberWithCommas = (amount: string): string => {
  if (!amount || Number.isNaN(Number(amount))) {
    return '';
  }

  return Number(amount).toLocaleString('en-US');
};

const doCommaRegex = (val: string, commas: boolean) => {
  return commas ? formatNumberWithCommas(val) : val;
};

const processDecimal = (val: string, commas: boolean, paddedZeroes?: number, noLeadingZeroes?: boolean) => {
  const numArr = []; // push left side, right side, and join with '.'
  const vals = val.split('.');

  // if blank, use a 0 (ex. 0.9), otherwise use the commaregex
  if (!noLeadingZeroes) {
    numArr.push(vals[0] ? doCommaRegex(vals[0], commas) : '0');
  }

  if (noLeadingZeroes && Math.abs(Number(vals[0])) > 99) {
    return val;
  }

  // if split results in 2 items, process right side of num
  if (vals.length === 2) {
    if (noLeadingZeroes) {
      const leftVals = Math.abs(Number(vals[0])) > 9 ? [parseInt(vals[0], 10)] : vals[0].split('').filter(v => v !== '0');

      if (leftVals.length) {
        leftVals.forEach(v => numArr.push(v));
      } else {
        numArr.push('');
      }
    }

    const noTrailZeroes = removeTrailingZeros(val).split('.')[1];
    let padding = paddedZeroes || noTrailZeroes ? 0 : 1;
    if (paddedZeroes) {
      // pad zeroes
      // note: if noTrailZeroes is greater than paddedZeroes, it will create negative padding:
      //                                                  0        -     10 = -10
      padding = noTrailZeroes && noTrailZeroes.length < paddedZeroes ? paddedZeroes - noTrailZeroes.length : 0;
    }
    numArr.push(`${noTrailZeroes}${'0'.repeat(padding)}`);
  } else {
    numArr.push(paddedZeroes ? '0'.repeat(paddedZeroes) : '0');
  }

  // join (ex ['2343', '34] -> 2343.34)
  return numArr.join('.');
};

// new function to parse decimals
const parseDecimal = (val: FormatNumberInputType, places: number, stdRateTrim?: boolean) => {
  if (val === '' || val === undefined || val === null || Number.isNaN(Number(val))) {
    return '';
  }

  const numVal = +val;
  const fixed = numVal.toFixed(places); // return string with padded trailing zeroes. it will also add 1 leading zero if needed

  // std trimming is like normal, except leading 0 is dropped. eg -0.20 -> -.2
  if (stdRateTrim) {
    if (!numVal) {
      return '0';
    }

    const trimmed = (+fixed).toString();

    if (Math.abs(numVal) >= 1) {
      return trimmed;
    }

    const split = trimmed.split('');
    split[numVal < 0 ? 1 : 0] = '';
    return split.join('');
  }

  // default case: trailing both
  // ex: rcfs
  return fixed;
};

type FormatNumberInputType = string | number | undefined | null;

export const formatNumber = (val: FormatNumberInputType, commas = false, decimals = false, paddedZeroes?: number, noLeadingZeroes?: boolean) => {
  // return empty if not valid or empty
  // number 0 should be parsed as the formatted '0'
  if (val === '' || val === undefined || val === null || Number.isNaN(Number(val))) {
    return '';
  }

  // '00000' => '0'
  const trimmedVal = val.toString().replace(leadZeroesRegex, '');
  if (!trimmedVal) {
    return '0';
  }

  if (decimals) {
    return processDecimal(trimmedVal, commas, paddedZeroes, noLeadingZeroes);
  }
  return doCommaRegex(trimmedVal, commas);
};

// ex: 0.00034
export const formatRcf = (val: FormatNumberInputType) => {
  return parseDecimal(val, 5);
};

export const formatStdRate = (val: FormatNumberInputType) => {
  return parseDecimal(val, 2, true);
};

// used for apr rates. ex: 0.9
export const formatDecimal = (val: FormatNumberInputType, commas = true) => {
  return formatNumber(val, commas, true);
};

// ex: 1,203
export const formatDollars = (val: FormatNumberInputType, commas = true, decimals = false) => {
  return formatNumber(val, commas, decimals);
};

export const formatMillions = (val: FormatNumberInputType, round: boolean, tofixed = 2, addM = false) => {
  const milionValue = Number(val) / 1_000_000;
  const M = addM ? 'M' : '';

  if (round) {
    return `${milionValue.toFixed(tofixed)}${M}`;
  }

  const stringValue = milionValue.toString();
  return `${stringValue.slice(0, stringValue.indexOf('.') + 3)}${M}`;
};

// ex: 1,200.92
export const formatDollarsCents = (val: FormatNumberInputType, commas = true) => {
  const value = typeof val === 'string' ? val.replace(',', '') : val;
  const formatted = formatNumber(value, commas, true, 2);

  return formatted;
};

export const formatAccounting = (val: FormatNumberInputType, symbol = true, includeCents = true) => {
  const num = includeCents ? formatDollarsCents(val) : formatDollars(val);
  const isNeg = num.indexOf('-') !== -1;
  const dollarSign = symbol ? '$' : '';
  return isNeg ? `(${dollarSign}${num.replace('-', '')})` : `${dollarSign}${num}`;
};
