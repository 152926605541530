import React from 'react';
import getSrpLabel from '../../utils/getSrpLabel';
import sortFieldsHelper from '../../utils/sortFieldsHelper';
import Gstatus from '../components/Gstatus';
import { VinItem } from '../models';
import CreateLeaseTable from './CreateLeaseTable';
import VinTableRow from './VinTableRow';

const getVinHeaderColumns = (isSET: boolean, isNationalApp: boolean, isLexusUser: boolean, useSrpLabelLogic: boolean) => {
  const columns = [];

  columns.push({ header: 'Vin', field: 'vin', sortable: true });
  columns.push({ header: 'Acc', field: 'accessories' });
  columns.push({ header: 'Dealer Code', field: 'dealerCode', sortable: true });

  if (!isSET) {
    columns.push({ header: 'Original Dealer Code', field: 'originalDealerCode', sortable: true });
  }

  columns.push({ header: 'Invoice Date', field: 'invoiceDate' });
  columns.push({ header: 'Dealer Invoice', field: 'dealerInvoice', sortable: true });
  columns.push({ header: getSrpLabel(useSrpLabelLogic, isLexusUser), field: 'msrp', sortable: true });

  if (!isSET) {
    columns.push({ header: 'Msrp Discount', field: 'msrpDiscount' });
  }

  if (!isSET && !isNationalApp) {
    columns.push({ header: 'Port', field: 'port', sortable: true });
  }

  columns.push({ header: 'Status', field: 'status', sortable: true });

  if (!isSET) {
    columns.push({ header: 'TDA', field: 'tdaFee', sortable: true });
  }

  columns.push({ header: 'DPH', field: 'dph', sortable: true });

  if (!isNationalApp) {
    columns.push({ header: 'Stop Sale', field: 'stopSale' });
  }

  if (isSET) {
    columns.push({ header: 'Temp Vin', field: 'tempVin' });
  }

  return columns;
};

interface Props {
  vinList: VinItem[];
  dealerCodeQuery: string;
  sortField: string;
  sortAsc: boolean;
  isSETUser: boolean;
  isLexusUser: boolean;
  isNationalApp: boolean;
  useSrpLabelLogic: boolean;
  gStatus: boolean;
  setGStatus: () => void;
  portsMap: (port: string) => string;
  handleSelectVin: (item: VinItem) => void;
  handleSort: (field: string) => void;
}

const VinTable = ({
  vinList,
  dealerCodeQuery,
  sortField,
  sortAsc,
  isSETUser,
  isLexusUser,
  isNationalApp,
  useSrpLabelLogic,
  gStatus,
  setGStatus,
  portsMap,
  handleSelectVin,
  handleSort,
}: Props) => {
  const filteredVinList = vinList
    .filter(item => {
      return (!gStatus || item.status.toUpperCase().trim() === 'G') && (!dealerCodeQuery || item.dealerCode.includes(dealerCodeQuery));
    })
    .sort(sortFieldsHelper(sortField, sortAsc));

  return (
    <>
      <Gstatus isFiltered={gStatus} setFiltered={setGStatus} />
      <CreateLeaseTable
        headerColumns={getVinHeaderColumns(isSETUser, isNationalApp, isLexusUser, useSrpLabelLogic)}
        sortField={sortField}
        sortAscending={sortAsc}
        handleSort={handleSort}
      >
        {filteredVinList.map(item => {
          return <VinTableRow key={item.vin} item={item} handleSelectVin={handleSelectVin} isSETUser={isSETUser} isNationalApp={isNationalApp} portsMap={portsMap} />;
        })}
      </CreateLeaseTable>
    </>
  );
};

export default VinTable;
