import React, { ReactNode } from 'react';
import Button from '../../../components/Button';
import AdInput from '../../components/AdInput';
import styles from './styles.module.scss';

interface Props {
  vin: string;
  children?: ReactNode;
  onVinClick: () => void;
  onSubmit: () => void;
  onResetAll?: () => void;
  inclExclLabel?: string;
  onResetIncExcl?: () => void;
  showEnhCostShareTooltip?: any;
  enhanceCostShareToolTip?: ReactNode;
  isRegionalApp?: Boolean;
}

const FinalSelectionFlow = ({
  vin,
  children,
  onVinClick,
  onSubmit,
  onResetAll,
  inclExclLabel,
  onResetIncExcl,
  showEnhCostShareTooltip,
  enhanceCostShareToolTip,
  isRegionalApp,
}: Props) => {
  return (
    <>
      <div className={styles.finalSelection}>
        <div>
          <AdInput
            className={styles.vinInputContainer}
            id="vin-input"
            label="VIN"
            type="text"
            buttonClickHandler={onVinClick}
            inputClickHandler={onVinClick}
            onChangeHandler={() => {}}
            value={vin}
            icon="edit"
          />
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        {showEnhCostShareTooltip ? enhanceCostShareToolTip : ''}

        {isRegionalApp && (
          <>
            <Button id="remove-exclusions-cta" variant="text" onClick={onResetIncExcl}>
              {`Remove ${inclExclLabel}`}
            </Button>
            <Button id="reset-all-cta" variant="text" onClick={onResetAll}>
              Reset All
            </Button>
          </>
        )}

        <Button id="create-offer-cta" variant="primary" onClick={onSubmit}>
          Create Offer
        </Button>
      </div>
    </>
  );
};

export default FinalSelectionFlow;
