import cx from 'clsx';
import React, { ReactNode } from 'react';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';

interface Props extends ITooltip {
  message: ReactNode;
  clickable?: boolean;
  grayTheme?: boolean;
  disable?: boolean;
}

const Tooltip = ({ id, className, children, message, clickable, grayTheme, disable = false, place = 'top', ...rest }: Props) => {
  const tooltipId = `tooltip-${id}`;
  const dataEvent = clickable ? 'click' : 'hover';

  return (
    <>
      <span data-tooltip-id={tooltipId} className={styles.trigger} data-tip data-tooltip-events={[dataEvent]}>
        {children}
      </span>
      {!disable && createPortal(
        <ReactTooltip {...rest} id={tooltipId} place={place} className={cx([styles.toolTipWidth, grayTheme && styles.grayTheme, className])} events={[dataEvent]}>
          {message}
        </ReactTooltip>,
        document.body,
        tooltipId
      )}
    </>
  );
};

export default Tooltip;
