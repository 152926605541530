import React from 'react';
import { formatRcf } from '../../../../utils/formatNumber';
import OfferTabHeaderDetailContainer from '../OfferTabHeaderDetailContainer';
import OfferTabHeaderDetailLabel from '../OfferTabHeaderDetailLabel';
import OfferTabHeaderDetailLabelValue from '../OfferTabHeaderDetailLabelValue';
import OfferTabHeaderDetailRow from '../OfferTabHeaderDetailRow';

interface Props {
  rcf: string;
  term: string;
}

const LeaseHeaderDetails = ({ rcf, term }: Props) => {
  const formattedRcf = formatRcf(rcf);
  return (
    <OfferTabHeaderDetailRow>
      <OfferTabHeaderDetailContainer isMargin={false}>
        <OfferTabHeaderDetailLabelValue>{formattedRcf}</OfferTabHeaderDetailLabelValue>
      </OfferTabHeaderDetailContainer>
      <OfferTabHeaderDetailContainer isMargin>
        <OfferTabHeaderDetailLabelValue>{term}</OfferTabHeaderDetailLabelValue>
        <OfferTabHeaderDetailLabel> Months</OfferTabHeaderDetailLabel>
      </OfferTabHeaderDetailContainer>
    </OfferTabHeaderDetailRow>
  );
};

export default LeaseHeaderDetails;
