import clsx from 'clsx';
import React, { useState } from 'react';
import Button from '../../../components/Button';
import ButtonGroup from '../../../components/ButtonGroup';
import DefaultModal from '../../../components/DefaultModal';
import Textarea from '../../../components/Textarea';
import Tooltip from '../../../components/Tooltip';
import usePromiseLoading from '../../../hooks/usePromiseLoading';
import TokenSettingsModal from '../TokenSettingsModal';
import { TokenType } from '../types';
import styles from './styles.module.scss';

interface Props {
  name: string;
  modalName: string;
  data: TokenType[];
  tokens: string[];
  dataTokens?: TokenType[]; // ex. use snippets as autocomplete for disclaimers
  onCreate: (group: string, token: string, content: string, setSelectedCb: (token: TokenType) => void) => Promise<void>;
  onEdit: (group: string, id: string, token: string, content: string) => Promise<void>;
  onDelete: (group: string, id: string) => Promise<void>;
}

const TokenSettingsComponent = ({ name, modalName, data, tokens, dataTokens, onCreate, onDelete, onEdit }: Props) => {
  const [selectedTokenId, setSelectedTokenId] = useState(data.length ? data[0].id : undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tokenModal, setTokenModal] = useState({ open: false, token: undefined as TokenType | undefined });
  const [error, setError] = useState('');
  const { loadPromise, promiseLoading } = usePromiseLoading();

  const selectedToken = data.find(item => item.id === selectedTokenId);

  const onClickItem = (item: TokenType) => {
    setSelectedTokenId(item.id);
  };

  const handleOnDelete = async () => {
    if (selectedTokenId) {
      await loadPromise(onDelete(name, selectedTokenId));
      setSelectedTokenId(undefined);
      setDeleteModal(false);
    }
  };

  const setSelectedCb = (token: TokenType) => {
    setSelectedTokenId(token.id);
  };

  const handleOnSubmit = async (token: string, content: string) => {
    try {
      if (tokenModal.token && selectedTokenId) {
        await loadPromise(onEdit(name, selectedTokenId, token, content));
      } else {
        await loadPromise(onCreate(name, token, content, setSelectedCb));
      }
      setTokenModal({ open: false, token: undefined });
      setError('');
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <>
      <div className={styles.componentContainer}>
        <section className={styles.panesContainer}>
          <div className={styles.tokenList}>
            <div className={styles.titleContainer}>{name}</div>
            <ul>
              {data.map(item => (
                <li key={item.id} className={clsx(selectedTokenId === item.id && styles.active)} id={item.id}>
                  <Tooltip id={`ts-tooltip-${item.id}`} message={item.name}>
                    <button id={`ts-item-${item.id}`} type="button" onClick={() => onClickItem(item)}>
                      {item.name}
                    </button>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.content}>
            <Textarea id={`ts-textarea-${name}`} className={styles.textArea} disabled value={selectedToken?.text || ''} />
          </div>
        </section>
        <section className={styles.footer}>
          <div>
            <Button id={`ts-create-new-${name}`} variant="primary" onClick={() => setTokenModal({ open: true, token: undefined })}>
              Create New
            </Button>
          </div>
          <div>
            <ButtonGroup>
              <Button id={`ts-delete-${name}`} variant="primary" onClick={() => setDeleteModal(true)} disabled={!selectedTokenId}>
                Delete
              </Button>
              <Button id={`ts-edit-${name}`} variant="primary" onClick={() => setTokenModal({ open: true, token: selectedToken })} disabled={!selectedTokenId}>
                Edit
              </Button>
            </ButtonGroup>
          </div>
        </section>
      </div>
      <DefaultModal
        title={`Remove ${modalName}`}
        message={`Are you sure you want to remove this ${modalName}?`}
        open={deleteModal}
        submitText="Confirm"
        disabled={promiseLoading}
        onClose={() => setDeleteModal(false)}
        onSubmit={handleOnDelete}
      />
      {tokenModal.open && (
        <TokenSettingsModal
          group={name}
          token={tokenModal.token}
          tokens={tokens}
          disabled={promiseLoading}
          dataTokens={dataTokens}
          onClose={() => {
            setTokenModal({ open: false, token: undefined });
            setError('');
          }}
          onSubmit={handleOnSubmit}
          error={error}
        />
      )}
    </>
  );
};

export default TokenSettingsComponent;
