import React, { ReactNode, useState } from 'react';
import FieldTitle from '../FieldTitle';
import Table from '../Table';
import TableContainer from '../Table/TableContainer';
import TableTitle from '../Table/TableTitle';
import TableTitleToggle from '../Table/TableTitleToggle';
import styles from './styles.module.scss';

interface ColumnProps {
  header: string;
  width?: number;
  onClick?: () => void;
  sortable?: boolean;
  field?: string;
  descendingFirst?: boolean;
}

type Props = {
  id?: string;
  title?: string;
  collapsable?: boolean;
  columns: ColumnProps[];
  renderRows: ReactNode;
  defaultSortField?: string;
  defaultSortAscending?: boolean;
  onSort?: (headerStr: string, sortField: boolean) => void;
  isNational?: boolean;
  className?: string;
};

const DefaultTable = ({ id, title, collapsable, columns, renderRows, defaultSortField = '', defaultSortAscending = false, onSort, isNational = false, className }: Props) => {
  const [collapsed, setCollapsed] = useState(collapsable);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortAscending, setSortAscending] = useState(defaultSortAscending);

  const handleOnCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleOnClick = (headerField: string, descendingFirst = false) => {
    const sortAsc = sortField !== headerField ? !descendingFirst : !sortAscending;
    setSortField(headerField);
    setSortAscending(sortAsc);

    if (onSort) {
      onSort(headerField, sortAsc);
    }
  };

  return (
    <TableContainer>
      {title && (
        <TableTitle className={isNational ? styles.nationalTitleHeader : ''}>
          <h2>{title}</h2>
          <div>
            {collapsable && <TableTitleToggle id={`${id}-collapse-toggle`} onClick={handleOnCollapsed} toggled={!collapsed} className={isNational ? styles.toggleIcon : ''} />}
          </div>
        </TableTitle>
      )}
      {!collapsed && (
        <Table className={className}>
          <thead>
            <tr>
              {columns.map(col => {
                const style = col.width ? { width: col.width } : undefined;
                const key = `${id}-${col.header || col.field}-hdr-btn`;

                return (
                  <th key={key} style={style} className={col.field === sortField ? styles.sortedField : ''}>
                    {col.sortable ? (
                      <FieldTitle
                        field={col.field}
                        sortField={sortField}
                        sortAscending={sortAscending}
                        descendingFirst={col.descendingFirst}
                        id={key}
                        header={col.header}
                        handleOnClick={handleOnClick}
                      />
                    ) : (
                      <>{col.header}</>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{renderRows}</tbody>
        </Table>
      )}
    </TableContainer>
  );
};

export default DefaultTable;
