import React, { useEffect } from 'react';

const DEFAULT_DELAY = 1500;

const useInputDelay = () => {
  const timeoutRef = React.useRef<number | null>(null);

  const cancelDelay = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    return () => {
      cancelDelay();
    };
  }, []);

  return {
    setDelay: (fn: () => void, delay = DEFAULT_DELAY) => {
      cancelDelay();
      timeoutRef.current = window.setTimeout(fn, delay);
    },
    cancelDelay: () => {
      cancelDelay();
    },
  };
};

export default useInputDelay;
