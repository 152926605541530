import { useCallback, useContext } from 'react';
import ToastContext from './ToastContext';
import { ToastStatus } from './types';

const useToast = () => {
  const { add = () => {} } = useContext(ToastContext);

  const success = useCallback((msg: string) => {
    add(msg, ToastStatus.SUCCESS);
  }, []);

  const error = useCallback((msg: string) => {
    add(msg, ToastStatus.ERROR);
  }, []);

  const info = useCallback((msg: string) => {
    add(msg, ToastStatus.INFO);
  }, []);

  return {
    success,
    error,
    info,
  };
};

export default useToast;
