import axios from 'axios';
import React, { ReactNode, useEffect, useState } from 'react';
import maintenanceImg from '../../../assets/maintenance.jpg';
import styles from './styles.module.scss';

type MaintenanceProps = {
  children: ReactNode;
  url: string;
};

const Maintenance = ({ children, url }: MaintenanceProps) => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const checkIsMaintenance = async () => {
      try {
        // add cache buster to url
        const cbUrl = `${url}?cacheb=${Date.now()}`;
        const res = await axios.get(cbUrl);
        setIsMaintenance(res.headers['content-type'].includes('application/json'));
      } catch (e) {
        setIsMaintenance(false);
      }

      setTimeout(checkIsMaintenance, 30_000);
    };

    checkIsMaintenance();
  }, []);

  if (isMaintenance) {
    return <img src={maintenanceImg} alt="maintenance_img" className={styles.maintenanceImg} />;
  }

  return <>{children}</>;
};

export default Maintenance;
