import clsx from 'clsx';
import React from 'react';
import Button from '../../components/Button';
import OATIcon from '../../components/OATIcon';
import OATLink from '../../components/OATLink';
import styles from './styles.module.scss';

type Props = {
  containerClass?: string;
  leftPanelClass?: string;
  leftPanelMessage: string;
  logoutText: string;
  onClickLogout: () => void;
  resetUrl: string;
  rightPanelClass?: string;
};

const PersonalSettingsLayout = ({ containerClass, leftPanelClass, rightPanelClass, leftPanelMessage, logoutText, resetUrl, onClickLogout }: Props) => {
  return (
    <div className={clsx(styles.personalSettings, containerClass)}>
      <div className={clsx(styles.leftPanel, leftPanelClass)}>{leftPanelMessage}</div>
      <div className={clsx(styles.rightPanel, rightPanelClass)}>
        <div>
          <Button id="ps-logout-btn" onClick={onClickLogout} variant="primary">
            {logoutText}
          </Button>
        </div>
        <div>
          <OATLink id="ps-reset-link" href={resetUrl} target="_blank" className={styles.reset}>
            <OATIcon icon="key" size={14} className={styles.icon} />
            Reset Password
          </OATLink>
        </div>
      </div>
    </div>
  );
};

export default PersonalSettingsLayout;
