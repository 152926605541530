type InputType = string | number | null | boolean | undefined;

export const assignStringValue = (value: InputType, defaultValue = '') => {
  if (value === null || value === undefined || value === false) {
    return defaultValue;
  }

  return value.toString();
};

export const assignNumberValue = (value: InputType, defaultValue = 0) => {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  const retVal = Number(typeof value === 'string' ? value.replace(/[ ,]/g, '') : value);

  return Number.isNaN(retVal) ? 0 : retVal;
};

export const assignBooleanValue = (value: InputType, defaultValue = false) => {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  return Boolean(value);
};

export const defaultNumberToUndefined = (value: InputType) => {
  if (value === null || value === undefined) {
    return undefined;
  }

  return assignNumberValue(value);
};

export const defaultNumberToNull = (value: InputType) => {
  if (value === null || value === undefined) {
    return null;
  }

  return assignNumberValue(value);
};

export const defaultStringToUndefined = (value: InputType) => {
  if (value === null || value === undefined) {
    return undefined;
  }

  return assignStringValue(value);
};

export const defaultStringToNull = (value: InputType) => {
  if (value === null || value === undefined) {
    return null;
  }

  return assignStringValue(value);
};
