import React, { ReactNode } from 'react';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Input from '../Input';
import Modal from '../Modal';
import ModalBody from '../Modal/ModalBody';
import ModalFooter from '../Modal/ModalFooter';
import ModalHeader from '../Modal/ModalHeader';
import styles from './styles.module.scss';

interface Props {
  id?: string;
  title: string;
  message: ReactNode;
  disabled?: boolean;
  submitText?: string;
  placeHolderText?: string;
  onSubmit: () => void;
  onClose: () => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const InputModal = ({ id, title, message, disabled, submitText = 'Submit', placeHolderText, onSubmit, onClose, onBlur }: Props) => {
  const handleOnSubmit = () => {
    onSubmit();
  };

  return (
    <Modal isOpen className={styles.deleteModal}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody className={styles.modalText}>
        {message}
        <Input placeholder={placeHolderText} className={styles.input} onBlur={onBlur} />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id={`input-modal-close-${id}`} className={styles.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button id={`input-modal-submit-${id}`} className={styles.deleteButton} onClick={handleOnSubmit} disabled={disabled}>
            {submitText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default InputModal;
