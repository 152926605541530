import clsx from 'clsx';
import React, { AnchorHTMLAttributes } from 'react';
import OATIcon from '../../../../components/OATIcon';
import styles from './styles.module.scss';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  navigate?: any; // fix issue where Link from react-router tries to pass down navigate to <a>. Results in unit test warning.
}

const OfferTabReturnCta = ({ children, navigate, className, ...rest }: Props) => {
  return (
    <div className={styles.container}>
      <a className={clsx(styles.offerTabReturnCta, className)} {...rest}>
        <OATIcon icon="angle-left" size={13} />
        {' Return to Offer Report'}
      </a>
    </div>
  );
};

export default OfferTabReturnCta;
