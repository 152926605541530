import { EfcBrand } from '../constants/efcConstants';

const getDefaultRegion = (brand: string, region: string) => {
  let regionParam = region;
  if (!regionParam) {
    regionParam = brand === EfcBrand.LEXUS ? '390' : '190';
  }

  return regionParam;
};

export default getDefaultRegion;
