import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import React, { ReactNode } from 'react';
import AuthClient from './AuthClient';

interface Props {
  client: AuthClient;
  children: ReactNode;
}

const AuthWrapper = ({ client, children }: Props) => {
  return !client.skip && client.instance ? (
    <MsalProvider instance={client.instance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
    </MsalProvider>
  ) : (
    <>{children}</>
  );
};

export default AuthWrapper;
