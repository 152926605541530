import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

enum AuthErrorCodes {
  CONSENT_REQUIRED = 'consent_required',
  INTERACTION_REQUIRED = 'interaction_required',
  LOGIN_REQUIRED = 'login_required',
}

const logger = console;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ['User.Read'],
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const getConfig = (clientId: string, tenantId: string, redirectUri: string) => ({
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logger.error(message);
            break;
          case LogLevel.Verbose:
            logger.debug(message);
            break;
          case LogLevel.Warning:
            logger.warn(message);
            break;
          default:
            break;
        }
      },
    },
  },
});

const getToken = async (instance: PublicClientApplication | undefined, request = loginRequest, skip = false): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!skip && instance) {
      instance
        .acquireTokenSilent({
          ...request,
          account: instance.getAllAccounts()[0],
        })
        .then(response => {
          if (response.accessToken) {
            resolve(response.accessToken);
          }
        })
        .catch(reason => {
          if (
            reason.errorCode === AuthErrorCodes.CONSENT_REQUIRED ||
            reason.errorCode === AuthErrorCodes.INTERACTION_REQUIRED ||
            reason.errorCode === AuthErrorCodes.LOGIN_REQUIRED
          ) {
            instance.logoutRedirect({ postLogoutRedirectUri: '/' });
          }
          logger.error('error in acquiring token', reason);
          reject(new Error(reason));
        });
    } else {
      resolve('');
    }
  });
};

const logout = async (instance: PublicClientApplication) => {
  await instance.logoutRedirect({ postLogoutRedirectUri: '/' });
};

/**
 * Main function that returns instance as well as util functions
 * @param clientId
 * @param tenantId
 * @param skip
 * @returns
 */
class AuthClient {
  instance: PublicClientApplication | undefined;

  skip = false;

  constructor(clientId: string, tenantId: string, skip = false, redirectUri = '/') {
    const msalConfig = getConfig(clientId, tenantId, redirectUri);
    this.instance = skip ? undefined : new PublicClientApplication(msalConfig);
  }

  getToken = (request = loginRequest) => {
    return getToken(this.instance, request, this.skip);
  };

  logout = async () => {
    if (!this.skip && this.instance) {
      await logout(this.instance);
    }
  };
}

export default AuthClient;
