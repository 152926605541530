import React from 'react';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Modal from '../Modal';
import ModalBody from '../Modal/ModalBody';
import ModalFooter from '../Modal/ModalFooter';
import ModalHeader from '../Modal/ModalHeader';

interface Props {
  id?: string;
  title: string;
  message: string;
  open: boolean;
  disabled?: boolean;
  submitText?: string;
  className?: string;
  modalBodyClassName?: string;
  isWarning?: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const DefaultModal = ({ id, title, message, open, disabled, submitText, className, modalBodyClassName, isWarning = false, onSubmit, onClose }: Props) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody className={modalBodyClassName} isWarning={isWarning}>
        {message}
      </ModalBody>
      <ModalFooter className={className}>
        <ButtonGroup>
          <Button id={`default-modal-close-${id}`} variant={isWarning ? 'error' : 'primary'} onClick={onClose}>
            Cancel
          </Button>
          <Button id={`default-modal-submit-${id}`} variant={isWarning ? 'error' : 'primary'} onClick={onSubmit} disabled={disabled}>
            {submitText || 'Submit'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default DefaultModal;
