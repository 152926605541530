export interface OfferMarketingDisclaimers {
  sortOrder: number;
  text?: string | null;
  uid?: string | null;
  tdaDisclaimerFileId?: string | null;
  tdaDisclaimerFileName?: string | null;
  tcuvDisclaimerFileId?: string | null;
  tcuvDisclaimerFileName?: string | null;
}

export interface OfferMarketingItem {
  uid: string;
  file?: string;
  fileId?: string;
  sortOrder: number;
  text?: string;
  isStandard?: boolean;
  link?: string;
  copyLinkToToyota?: boolean;
}

export interface OfferMarketing {
  id: string;
  language: string;
  offerCardTitle?: string | null;
  offerDetailTitle?: string | null;
  disclaimers: OfferMarketingDisclaimers[];
  miscType?: string | null;
  offerCardAltText?: string | null;
  offerDetailAltText?: string | null;
  description?: string | null;
  imageDisclaimer?: string | null;
  rev?: string | null;
}

export interface OfferMarketingInput {
  marketingDetails: OfferMarketing[];
  id?: string | null;
  rev?: string | null;
}

export enum DnDType {
  BULLETS = 'Bullets',
  DISCLAIMERS = 'Disclaimers',
  RESTRICTIONS = 'Restrictions',
}
