import React from 'react';
import styles from './styles.module.scss';

interface Props {
  label: string;
}

const LabelRow = ({ label }: Props) => {
  return <div className={styles.labelRow}>{label}</div>;
};

export default LabelRow;
