import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  isMargin: boolean;
}

const OfferTabHeaderDetailContainer = ({ children, isMargin }: Props) => {
  return (
    <div className={clsx(styles.container, isMargin && styles.margined)}>
      {children}
    </div>
  );
};

export default OfferTabHeaderDetailContainer;
